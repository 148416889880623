import { createTheme } from "@mui/material/styles";
import * as colors from "designsystem/Palette";
import { uiConfig, textStyles } from "designsystem";
import {
  AlertStyles,
  AlertTitleStyles,
  AppBarStyles,
  BadgeStyles,
  ButttonStyles,
  DrawerStyles,
  FormControlLabelStyles,
  FormControlStyles,
  FormGroupStyles,
  FormLabelStyles,
  HelperTextStyles,
  InputStyles,
  ListAvatarStyles,
  ListItemStyles,
  ListStyles,
  PaperStyles,
  PopoverStyles,
  SVGStyles,
  SelectStyles,
  TabsStyles,
  LinearProgressStyles,
  GridStyles,
  DataGridStyles,
  CardStyles,
  CircularProgressStyles,
  TableStyles,
  SwitchStyles,
  StackStyles,
  IconButttonStyles,
  CardHeaderStyles,
  SliderStyles,
  AutocompleteStyles,
  AccordionStyles,
} from "themes";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    customColor?: {
      menuIconColor: string;
      drawerBgColor: string;
      datepickerBorder: string;
      svgIconColor: string;
      inputColor: string;
      badgeBgColor: string;
      clientDetailBg: string;
      infoCardBr: string;
      progressBarbg: string;
      decorationLine: string;
    };
  }
  interface Components {
    MuiDataGrid?: {
      styleOverrides?: {
        root?: React.CSSProperties;
        // Add other style overrides if needed
      };
    };
  }
}

const advertMingoTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1300,
      xl: 1920,
    },
  },

  components: {
    MuiAlert: {
      styleOverrides: AlertStyles,
    },
    MuiAlertTitle: {
      styleOverrides: AlertTitleStyles,
    },
    MuiFormHelperText: {
      styleOverrides: HelperTextStyles,
    },
    MuiButton: {
      styleOverrides: ButttonStyles,
    },
    MuiIconButton: {
      styleOverrides: IconButttonStyles,
    },
    MuiInputBase: {
      styleOverrides: InputStyles,
    },
    MuiSelect: {
      styleOverrides: SelectStyles,
    },
    MuiPopover: {
      styleOverrides: PopoverStyles,
    },
    MuiListItemButton: {
      styleOverrides: ListItemStyles,
    },
    MuiDrawer: {
      styleOverrides: DrawerStyles,
    },
    MuiAppBar: {
      styleOverrides: AppBarStyles,
    },
    MuiBadge: {
      styleOverrides: BadgeStyles,
    },
    MuiTabs: {
      styleOverrides: TabsStyles,
    },
    MuiListItemAvatar: {
      styleOverrides: ListAvatarStyles,
    },
    MuiList: {
      styleOverrides: ListStyles,
    },
    MuiStack: {
      styleOverrides: StackStyles,
    },
    MuiSvgIcon: {
      styleOverrides: SVGStyles,
    },
    MuiPaper: {
      styleOverrides: PaperStyles,
    },
    MuiLinearProgress: {
      styleOverrides: LinearProgressStyles,
    },
    MuiCircularProgress: {
      styleOverrides: CircularProgressStyles,
    },
    MuiGrid: {
      styleOverrides: GridStyles,
    },
    MuiDataGrid: {
      styleOverrides: DataGridStyles,
    },
    MuiTableContainer: {
      styleOverrides: TableStyles,
    },
    MuiCard: {
      styleOverrides: CardStyles,
    },
    MuiCardHeader: {
      styleOverrides: CardHeaderStyles,
    },
    MuiSwitch: {
      styleOverrides: SwitchStyles,
    },
    MuiSlider: {
      styleOverrides: SliderStyles,
    },
    MuiFormControl: {
      styleOverrides: FormControlStyles,
    },
    MuiFormControlLabel: {
      styleOverrides: FormControlLabelStyles,
    },
    MuiFormLabel: {
      styleOverrides: FormLabelStyles,
    },
    MuiFormGroup: {
      styleOverrides: FormGroupStyles,
    },
    MuiAutocomplete: {
      styleOverrides: AutocompleteStyles,
    },
    MuiAccordion: {
      styleOverrides: AccordionStyles,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body2: "span",
        },
      },
    },
  },
  // FOR FONTS
  typography: {
    h1: {
      fontFamily: textStyles.fontFamily.titleFont,
      color: colors.blackColor,
      fontSize: textStyles.fontSize.heading,
      fontWeight: textStyles.fontWeight.medium,
      lineHeight: textStyles.lineHeight.large,
      letterSpacing: textStyles.letterSpacing.macro,
    },
    h2: {
      fontFamily: textStyles.fontFamily.titleFont,
      color: colors.blackColor,
      fontSize: textStyles.fontSize.medium,
      fontWeight: textStyles.fontWeight.medium,
      lineHeight: textStyles.lineHeight.base,
      letterSpacing: textStyles.letterSpacing.macro,
    },
    h5: {
      fontWeight: textStyles.fontWeight.strong,
      color: colors.blackColor,
      fontSize: textStyles.fontSize.large,
      "&.filter-category-wrp": {
        borderBottom: "1px solid #eee",
        marginBottom: uiConfig.AM10,
        paddingLeft: uiConfig.AM5,
        ".MuiTypography-root": {
          fontWeight: textStyles.fontWeight.strong,
          color: colors.blackColor,
        }
      },
      "&.total-prize": {
        marginTop: "20px",
        b: {
          color: colors.primaryDarkColor,
          fontWeight: textStyles.fontWeight.strong,
          fontFamily: textStyles.fontFamily.buttonFont,
        },
      },
    },
    h6: {
      fontWeight: textStyles.fontWeight.strong,
      marginBottom: uiConfig.AM10,
      color: colors.blackColor,
      fontSize: textStyles.fontSize.large,
    },
    caption: {
      fontSize: textStyles.fontSize.large,
      fontWeight: textStyles.fontWeight.medium,
      color: colors.secondaryLightColor,
      marginTop: uiConfig.AM16,
      lineHeight: textStyles.lineHeight.small,
      display: "block",
      "& .custom-link": {
        marginLeft: "5px",
        color: colors.secondaryLightColor,
        textDecoration: "underline",
        textDecorationColor: colors.decorationLine,
        "&:hover": {
          textDecorationColor: "inherit",
        },
      },
      "& .ordercontent-ellipsis": {
        display: " -webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        width: "400px",
        marginBottom: "-15px",
      },
    },
    body1: {
      borderBottom: "3px solid",
      borderBottomColor: "transparent",
      "&:hover .MuiTypography-root": {
        borderBottomColor: "primary.main",
      },
      fontFamily: textStyles.fontFamily.defaultFont,
      fontSize: textStyles.fontSize.base,
      "&.progressvalue": {
        borderBottom: "0",
      },
    },
    body2: {
      fontFamily: textStyles.fontFamily.buttonFont,
      color: colors.blackColor,
      marginTop: uiConfig.AM3,
    },
    button: {
      fontSize: textStyles.fontSize.medium,
      fontWeight: textStyles.fontWeight.normal,
      lineHeight: textStyles.lineHeight.base,
      letterSpacing: textStyles.letterSpacing.macro,
      fontFamily: textStyles.fontFamily.buttonFont,
    },
    fontFamily: textStyles.fontFamily.defaultFont,
    fontSize: textStyles.fontSize.base,
  },

  palette: {
    common: {
      white: colors.whiteColor,
      black: colors.blackColor,
    },
    primary: {
      light: colors.primaryLightColor,
      main: colors.primaryColor,
      dark: colors.primaryDarkColor,
      contrastText: colors.primaryContrastColor,
    },
    secondary: {
      light: colors.secondaryLightColor,
      main: colors.secondaryColor,
      dark: colors.secondaryDarkColor,
      contrastText: colors.secondaryContrastColor,
    },
    customColor: {
      menuIconColor: colors.menuIcon,
      drawerBgColor: colors.drawerBgColor,
      datepickerBorder: colors.datepickerBorder,
      svgIconColor: colors.svgIconColor,
      inputColor: colors.inputColor,
      badgeBgColor: colors.badgeBgColor,
      clientDetailBg: colors.clientDetailBg,
      infoCardBr: colors.infoCardBr,
      progressBarbg: colors.progressBarbg,
      decorationLine: colors.decorationLine,
    },
    error: {
      main: colors.errorMainColor,
      dark: colors.errorDarkColor,
    },
  },
});

export default advertMingoTheme;
