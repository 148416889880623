import React, { ReactNode } from "react";
import { BoxProps, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CarouselBoxContainerProps extends BoxProps {
  children?: ReactNode;
  bgColor?: string;
  maxWidth?: string;
  mb?: string;
  border?: number;
}

export default function AMCarouselBoxContainer({
  children,
  bgColor,
  maxWidth,
  mb,
  border,
  ...rest
}: Readonly<CarouselBoxContainerProps>) {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      elevation={0}
      component="div"
      {...rest}
      maxWidth={maxWidth ?? "90%"}
      marginBottom={mb ?? "50px"}
      overflow={"inherit"}
      padding="0px"
      bgcolor="transparent!important"
      className="secloader-wrp"
      position="relative"
      sx={{
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
        ".slick-slide .MuiGrid-root": {
          maxWidth: "calc(100% - 24px)",
          margin: "auto",
        },
        ".slick-slider": {
          margin: " 0 -12px",
        },
      }}
    >
      {children}
    </Grid>
  );
}
