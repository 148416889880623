import React from "react";
import { BoxProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AMTotalPriceProps extends BoxProps {
  value: number;
}

const AMTotalPrice = ({ value }: AMTotalPriceProps) => {
  const { t } = useTranslation();

  return (
    <Typography
      variant="h5"
      className="total-prize"
      sx={{
        marginBottom: "10px",
      }}
    >
      <b>{t("totalPrice")} : &nbsp;</b>
      <span>${value}</span>
    </Typography>
  );
};

export default AMTotalPrice;
