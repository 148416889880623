import React from "react";
import { CardMedia } from "@mui/material";

interface AMVideoProps {
  videoUrl: string;
}
const AMVideo = ({ videoUrl }: Readonly<AMVideoProps>) => {
  return (
    <CardMedia
      component="video"
      controls
      autoPlay={false}
      loop
      muted
      style={{ height: "100%" }}
      src={videoUrl}
      controlsList="nodownload"
      disablePictureInPicture
    />
  );
};

export default AMVideo;
