import * as React from "react";
import { Card, CircularProgress } from "@mui/material";

const AMSectionLoader = () => {
  return (
    <Card
      elevation={0}
      sx={{
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        bgcolor: "rgba(255, 255, 255, .7)",
        zIndex: 10000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Card>
  );
};

export default AMSectionLoader;
