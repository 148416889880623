import React from "react";
import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface AMLinkProps {
  value: string;
  link: string;
  route?: boolean;
  target?: string;
}
const AMLink = ({ value, link, route, target }: Readonly<AMLinkProps>) => {
  return (
    <span>
      {route ? (
        <RouterLink className="custom-link" to={link}>
          {value}
        </RouterLink>
      ) : (
        <MuiLink
          href={link}
          target={target}
          color="secondary.light"
          marginLeft="5px"
        >
          {value}
        </MuiLink>
      )}
    </span>
  );
};

export default AMLink;
