import React, { useEffect, ReactNode } from "react";
import { Outlet, useLocation } from "react-router-dom";

import ROUTES from "utils/CommonRouter";
import MainHeader from "./Header";
import MainFooter from "./Footer";
import { useContextCommonDataModel } from "context/CommonContext";
import { windowScroll } from "utils/CommonFunctions";

interface MainLayoutPageProps {
  children?: ReactNode;
}

const MainLayoutPage = ({ children }: Readonly<MainLayoutPageProps>) => {
  const { pushRouteInLocalStorage } = useContextCommonDataModel();

  const { pathname, key } = useLocation();
  useEffect(() => {
    pushRouteInLocalStorage(pathname);
    windowScroll();
  }, [pathname, key, pushRouteInLocalStorage]);

  return (
    <div>
      {/* HEADER SECTION STARTS HERE */}
      <MainHeader />
      {/* HEADER SECTION ENDS HERE */}
      {/* ALL PAGES SECTION STARTS HERE */}
      <main
        className={`main-wrp ${
          pathname === ROUTES["INDEX_ROUTE"] ? "homemain-wrp" : ""
        } `}
      >
        {children ?? <Outlet />}
      </main>
      {/* ALL PAGES SECTION ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <MainFooter />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
};
export default MainLayoutPage;
