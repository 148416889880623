import React from "react";
import "css/App.css";
import { ThemeProvider } from "@mui/material";
import { CommonContextProvider } from "context/CommonContext";
import "./i18n";
import createTheme from "./themes/Theme";
import Router from "routes/index";

const App = () => {
  return (
    <CommonContextProvider>
      <ThemeProvider theme={createTheme}>
        <Router />
      </ThemeProvider>
    </CommonContextProvider>
  );
};

export default App;
