import React, { useEffect, ReactNode } from "react";
import { Outlet, useLocation } from "react-router-dom";
import * as colors from "designsystem/Palette";
import ROUTES from "utils/CommonRouter";
import MainHeader from "../Main/Header";
import MainFooter from "../Main/Footer";
import { useContextCommonDataModel } from "context/CommonContext";
import { windowScroll } from "utils/CommonFunctions";
import { Box } from "@mui/system";
import { uiConfig } from "designsystem";
import { Container } from "@mui/material";

interface MainLayoutPageProps {
  children?: ReactNode;
}

const MainLayoutPage = ({ children }: MainLayoutPageProps) => {
  const { pushRouteInLocalStorage } = useContextCommonDataModel();
  const { pathname, key } = useLocation();

  useEffect(() => {
    pushRouteInLocalStorage(pathname);
    windowScroll();
  }, [pathname, key, pushRouteInLocalStorage]);

  return (
    <div>
      <MainHeader leftMenu={true} boxShadow={uiConfig.AMBreadcrumbsShadow} />
      <Box bgcolor={colors.clientDetailBg}>
        <Container
          maxWidth="xl"
          sx={{
            padding: "0!important",
          }}
        >
          <main
            className={`main-wrp topspaceextra ${
              pathname === ROUTES["INDEX_ROUTE"] ? "homemain-wrp" : ""
            }`}
          >
            <Box p={3}>{children ?? <Outlet />}</Box>
          </main>
        </Container>
      </Box>
      <MainFooter />
    </div>
  );
};

export default MainLayoutPage;
