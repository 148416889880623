import React, { ReactNode } from "react";
import { DrawerProps as MuiDrawerProps, Drawer, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

interface DrawerProps extends MuiDrawerProps {
  value: ReactNode;
  children?: ReactNode;
  clickevent?: () => void;
  opendrawer?: boolean;
  closeclickevent?: () => void;
}

const AMMenuDrawer = ({
  value,
  children,
  clickevent,
  opendrawer,
  closeclickevent,
  ...muiDrawerProps
}: Readonly<DrawerProps>) => {
  return (
    <div>
      {/* CLICK TO OPEN DRAWER SECTION STARTS HERE */}
      <Button
        onClick={clickevent}
        disableFocusRipple
        disableTouchRipple
        aria-label={"Drawer Button"}
        aria-labelledby={"Drawer Button"}
        sx={{
          backgroundColor: "transparent!important",
          padding: 0,
          minWidth: "auto",
        }}
      >
        {value}
      </Button>
      {/* CLICK TO OPEN DRAWER SECTION ENDS HERE */}

      {/* DRAWER SECTION STARTS HERE */}
      <Drawer
        anchor={muiDrawerProps.anchor ?? "left"}
        open={opendrawer}
        onClose={closeclickevent}
        elevation={0}
      >
        {/* CLOSE DRAWER SECTION STARTS HERE */}
        <ArrowBack
          sx={{
            color: "#08090F",
            cursor: "pointer",
            marginTop: "12px",
            marginBottom: "25px",
            marginLeft: "16px",
          }}
          onClick={clickevent}
        />
        {/* CLOSE DRAWER SECTION ENDS HERE */}
        {children}
      </Drawer>
      {/* DRAWER SECTION ENDS HERE */}
    </div>
  );
};

export default AMMenuDrawer;
