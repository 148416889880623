import React, { ReactNode } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AMPageTitle } from "components/common";
import { useTheme } from "@mui/material/styles";

interface AMCommonInfoCardProps {
  title: string;
  description: string | ReactNode;
  icon?: ReactNode;
  button?: ReactNode;
  statusButton?: ReactNode;
  isSmall?: boolean;
  smBreakPoint?: boolean;
  isLgLabel?: boolean;
  required?: boolean;
}
const AMCommonInfoCard = ({
  title,
  description,
  icon,
  statusButton,
  button,
  isSmall,
  smBreakPoint,
  isLgLabel,
  required,
}: Readonly<AMCommonInfoCardProps>) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card className="infocardwrp">
      <CardContent
        className="titlewrp"
        sx={{
          display: isMobile && smBreakPoint ? "block!important" : "",
        }}
      >
        <CardContent className="title-statusbtn">
          <AMPageTitle title={title} isH2={true} isSmall={isSmall} />
          {required && (
            <Typography
              variant="body2"
              sx={{
                color: "error.dark",
                lineHeight: "1rem",
              }}
            >
              *
            </Typography>
          )}
          {statusButton}
        </CardContent>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            display: isMobile && smBreakPoint ? "block" : "",
            width: isMobile && smBreakPoint ? "110%" : "auto",
            margin: isMobile && smBreakPoint ? "8px -16px -8px" : "",
          }}
        >
          {icon}
          {isMobile && smBreakPoint ? (
            <Box
              sx={{
                borderTop: "1px solid #eee",
                justifyContent: "flex-start",
                alignItems: "flex-center",
              }}
            >
              {button}
            </Box>
          ) : (
            button
          )}
        </Box>
      </CardContent>
      <CardContent
        sx={{
          ".input-label": {
            fontSize: isLgLabel ? "18px!important" : "14px!important",
          },
          ".MuiTypography-body1": {
            fontSize: isLgLabel ? "20px" : "16px",
          },
        }}
      >
        {typeof description === "string" ? (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        ) : (
          <div>{description}</div>
        )}
      </CardContent>
    </Card>
  );
};

export default AMCommonInfoCard;
