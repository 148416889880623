import { refreshToken } from "api/api";
import { getCookie, deleteCookie } from "utils/cookieService";
import { useContextCommonDataModel } from "context/CommonContext";
import { userProfileModelDefaultValues } from "models/user/UserProfileModel";
import ROUTES from "utils/CommonRouter";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { removeLocalStorage } from "utils/CommonFunctions";
import { useTranslation } from "react-i18next";
import {
  ERROR_REASON_AT_ERROR,
  ERROR_REASON_AT_EXPIRED,
  ERROR_REASON_AUTHORIZATION_ERROR,
  ERROR_REASON_AUTH_UNKNOWN_ERROR,
  ERROR_REASON_RT_ERROR,
  ERROR_REASON_RT_EXPIRED,
  ERROR_STATE,
  SUCCESS_STATE,
} from "utils/CommonText";
import { ApiResult } from "utils/CommonTypes";

const useApi = () => {
  const { t } = useTranslation("gen-error-message");
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { updateUserDataModel, authenticated } = useContextCommonDataModel();
  const navigate = useNavigate();

  const userData = {
    userProfile: userProfileModelDefaultValues,
    authenticated: false,
    token: "",
    roles: [],
  };

  const request = async (
    { apiFunction }: { apiFunction: Function },
    requiresAuthentication: boolean = true,
    ...args: any[]
  ) => {
    setLoading(true);

    try {
      if (requiresAuthentication && !authenticated) {
        removeLocalStorage();
        navigate(ROUTES["SIGN_IN"]);
        return;
      } else if (!requiresAuthentication && !authenticated) {
        const result = await apiFunction(...args);
        if (result?.status || result.response.status) {
          setData(result);
        }
        return;
      }

      await tokenCheck();

      const result = await apiFunction(...args);
      await tokenCheck();
      if (result?.status === SUCCESS_STATE) {
        setData(result);
      } else {
        handleApiError(result);
      }
    } catch (error) {
      handleError(error as string);
    } finally {
      setLoading(false);
    }
  };

  const tokenCheck = async () => {
    const refreshTokenCookie = getCookie("rt");
    const accessTokenCookie = getCookie("at");

    if (!refreshTokenCookie) {
      handleTokenExpiration();
      return;
    }

    if (refreshTokenCookie && !accessTokenCookie) {
      await retryTokenRefresh();
    }
  };

  const retryTokenRefresh = async () => {
    let retryCount = 0;
    let refreshTokenResponse;

    while (retryCount < 3) {
      refreshTokenResponse = await refreshToken();

      if (refreshTokenResponse?.response?.status) {
        if (getCookie("at")) {
          break;
        }
      }

      retryCount++;
    }

    if (!getCookie("at")) {
      handleError("Failed to refresh token");
      handleTokenExpiration();
    }
  };

  const handleApiError = (result: ApiResult) => {
    const { status, reasonCode } = result;
    const errorReasons = [
      ERROR_REASON_RT_EXPIRED,
      ERROR_REASON_RT_ERROR,
      ERROR_REASON_AT_EXPIRED,
      ERROR_REASON_AT_ERROR,
      ERROR_REASON_AUTHORIZATION_ERROR,
      ERROR_REASON_AUTH_UNKNOWN_ERROR,
    ];

    if (status === ERROR_STATE && errorReasons.includes(reasonCode)) {
      navigateWithError(reasonCode);
    } else {
      setData(result);
    }
  };

  const handleTokenExpiration = () => {
    updateUserDataModel(userData);
    deleteCookie(["at", "rt"]);
    navigateWithError("RT_EXPIRED");
    removeLocalStorage();
  };

  const navigateWithError = (reasonCode: string) => {
    updateUserDataModel(userData);
    deleteCookie(["at", "rt"]);
    navigate(ROUTES["SIGN_IN"], {
      state: {
        message: t(reasonCode),
        enableToaster: true,
      },
    });
    removeLocalStorage();
  };

  const handleError = (error: string) => {
    setError(error);
    setLoading(false);
  };

  return { data, error, loading, request };
};

export default useApi;
