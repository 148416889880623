import * as React from "react";
import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AMErrorTextProps {
  value: string;
  topSpacce?: string;
}

const AMErrorText = ({ value, topSpacce }: Readonly<AMErrorTextProps>) => {
  const { t } = useTranslation("validation");

  return (
    <FormHelperText
      className="input-errors"
      sx={{
        marginTop: topSpacce ?? "",
      }}
      error={true}
    >
      {t(value)}
    </FormHelperText>
  );
};
export default AMErrorText;
