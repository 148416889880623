import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const FormLabelStyles = {
  root: {
    color: colors.secondaryLightColor,
    overflow: uiConfig.AMInherit,
    transform: uiConfig.AMNone,
    borderBottom: uiConfig.AM0,
  },
};
