import React, { useState } from "react";
import { Button, CardContent, Input, Typography } from "@mui/material";
import AMLabel from "./AM_Label";
import { Cancel } from "@mui/icons-material";
import AMButton from "./AM_Button";
import AMImage from "./AM_Image";
import AMModalPopup from "./AM_ModalPopup";

interface AMFileUploadProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  value: any;
  onchange: (value: any) => void;
  acceptFormat: string[];
  inputClearCallback?: () => void;
  isDisabled?: boolean;
}

const AMFileUpload = ({
  label,
  required,
  value,
  onchange,
  acceptFormat,
  placeholder,
  inputClearCallback,
  isDisabled,
}: Readonly<AMFileUploadProps>) => {
  const [fileURL, setFileURL] = useState<string | null>(value?.name ?? null);
  const [isOpen, setIsOpen] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      onchange(selectedFile);
      setFileURL(URL.createObjectURL(selectedFile));
    }
  };
  const isImage = value?.type.startsWith("image/");
  const isVideo = value?.type.startsWith("video/");
  const isPDF = value?.type === "application/pdf";

  const imageView = () => {
    setIsOpen(true);
  };
  const closeImageView = () => {
    setIsOpen(false);
  };
  const acceptString = acceptFormat.map((format) => `.${format}`).join(",");

  return (
    <div>
      <AMLabel label={label} required={required} />
      <CardContent className="file-upload-wrp">
        <Input
          sx={{
            paddingRight: "260px",
          }}
          value={value.name}
          placeholder={placeholder}
          className={isDisabled ? "Mui-disabled" : ""}
        />
        <CardContent
          className={`file-upload-button ${isDisabled ? "Mui-disabled" : ""}`}
        >
          <Input
            inputProps={{
              accept: acceptString,
            }}
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            sx={{ display: "none" }}
          />
          <label htmlFor="file-upload">
            <Button component="span" disableFocusRipple disableRipple>
              Upload File
            </Button>
          </label>
          {value?.name !== "" && (
            <>
              <Cancel className="clearBtn" onClick={inputClearCallback} />
              <AMButton
                value={"view " + (isImage ? "image" : isVideo ? "video" : "")}
                margintop=""
                isSmall
                variant="outlined"
                buttonClick={() => imageView()}
              />
            </>
          )}
        </CardContent>
      </CardContent>

      {value?.name !== "" && (
        <Typography
          className={isDisabled ? "Mui-disabled" : ""}
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          {value.name}
        </Typography>
      )}

      <AMModalPopup
        title={"view"}
        description={
          <>
            {fileURL && isImage && (
              <AMImage imgUrl={fileURL} imgName={value.name} />
            )}
            {fileURL && isVideo && <video src={fileURL} controls />}
            {fileURL && isPDF && (
              <iframe
                title="pdf"
                src={fileURL}
                style={{ width: "100%", height: "auto", border: "none" }}
              />
            )}
          </>
        }
        handleClose={closeImageView}
        open={isOpen}
      />
    </div>
  );
};

export default AMFileUpload;
