import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const FormControlStyles = {
  root: {
    width: uiConfig.AMfull,
    borderBottom: `${uiConfig.AM1} ${textStyles.border.solid}`,
    fontSize: uiConfig.AM18,
    borderColor: `${colors.datepickerBorder} !important`,
    "& input ": {
      fontSize: uiConfig.AM18,
      padding: `${uiConfig.AMDateTimePicker} !important`,
      fontWeight: textStyles.fontWeight.strong,
    },
    "&:after, &:before, & .MuiOutlinedInput-notchedOutline, & .MuiTouchRipple-root":
      {
        display: `${uiConfig.AMNone} !important`,
      },
    "& .MuiButtonBase-root, & .MuiButtonBase-root:active, & .MuiButtonBase-root:hover, & .MuiButtonBase-root:focus":
      {
        bgcolor: `${colors.transparent} !important`,
      },
  },
};
