import RolesModel, {rolesModelDefaultValues} from "./RolesModel";
import UserProfileModel, {userProfileModelDefaultValues} from "./UserProfileModel";


interface UserModel  {
    userProfile: UserProfileModel,
    authenticated: boolean,
    token: string,
    roles: RolesModel[]
}

export const userDataModelDefaultValues : UserModel = {
    userProfile: userProfileModelDefaultValues,
    authenticated: false,
    token: "",
    roles: [rolesModelDefaultValues]
}

export default UserModel;