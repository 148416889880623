import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const SliderStyles = {
  root: {
    color: colors.primaryDarkColor,
    ".MuiSlider-valueLabel": {
      backgroundColor: colors.secondaryDarkColor,
      color: colors.primaryDarkColor,
      border: uiConfig.AM0,
      marginTop: uiConfig.AM0,
      boxShadow: `${uiConfig.AM0} ${uiConfig.AM0} ${uiConfig.AM1} ${colors.sliderShadow}`,
      bottom: `-${uiConfig.AM5}`,
      top: uiConfig.AMAuto,
      padding: uiConfig.AMBtnReducePadding,
    },
    ".MuiSlider-valueLabelOpen": {
      transform: "translateY(100%) scale(1)!important",
    },
    ".MuiSlider-valueLabelOpen:before": {
      top: `-${uiConfig.AM8}`,
      bottom: uiConfig.AMAuto,
    },
  },
};
