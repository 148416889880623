import React from "react";
import { Button, CardMedia } from "@mui/material";
import { useLocation, NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { removeLocalStorage } from "utils/CommonFunctions";
import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";
import { getCookie } from "utils/cookieService";

interface NavLinkProps {
  menu?: string;
  to: string;
  customKey?: number;
  alignTxt?: string;
  route?: string;
  color?: string;
  icon?: string;
  flexDir?: string;
  alignItems?: string;
  footerSpace?: boolean;
}

const AMNavLink = ({
  menu,
  to,
  customKey,
  route,
  alignTxt,
  color,
  icon,
  footerSpace,
  flexDir,
  alignItems,
}: Readonly<NavLinkProps>) => {
  const { pathname } = useLocation();
  const theme = useTheme();

  const handleBreadcrumb = () => {
    const storedUser = getCookie("at");

    if (!storedUser) {
      removeLocalStorage();
    }
  };

  const StyledNavLink = styled(NavLink)(() => ({
    display: "inline-block",
    "&.active .MuiButtonBase-root, &:hover .MuiButtonBase-root": {
      borderBottomColor: colors.primaryColor,
    },
  }));

  return (
    <div>
      <StyledNavLink
        className="navLink"
        to={to ?? "/"}
        key={customKey}
        tabIndex={-1}
        aria-labelledby="Nav Link"
        onClick={handleBreadcrumb}
      >
        <Button
          disableFocusRipple
          disableTouchRipple
          sx={
            {
              margin: footerSpace ? "6px 0" : "0px 20px",
              padding: 0,
              fontSize: textStyles.fontSize.base,
              textAlign: alignTxt ?? "initial",
              color: color ?? "common.black",
              background: "none!important",
              minWidth: uiConfig.AM0,
              borderBottomWidth: uiConfig.AM3,
              lineHeight: uiConfig.AM16,
              borderRadius: uiConfig.AM0,
              borderBottomStyle: "solid",
              borderBottomColor:
                pathname === route ? colors.primaryColor : "transparent",
              flexDirection: flexDir ?? "row",
              alignItems: alignItems ?? "center",
              [theme.breakpoints.down("lg")]: {
                margin: footerSpace ? "6px 0" : "6px 15px",
                fontSize: textStyles.fontSize.medium,
              },
              [theme.breakpoints.down("md")]: {
                margin: footerSpace ? "6px 0" : "6px 25px",
                fontSize: textStyles.fontSize.base,
              },
            } as React.CSSProperties
          }
          aria-label={menu || "Menu icon"}
          aria-labelledby={menu || "Menu icon"}
        >
          {/* MENU IMAGE ICON */}
          {menu || (
            <CardMedia
              component="img"
              image={icon}
              alt={"Menu icon"}
              width="40px"
              height="25px"
              sx={{
                maxWidth: uiConfig.AM40,
              }}
            />
          )}
          {/* MENU IMAGE ICON */}
        </Button>
      </StyledNavLink>
    </div>
  );
};
export default AMNavLink;
