import React, { ReactNode, SyntheticEvent, MouseEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material";
import {
  ExpandMore,
  AddBoxOutlined,
  IndeterminateCheckBoxOutlined,
} from "@mui/icons-material";
import { textStyles, uiConfig } from "designsystem";

interface AccordionProps {
  contentId?: number;
  name?: string | ReactNode;
  color?: string;
  description?: string | ReactNode;
  plusMinus?: boolean | string;
  checked?: boolean;
  isCheckbox?: boolean;
  handleCheckToggle?: () => void;
  expanded?: boolean;
  onChange?: (event: SyntheticEvent, isExpanded: boolean) => void;
}

const AMAccordion = ({
  contentId,
  name,
  description,
  checked,
  plusMinus,
  handleCheckToggle,
  isCheckbox,
  expanded,
  onChange,
}: Readonly<AccordionProps>) => {
  const handleCheckboxClick = (event: MouseEvent) => {
    event.stopPropagation();
    if (handleCheckToggle) handleCheckToggle();
  };

  let icon;
  if (plusMinus) {
    icon = expanded ? <IndeterminateCheckBoxOutlined /> : <AddBoxOutlined />;
  } else {
    icon = <ExpandMore />;
  }

  return (
    <Card
      elevation={0}
      sx={{
        border: isCheckbox ? "" : "1px solid #eee",
      }}
    >
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={onChange}
        className={`${isCheckbox ? "checkboxAccordion" : ""} ${
          checked ? "active" : ""
        } ${!plusMinus && "expandarrowspace"}`}
      >
        <AccordionSummary
          sx={{
            minHeight: `${uiConfig.AM0}!important`,
            fontWeight: textStyles.fontWeight.strong,
            color: "secondary.light",
          }}
          expandIcon={
            <div className={`expandarrow ${plusMinus && "expand-plusminus"}`}>
              {icon}
            </div>
          }
          aria-controls={`panel${contentId}bh-content`}
          id={`panel${contentId}bh-header`}
        >
          {isCheckbox ? (
            <Box className="name-wrp" onClick={handleCheckboxClick}>
              <Checkbox
                edge="start"
                checked={checked}
                disableRipple
                onClick={handleCheckboxClick}
              />
              <ListItemText primary={name} />
            </Box>
          ) : (
            name
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            borderTop: "1px solid #eee",
            padding: uiConfig.AMAccordionPadding,
            "& br": {
              display: "none",
            },
          }}
        >
          {typeof description === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <Box display="flex" flexDirection="column" gap="10px">
              {description}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default AMAccordion;
