import React, { ReactNode } from "react";
import { CardContent, CardMedia, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CarouselCardProps {
  carouseldescription?: ReactNode;
  carouselimage?: string;
}

const AMCarouselItems = ({
  carouseldescription,
  carouselimage,
}: Readonly<CarouselCardProps>) => {
  const theme = useTheme();

  return (
    <CardContent
      sx={{
        padding: "0px 5px!important",
      }}
    >
      {/* CAROUSEL IMAGE STARTS */}

      <CardContent
        sx={{
          padding: "10px",
          flex: "1 0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid rgba(255, 157, 202, 0.05)",
          height: "196px",
          borderRadius: "20px",
          width: "100%",
          [theme.breakpoints.down("lg")]: {
            margin: "auto",
            borderRadius: "10px",
          },
        }}
      >
        <CardMedia
          component="img"
          image={carouselimage}
          alt="carousel img"
          width="auto"
          height="auto"
          sx={{
            width: "auto",
            objectFit: "inherit",
            maxWidth: "100%",
            maxHeight: "100%",
            borderRadius: "10px",
            [theme.breakpoints.down("lg")]: {
              borderRadius: "10px",
            },
          }}
        />
      </CardContent>
      {/* CAROUSEL IMAGE ENDS */}
      {/* CAROUSEL DESCRIPTION STARTS */}
      <Typography
        variant="caption"
        className="ellipsis ellipsis-5"
        sx={{
          minHeight: "84px",
          wordBreak: "break-all",
        }}
      >
        {carouseldescription}
      </Typography>
      {/* CAROUSEL DESCRIPTION ENDS */}
    </CardContent>
  );
};

export default AMCarouselItems;
