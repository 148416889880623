const UiConfig = {
  AM170: "170px",
  AM0: "0px",
  AM1: "1px",
  AM16: "16px",
  AM10: "10px",
  AM20: "20px",
  AM25: "25px",
  AM23: "23px",
  AM24: "24px",
  AM90: "90px",
  AM117: "117px",
  AMBtnPadding: "0px 22px",
  AMItemPadding: " 2px 15px",
  AMBtnReducePadding: "0px 10px",
  AM678: "678px",
  AM165: "165px",
  AM25per: "25%",
  AM50per: "50%",
  AM75per: "75%",
  AM80per: "80%",
  AM90po5per: "99.5%",
  AMfull: "100%",
  AM285: "285px",
  AM12: "12px",
  AM174: "174px",
  AM250: "250px",
  AM50: "50px",
  AM15: "15px",
  AM18: "18px",
  AMPoint17: "0.17px",
  AMOpa7: "0.07",
  AM3: "3px",
  AM5: "5px",
  AM58: "58px",
  AM56: "56px",
  AM8: "8px",
  AM7: "7px",
  AM26: "26px",
  AM60: "60px",
  AM80: "80px",
  AM40: "40px",
  AM43: "43px",
  AM30: "30px",
  AM34: "34px",
  AM6: "6px",
  AM200: "200px",
  AM270: "270px",
  AM272: "272px",
  AM3Point5: "3.5px",
  AM11Point5: "11.5px",
  AM5Point5: "5.5px",
  AM100: "100px",
  AM120: "120px",
  AMAccordionPadding: "20px 16px",
  AMNotFound: "10px 0px",
  AMNegative5: "-5",
  AMDrwopDownBtn: "0px 5px",
  AMBreadcrumb: "15px 0px",
  AMDateTimePicker: "4px 0 5px",
  AMCal508: "calc(100vh - 508px)",
  AMCal413: "calc(100vh - 413px)",
  AMBreadcrumbsShadow: "rgba(0, 0, 0, 0.05) 0rem 0rem 0.6875rem 0rem",
  AMPaperShadow: "rgba(0, 0, 0, 0.04) 0rem 0rem 0.6875rem .2rem",
  AMInherit: "inherit",
  AMInitial: "initial",
  AMNone: "none",
  AMNormal: "normal",
  AMAuto: "auto",
  AMNowrap: "nowrap",
  AMBreakSpaces: "break-spaces",
  AMDefault: "default",
  AMEllipsis: "ellipsis",
  AMAppBarGrid: "23% auto 23%",
};

export default UiConfig;
