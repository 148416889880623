import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const HelperTextStyles = {
  root: {
    fontSize: textStyles.fontSize.large,
    fontFamily: textStyles.fontFamily.defaultFont,
    fontWeight: textStyles.fontWeight.strong,
    lineHeight: uiConfig.AM16,
    color: colors.errorMainColor,
  },
};
