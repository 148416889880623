import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const CardStyles = {
  root: {
    "&.default-filter-wrap": {
      overflow: "visible",
      ".MuiCardHeader-root": {
        background: "none!important",
        padding: `${uiConfig.AM0} !important`,
        paddingBlock: `${uiConfig.AM16} !important`,
        border: `${uiConfig.AM0} !important`,
        ".MuiTypography-root": {
          fontFamily: `${textStyles.fontFamily.defaultFont} !important`,
          fontSize: `${textStyles.fontSize.extraLarge}px !important`,
          fontWeight: `${textStyles.fontWeight.strong} !important`,
        },
      },
      ".MuiCardContent-root": {
        boxShadow: uiConfig.AMPaperShadow,
        borderRadius: "15px",
        paddingRight: `${uiConfig.AM5} !important`,
      },
      ".default-filter-item": {
        paddingBottom: `${uiConfig.AM16} !important`,
        h2: {
          fontFamily: `${textStyles.fontFamily.defaultFont} !important`,
          fontSize: `${textStyles.fontSize.base}px !important`,
          fontWeight: `${textStyles.fontWeight.strong} !important`,
          margin: 0,
          textTransform: "upperCase",
        },
        ".common-checkbox": {
          margin: 0,
          ".MuiSvgIcon-root": {
            margin: 0,
          },
        },
        ".filter-checkbox-item": {
          display: "flex",
          flexDirection: "column",
          // gap: "10px",
        },
      },
      ".default-filter-content-wrp": {
        height: "calc(100vh - 225px)",
      },
    },
    ".modal-description": {
      maxHeight: "calc(100vh - 125px)",
    },
    "&.filter-popup-wrp ": {
      padding: uiConfig.AM10,
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      ".MuiCardHeader-root": {
        background: "none!important",
        padding: `${uiConfig.AM10} !important`,
        ".MuiTypography-root": {
          fontFamily: `${textStyles.fontFamily.defaultFont} !important`,
          fontSize: `${textStyles.fontSize.large}px !important`,
          fontWeight: `${textStyles.fontWeight.strong} !important`,
        },
      },
      ".common-checkbox": {
        marginTop: "0px",
      },
      ".filter-content-wrp>.MuiPaper-root": {
        marginBottom: uiConfig.AM10,
      },
      ".filter-checkbox-item": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      },
      ".MuiAccordionSummary-content": {
        margin: `${uiConfig.AM0}!important`,
        fontFamily: `${textStyles.fontFamily.defaultFont} !important`,
      },
      ".MuiAccordionDetails-root, .MuiButtonBase-root.MuiAccordionSummary-root":
        {
          paddingBlock: "10px",
        },
    },

    ".file-upload-wrp": {
      padding: `${uiConfig.AM0} !important`,
      position: "relative",
      ".file-upload-button": {
        position: "absolute",
        right: 0,
        bottom: "5px",
        padding: `${uiConfig.AM0} !important`,
        display: "flex",
        alignItems: "center",
        gap: "10px",
        ".MuiButton-root": {
          lineHeight: "normal",
          background: "none !important",
          fontFamily: textStyles.fontFamily.defaultFont,
          borderRadius: uiConfig.AM20,
          backgroundColor: `${colors.primaryDarkColor} !important`,
          color: colors.whiteColor,
          padding: "2px 15px 0",
          textDecoration: "none",
          textTransform: "uppercase",
          fontWeight: "bold",
        },
      },
    },
    ".preview-wrp": {
      width: "300px",
      height: "300px",
      border: "1px solid #ddd",
      marginTop: "10px",
      padding: 0,
      borderRadius: "10px",
      overflow: "hidden",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    "&.weblink-wrp": {
      position: "relative",
      "&>span": {
        position: "absolute",
        right: 0,
        bottom: "5px",
        a: {
          fontFamily: textStyles.fontFamily.defaultFont,
          borderRadius: uiConfig.AM20,
          backgroundColor: colors.primaryDarkColor,
          color: colors.whiteColor,
          padding: "2px 15px 0",
          textDecoration: "none",
          textTransform: "uppercase",
          fontWeight: "bold",
          lineHeight: "normal",
        },
      },
    },
    ".cardactionbtn": {
      padding: uiConfig.AM0,
    },
    ".MuiCardContent-root:last-child": {
      paddingBottom: uiConfig.AM16,
    },

    ".contactinfo": {
      display: textStyles.display.flex,
    },
    ".maildescription": {
      padding: uiConfig.AM0,
      width: uiConfig.AM75per,
      ".MuiTypography-root": {
        marginTop: uiConfig.AM5,
        "& a": {
          color: colors.blackColor,
        },
      },
    },
    ".actionprogress": {
      width: uiConfig.AM25per,
      padding: uiConfig.AM0,
      display: textStyles.display.flex,
      alignItems: textStyles.alignItems.center,
      justifyContent: textStyles.justifyContent.flexEnd,
      gap: uiConfig.AM20,
    },
    "&.account-detail-wrp": {
      overflow: uiConfig.AMInherit,
      ".MuiCardContent-root": {
        padding: `${uiConfig.AM0} ${uiConfig.AM0} ${uiConfig.AM10}`,
      },
      ".MuiGrid-container": {
        borderTop: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.infoCardBr}`,
        marginRight: `-${uiConfig.AM16}`,
        width: uiConfig.AMAuto,
        paddingRight: uiConfig.AM16,
        marginTop: uiConfig.AM0,
        marginBottom: `-${uiConfig.AM15}`,
      },
    },
    ".editmedia-wrp": {
      overflow: uiConfig.AMInherit,
      "&.bottomedit": {
        paddingBottom: `${uiConfig.AM25} !important`,
        ".MuiCardContent-root": {
          paddingBottom: `${uiConfig.AM0} !important`,
          marginBottom: `${uiConfig.AM6} !important`,
        },
      },
      ".MuiCardContent-root": {
        padding: uiConfig.AM0,
        fontFamily: textStyles.fontFamily.defaultFont,
        lineHeight: uiConfig.AM20,
        minHeight: uiConfig.AM40,
        b: {
          marginBottom: uiConfig.AM5,
          display: textStyles.display.block,
        },
      },
      ".profiletxt": {
        display: textStyles.display.flex,
        alignItems: textStyles.alignItems.center,
        justifyContent: textStyles.justifyContent.spaceBetween,
        padding: uiConfig.AM0,
        marginTop: `-${uiConfig.AM7}`,
        ".MuiTypography-root": {
          fontSize: textStyles.fontSize.ms10,
          fontWeight: textStyles.fontWeight.strong,
        },
      },
      ".desaction": {
        justifyContent: textStyles.justifyContent.spaceAround,
        position: textStyles.position.absolute,
        left: uiConfig.AM0,
        right: uiConfig.AM0,
        bottom: uiConfig.AM0,
        padding: `${uiConfig.AM0} ${uiConfig.AM16} ${uiConfig.AM10}`,
        gap: uiConfig.AM10,
        ".MuiSvgIcon-root": {
          background: "#fff5fa",
          border: "1px solid #ffd9e0",
          width: `${uiConfig.AM60} !important`,
          height: `${uiConfig.AM30} !important`,
          padding: uiConfig.AM5,
          borderRadius: uiConfig.AM5,
          flex: 1,
          margin: uiConfig.AM0,
        },
      },
    },
    ".arrow-wrapper": {
      justifyContent: textStyles.justifyContent.center,
      gap: uiConfig.AM5,
      padding: `${uiConfig.AM10} ${uiConfig.AM0} ${uiConfig.AM10}`,
      ".MuiTypography-root": {
        margin: uiConfig.AM0,
        border: uiConfig.AM0,
        lineHeight: uiConfig.AMNormal,
        fontSize: textStyles.fontSize.large,
        color: colors.arrowTyClr,
      },
      button: {
        background: uiConfig.AMNone,
        border: uiConfig.AM0,
        margin: uiConfig.AM0,
        cursor: textStyles.cursor.pointer,
        "&.slickPrev": {
          transform: `${textStyles.transform.sliderotate} !important`,
        },
        "&.slick-disabled": {
          webkitFilter: "grayscale(100%)",
          filter: "grayscale(100%)",
          cursor: textStyles.cursor.notAllowed,
        },
      },
      "&.outer-carousel-arrow": {
        position: textStyles.position.absolute,
        left: uiConfig.AM0,
        right: uiConfig.AM0,
        bottom: `-${uiConfig.AM43}`,
        padding: uiConfig.AM0,
      },
    },
  },
};
