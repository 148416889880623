import * as React from "react";
import { Box } from "@mui/material";
import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

interface NoFoundProps {
  value: string;
  isSmall?: boolean;
  minHeight?: string;
}

const AMNotFound = ({ value, minHeight, isSmall }: Readonly<NoFoundProps>) => {
  return (
    <Box
      padding={uiConfig.AMNotFound}
      alignItems="center"
      justifyContent="center"
      typography="caption"
      display="flex"
      color={colors.primaryColor}
      fontWeight="bold"
      fontSize={isSmall ? "15px" : "18px"}
      marginTop={0}
      className="notfound"
      sx={{
        minHeight: minHeight ?? uiConfig.AM120,
      }}
    >
      {value}
    </Box>
  );
};

export default AMNotFound;
