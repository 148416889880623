import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "js-cookie";

let cookieValue = Cookies.get("TILang");
if (cookieValue === "") {
  cookieValue = "en";
}

i18n
  .use(initReactI18next)
  .use(i18nBackend)
  .use(LanguageDetector)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: "en",
    lng: cookieValue,
    detection: {
      order: ["cookie", "navigator"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `/locale/{{lng}}/{{ns}}.json`,
    },

    ns: [
      "common",
      "menu",
      "home",
      "about",
      "contact",
      "client-detail",
      "gen-error-message",
      "notification",
      "gen-faq",
      "signin",
      "create-account",
      "forgot-password",
      "reset-password",
      "validation",
      "report-fraud",
      "under-construction",
      "gen-success-message",
      "menu-item",
      "dashboard",
      "table-form",
      "advertmingo-contacts",
      "advertmingo-bank-details",
      "advertmingo-payment",
      "country-names",
      "advertmingo-payment"
    ],

    defaultNS: "common",
    cache: {
      lookupCookie: "TILang",
      lookupLocalStorage: "i18nextLng",
    },
  });

export default i18n;
