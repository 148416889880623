import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const ListAvatarStyles = {
  root: {
    width: uiConfig.AM10,
    height: uiConfig.AM10,
    borderRadius: uiConfig.AMfull,
    minWidth: uiConfig.AMAuto,
    display: textStyles.display.flex,
    alignItems: textStyles.alignItems.center,
    justifyContent: textStyles.justifyContent.center,
    color: colors.whiteColor,
    marginRight: uiConfig.AM10,
    fontSize: textStyles.fontSize.macro,
    fontWeight: textStyles.fontWeight.normal,
    fontFamily: textStyles.fontFamily.defaultFont,
  },
};
