import React, { useState, useEffect, useRef, ReactNode } from "react";
import { Card, Button, Paper, Drawer, CardProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ArrowBack } from "@mui/icons-material";
import { setupMediaQueryListener } from "utils/CommonFunctions";
import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

interface CustomSelectProps {
  value: ReactNode;
  children?: ReactNode;
  TogglePopover: (() => void) | undefined;
  openTogglePopover?: boolean;
}
interface CustomSelectProps extends CardProps {}

const AMCustomSelect = ({
  value,
  children,
  TogglePopover = () => {},
  openTogglePopover,
  ...rest
}: Readonly<CustomSelectProps>) => {
  const theme = useTheme();

  // FOR MOBILE VIEW NOTIFICATION STARTS
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const tabScreen = setupMediaQueryListener(
      "(max-width: 1299px)",
      setIsSmallScreen
    );
    return tabScreen;
  }, []);
  // FOR MOBILE VIEW NOTIFICATION ENDS

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      const container = containerRef.current;
      if (
        container &&
        e.target instanceof Node &&
        !container.contains(e.target)
      ) {
        TogglePopover();
      }
    };

    document.addEventListener("mouseup", handleOutsideClick);

    return () => {
      document.removeEventListener("mouseup", handleOutsideClick);
    };
  }, [TogglePopover]);

  return (
    <Card
      elevation={0}
      sx={{
        padding: uiConfig.AM0,
        overflow: "inherit",
        position: "relative",
        bgcolor: "transparent!important",
        justifyContent: "flex-start",
      }}
    >
      {/* BUTTON CLICK STARTS */}
      <Button
        disableFocusRipple
        disableTouchRipple
        aria-label={"SelectButton"}
        aria-labelledby="SelectButton"
        sx={{
          "&.active": {
            bgcolor: `${colors.primaryContrastColor} !important`,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          },
          [theme.breakpoints.down("lg")]: {
            padding: uiConfig.AMDrwopDownBtn,
            marginTop: uiConfig.AMNegative5,
          },
        }}
        className={`dropdownbtn ${openTogglePopover ? "active" : ""}`}
        onClick={() => {
          TogglePopover();
        }}
      >
        {value}
      </Button>
      {/* BUTTON CLICK ENDS */}

      {isSmallScreen ? (
        <Drawer
          anchor="left"
          open={openTogglePopover}
          onClose={() => {
            TogglePopover();
          }}
          elevation={0}
        >
          {/* CLOSE DRAWER SECTION STARTS HERE */}
          <ArrowBack
            sx={{
              color: "customColor.menuIcon",
              cursor: "pointer",
              marginTop: uiConfig.AM12,
              marginBottom: uiConfig.AM10,
              marginLeft: uiConfig.AM16,
            }}
            onClick={() => TogglePopover()}
          />
          {/* CLOSE DRAWER SECTION ENDS HERE */}
          {children}
        </Drawer>
      ) : (
        <div>
          {/* WEB VIEW POPOVER STARTS */}
          {openTogglePopover && (
            <Paper
              ref={containerRef}
              sx={{
                padding: uiConfig.AM0,
                marginTop: uiConfig.AM0,
                position: "absolute",
                width: uiConfig.AM250,
                backgroundColor: colors.primaryContrastColor,
                right: uiConfig.AM0,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: uiConfig.AM15,
                borderTopRightRadius: uiConfig.AM0,
                overflow: "hidden",
              }}
            >
              {children}
            </Paper>
          )}
          {/* WEB VIEW POPOVER ENDS */}
        </div>
      )}
    </Card>
  );
};

export default AMCustomSelect;
