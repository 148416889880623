import React, { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

interface AMFlexCardProps extends BoxProps {
  children: ReactNode;
}

const AMFlexCard = ({ children, ...props }: AMFlexCardProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      {...props}
    >
      {children}
    </Box>
  );
};

export default AMFlexCard;
