import React from "react";
import { Box, Toolbar, Container, Grid } from "@mui/material";
import { AMApplicationLogo, AMNavLink } from "components/common/index";
import menuData from "data/front-end-modals/menu.json";

import ROUTES from "utils/CommonRouter";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { CopyRight } from "views/layout";

const MainFooter = () => {
  const { t } = useTranslation("menu");
  const routes: Record<string, string> = ROUTES;
  const theme = useTheme();

  return (
    <Box bgcolor="primary.contrastText" paddingY="15px">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            alignItems: "flex-start",
            [theme.breakpoints.down("lg")]: {
              flexDirection: "column",
            },
          }}
        >
          {/* LOGO STARTS HERE */}
          <AMApplicationLogo />
          {/* LOGO ENDS HERE */}

          {/* MENU STARTS HERE */}

          <Grid
            container
            spacing={2}
            marginLeft="108px"
            marginTop="0px"
            columnGap="91px"
            display="grid"
            gridTemplateColumns="118px 185px"
            sx={{
              [theme.breakpoints.down("lg")]: {
                margin: "40px 0px 20px",
                width: "100%",
                gap: "0px",
                gridTemplateColumns: "30% 50%",
              },
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "50% 50%",
              },
            }}
          >
            {menuData
              .filter((menu) => menu.isFooter)
              .map((menu) => (
                <Grid
                  item
                  key={menu.id}
                  paddingLeft="0px!important"
                  paddingTop="0px!important"
                  sx={{
                    [theme.breakpoints.down("lg")]: {
                      padding: "0px!important",
                    },
                  }}
                >
                  <Box display="inline-flex" flexDirection="column">
                    <AMNavLink
                      menu={t(menu.menu)}
                      to={routes[menu.route]}
                      customKey={menu.id}
                      footerSpace={true}
                      route={routes[menu.route]}
                      icon={menu.icon}
                      color="secondary.light"
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>

          {/* MENU ENDS HERE */}
        </Toolbar>
        {/* COPY RIGHT CONTENT STARTS HERE */}
        <CopyRight />
        {/* COPY RIGHT CONTENT ENDS HERE */}
      </Container>
    </Box>
  );
};

export default MainFooter;
