import {
  AccordianItems,
  CarouselItems,
  CarouselItemsCard,
  ClientDetailProps,
  ContactType,
  CurrencyData,
  CurrencyInfo,
  CustomError,
  DetailListData,
  FaqAccordian,
  FaqAccordianItems,
  FaqItems,
  OptionData,
  OptionItems,
  RouteItem,
  RouteWithChildren,
  TransformedOption,
  VerificationType,
  Option,
  PaymentStatusType,
  AdvertType,
  DataItem,
  PaymentDetails,
  TransformedPayment,
  Props,
  RenderCellParams,
  InfoBox,
  Column,
  VariableOption,
  AnalyticsType,
  ListData,
  SocialMediaInterestForm,
  SocialMediaInterestFormErrors,
  AdvertiserProject,
  AdvertiserClients,
  Filter,
  TransformedClient,
  TransformedType,
  AdvertiserCampaign,
} from "utils/CommonTypes";
import ROUTES from "utils/CommonRouter";
import _ from "lodash";
import contactTypes from "data/cms/user-contact-type.json";
import socialMediaTypes from "data/cms/social-media-type.json";
import verficationTypes from "data/cms/user-contact-verification-type.json";
import paymentType from "data/cms/payment-status-type.json";
import bankTypes from "data/cms/bank-type.json";
import {
  ADD_ICON,
  DELETE_ICON,
  EDIT_ICON,
  INTERESTS,
  LANGUAGES,
  ABOUT_FOLLOWS_TAB_KEY,
  VIEW_ICON,
  step1Keys,
  step2Keys,
  step3Keys,
  ABOUT_AUDIENCE_TAB_KEY,
  ABOUT_ME_TAB_KEY,
  ABOUT_ORGANIZATION_TAB_KEY,
  VARIABLE_KEY,
  ADVERTISER_CHANNEL_TAB_KEY,
  ADVERTISER_AUDIENCE_TAB_KEY,
  ADVERTISER_PROJECT_TAB_KEY,
  ADVERTISER_CLIENT_CONTACT_TAB_KEY,
  ADVERTISER_CLIENT_PROJECT_TAB_KEY,
  ADVERTISER_CLIENT_TAB_KEY,
  CHECK_FILE_UPLOAD,
  CHECK_TEXT_EDITOR,
  CHECK_WEB_LINK,
} from "./CommonText";
import React, { Dispatch, SetStateAction } from "react";
import {
  AddCircleOutlineOutlined,
  DeleteOutlined,
  ModeOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import currencyCodeJson from "data/cms/currency-codes-details.json";
import advertTypeJson from "data/cms/advert-type.json";
import { CardActions, Tooltip } from "@mui/material";
import mcProfileType from "data/cms/user-mc-profile-verification-type.json";
import userMediaChannelType from "data/cms/user-mc-type.json";
import mediaChannelType from "data/cms/mc-type.json";
import interestTypeJson from "data/front-end-modals/interest-type.json";
import languageTypeJson from "data/front-end-modals/language.json";
import selfAnalyticsJson from "data/cms/self-analytics-type.json";
import { routers } from "routes/index";
import { deleteCookie } from "./cookieService";
import contentFormatJson from "data/cms/advertiser-content-format-type.json";
import campaignTypeJson from "data/cms/content-campaign-status-type.json";

const convertDetailJsonToArray = (
  detailData: DetailListData,
  t: (key: string) => string
): ClientDetailProps => {
  let data: ClientDetailProps = {
    detail: {
      title: detailData.detailedGenPageTitle.isTranslation
        ? t(detailData.detailedGenPageTitle.translationKey)
        : detailData.detailedGenPageTitle.defaultTranslation,
      description: detailData.detailedGenPageMainContentText.isTranslation
        ? t(detailData.detailedGenPageMainContentText.translationKey)
        : detailData.detailedGenPageMainContentText.description,
    },
    whoItsFor: {
      title: detailData.detailedGenPageWhoItsForCarouselTitle.isTranslation
        ? t(detailData.detailedGenPageWhoItsForCarouselTitle.translationKey)
        : detailData.detailedGenPageWhoItsForCarouselTitle.defaultTranslation,
      carouselItems: carouselData(
        detailData.detailedGenPageWhoItsForCarousel,
        t
      ),
    },
    howItsWork: {
      title: detailData.detailedGenPageHowItWorksStepsCarouselTitle
        .isTranslation
        ? t(
            detailData.detailedGenPageHowItWorksStepsCarouselTitle
              .translationKey
          )
        : detailData.detailedGenPageHowItWorksStepsCarouselTitle
            .defaultTranslation,
      carouselItems: carouselData(
        detailData.detailedGenPageHowItWorksStepsCarousel,
        t
      ),
    },
    pricing: {
      title: detailData.detailedGenPagePricingTitle.isTranslation
        ? t(detailData.detailedGenPagePricingTitle.translationKey)
        : detailData.detailedGenPagePricingTitle.defaultTranslation,
      description: detailData.detailedGenPagePricingText.isTranslation
        ? t(detailData.detailedGenPagePricingText.translationKey)
        : detailData.detailedGenPagePricingText.description,
    },
    signIn: {
      title: detailData.detailedGenPageSignInTitle.isTranslation
        ? t(detailData.detailedGenPageSignInTitle.translationKey)
        : detailData.detailedGenPageSignInTitle.defaultTranslation,
      buttonText: detailData.detailedGenPageSignInButton.isTranslation
        ? t(detailData.detailedGenPageSignInButton.translationKey)
        : detailData.detailedGenPageSignInButton.defaultTranslation,
    },
    faq: {
      title: detailData.detailedGenPageFAQTitle.isTranslation
        ? t(detailData.detailedGenPageFAQTitle.translationKey)
        : detailData.detailedGenPageFAQTitle.defaultTranslation,
      accordian: accordianData(detailData.detailedGenPageFAQAccordion, t),
      buttonText: detailData.detailedGenPageFAQButton.isTranslation
        ? t(detailData.detailedGenPageFAQButton.translationKey)
        : detailData.detailedGenPageFAQButton.defaultTranslation,
    },
    doYouKnow: {
      title: detailData.detailedGenPageDidYouKnowTitle.isTranslation
        ? t(detailData.detailedGenPageDidYouKnowTitle.translationKey)
        : detailData.detailedGenPageDidYouKnowTitle.defaultTranslation,
      description: detailData.detailedGenPageDidYouKnowText.isTranslation
        ? t(detailData.detailedGenPageDidYouKnowText.translationKey)
        : detailData.detailedGenPageDidYouKnowText.description,
    },
    doYouKnowWhoItsFor: {
      title: detailData.detailedGenPageDidYouKnowWhoItsForCarouselTitle
        .isTranslation
        ? t(
            detailData.detailedGenPageDidYouKnowWhoItsForCarouselTitle
              .translationKey
          )
        : detailData.detailedGenPageDidYouKnowWhoItsForCarouselTitle
            .defaultTranslation,
      carouselItems: carouselData(
        detailData.detailedGenPageDidYouKnowCarousel,
        t
      ),
    },
  };
  return data;
};

const carouselData = (
  carouselData: { items: CarouselItemsCard[] },
  t: (key: string) => string
): CarouselItems[] => {
  let items = carouselData.items;
  return items.map(function (item: CarouselItemsCard) {
    let data: CarouselItems = {
      image: item.image.image,
    };
    if (item?.shortText) {
      data.shortDescription = item.shortText.isTranslation
        ? t(item.shortText.translationKey)
        : item?.shortText?.description;
    }
    if (item?.detailText) {
      data.longDescription = item.detailText.isTranslation
        ? t(item.detailText.translationKey)
        : item?.detailText?.description;
    }
    if (item?.text) {
      data.text = item.text.isTranslation
        ? t(item.text.translationKey)
        : item?.text?.description;
    }
    return data;
  });
};

const accordianData = (
  accordianData: FaqAccordian,
  t: (key: string) => string
): AccordianItems[] => {
  let items = accordianData.items;
  return items
    .slice()
    .sort(
      (itemA: FaqAccordianItems, itemB: FaqAccordianItems) =>
        itemA?.orderCount - itemB?.orderCount
    )
    .map(function (item: FaqAccordianItems, index: number) {
      return {
        contentId: index,
        orderCount: item?.orderCount || 0,
        title: item.title.isTranslation
          ? t(item.title.translationKey)
          : item.title.defaultTranslation,
        detailText: {
          isTranslation: item.detailText.isTranslation,
          translationKey: item.detailText.translationKey,
          description: item.detailText.defaultTranslation,
        },
      };
    });
};

const getOptionData = (
  option: OptionItems[],
  t: (key: string) => string
): OptionData[] => {
  return option.map(function (option: OptionItems) {
    let label = option.isTranslation
      ? t(option.translationKey)
      : option.defaultTranslation;

    let value = option?.apiName ? option?.apiName : option?.name;
    if (!value) {
      value = "";
    }

    return {
      label: label,
      value: value,
    };
  });
};

const getFaqDetailData = (
  faq: FaqItems[],
  t: (key: string) => string
): {
  contentId: number;
  orderCount: number;
  title: string;
  items: AccordianItems[];
}[] => {
  return faq.map(function (item: FaqItems) {
    return {
      contentId: item.contentId,
      orderCount: item.orderCount,
      title: item.isTranslation
        ? t(item.translationKey)
        : item.defaultTranslation,
      items: accordianData(item, t),
    };
  });
};

const getContactType = (value: string, t: (key: string) => string) => {
  const findValue = contactTypes.userContactType.filter(
    (data: ContactType) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getPaymentBankType = (value: string, t: (key: string) => string) => {
  const findValue = bankTypes.bankType.filter((data) => data.apiName === value);
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getSocialMediaType = (value: string, t: (key: string) => string) => {
  const findValue = socialMediaTypes.socialMediaType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getVerificationType = (value: string, t: (key: string) => string) => {
  const findValue = verficationTypes.userContactVerificationType.filter(
    (data: VerificationType) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getMcProfileVerificationType = (
  value: string,
  t: (key: string) => string
) => {
  const findValue = mcProfileType.userMCProfileVerificationType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getUserMediaChannelType = (value: string, t: (key: string) => string) => {
  const findValue = userMediaChannelType.userMCType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getMediaChannelType = (value: string, t: (key: string) => string) => {
  const findValue = mediaChannelType.mcType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getInterestType = (value: string, t: (key: string) => string) => {
  const findValue = interestTypeJson.interestType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getLanguageType = (value: string, t: (key: string) => string) => {
  const findValue = languageTypeJson.languageType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getMcAboutMeAndFollowsTitle = (
  value: string,
  t: (key: string) => string
) => {
  const findValue = selfAnalyticsJson.selfAnalyticsType.filter(
    (data) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getCountryNames = (
  value: Option[],
  t: (key: string) => string
): TransformedOption[] => {
  return value.map((option: Option) => {
    let label = option.isTranslation
      ? t(option.translationKey)
      : option.defaultTranslation;

    let value = option?.name ?? "";
    return {
      label: label,
      value: value,
    };
  });
};

function getCurrencyNames(jsonData: string): string[] {
  const currencyNames: string[] = [];
  const data: { [key: string]: CurrencyInfo } = JSON.parse(jsonData);
  for (const currencyCode in data) {
    if (data.hasOwnProperty(currencyCode)) {
      currencyNames.push(
        `${data[currencyCode].code} - ${data[currencyCode].name}`
      );
    }
  }
  return currencyNames;
}

const getUserContactVerificationType = (
  value: string,
  t: (key: string) => string
) => {
  const findValue = paymentType.paymentStatusType.filter(
    (data: PaymentStatusType) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getUserAdvertType = (value: string, t: (key: string) => string) => {
  const findValue = advertTypeJson.advertType.filter(
    (data: AdvertType) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getBreadcrumbs = (
  pathNames: string[],
  t: (key: string) => string,
  error: CustomError | null
) => {
  let breadcrumbs = [
    {
      title: t("home"),
      href: ROUTES["INDEX_ROUTE"],
    },
  ];
  if (!_.isEmpty(error)) {
    breadcrumbs.push({
      title: error.status === 404 ? error.status.toString() : "500",
      href: ROUTES["INDEX_ROUTE"],
    });
    return breadcrumbs;
  }
  if (!_.isEmpty(pathNames)) {
    pathNames.forEach((path) => {
      if (path !== "/") {
        let routes: RouteWithChildren[] = [routers[0] as RouteWithChildren];
        let route = findRouteTitle(routes[0], path);

        if (_.isEmpty(route)) {
          routes = [routers[2] as RouteWithChildren];
          route = findRouteTitle(routes[0], path);
        }

        breadcrumbs.push({
          title: t(route),
          href: path,
        });
      }
    });
  }
  return breadcrumbs;
};

function findRouteTitle(route: RouteWithChildren, path: string): string {
  if (RegExp(/^\/my-advertmingo\/table\/\d+$/).exec(path)) {
    path = "/my-advertmingo/table/:id";
  }

  return !_.isEmpty(route?.children) && !_.isUndefined(route?.children)
    ? route.children.find((child: RouteItem) => child.path === path)?.title ??
        route.title ??
        ""
    : "";
}

const removeLocalStorage = () => {
  localStorage.removeItem("breadcrumbs");
  localStorage.removeItem("openMenus");
  localStorage.clear();
};

const windowScroll = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  const ensureAndAdjustScrollToTop = () => {
    if (window.scrollY !== 0) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  setTimeout(ensureAndAdjustScrollToTop, 200);
};

const setupMediaQueryListener = (
  query: string,
  callback: (matches: boolean) => void
) => {
  const mediaQuery = window.matchMedia(query);

  const handleWidthChange = (e: MediaQueryListEvent) => {
    callback(e.matches);
  };

  handleWidthChange({ matches: mediaQuery.matches } as MediaQueryListEvent);

  mediaQuery.addEventListener("change", handleWidthChange);

  return () => {
    mediaQuery.removeEventListener("change", handleWidthChange);
  };
};

const getCategoryStyles = (category: string) => {
  switch (category) {
    case "high":
      return {
        itemsBgColor: "rgba(254, 235, 255, 0.70)",
        borderColor: "rgba(254, 59, 149, 0.50)",
        bgColor: "#FE3B95",
      };
    case "medium":
      return {
        itemsBgColor: "rgba(252, 97, 17, 0.20)",
        borderColor: "rgba(252, 97, 17, 0.50)",
        bgColor: "#FC6111",
      };
    case "low":
      return {
        itemsBgColor: "rgba(31, 197, 130, 0.20)",
        borderColor: "rgba(31, 197, 130, 0.50)",
        bgColor: "#1FC582",
      };
    default:
      return {
        itemsBgColor: "",
        borderColor: "",
        bgColor: "",
      };
  }
};
const sortWorkingAreas = (a: [string, InfoBox[]], b: [string, InfoBox[]]) => {
  return a[1]?.length - b[1]?.length;
};

const sortInfoBoxes = (
  a: { orderCount: number },
  b: { orderCount: number }
): number => {
  return a.orderCount - b.orderCount;
};

const hasAnyKey = (obj: Record<string, any>, keys: string[]): boolean => {
  return keys.some((key: string) => key in obj);
};

const hasStep1Errors = (errors: Record<string, any>): boolean => {
  return errors && hasAnyKey(errors, step1Keys);
};

const hasStep2Errors = (errors: Record<string, any>): boolean => {
  return errors && hasAnyKey(errors, step2Keys);
};

const hasStep3Errors = (errors: Record<string, any>): boolean => {
  return errors && hasAnyKey(errors, step3Keys);
};

const checkIsPrimaryKeyValue = (
  data: DataItem[],
  setIsPrimaryKey: Dispatch<SetStateAction<DataItem | undefined>>
): DataItem | undefined => {
  const checkIsPrimaryKey = data.find(
    (item: DataItem) => item.isPrimary === true
  );
  setIsPrimaryKey(checkIsPrimaryKey);
  return checkIsPrimaryKey;
};

const transformData = (data: PaymentDetails[]): TransformedPayment[] => {
  const transformedData: TransformedPayment[] = [];
  for (const payment of data) {
    const paymentDetails: TransformedPayment = {};
    for (const key in payment) {
      paymentDetails[key] = { value: String(payment[key]) };
    }
    transformedData.push(paymentDetails);
  }

  return transformedData;
};

const updateColumns = (columns: any, filteredCurrentDetailsList: any) => {
  return columns.map((column: Column) => {
    if (column.field === "actions") {
      return {
        ...column,
        renderCell: (params: RenderCellParams) => {
          const rowData = filteredCurrentDetailsList[params.id];
          const isPublished = rowData?.status?.value === "Published";

          return column?.actionItems?.map((item: Props) => (
            <CardActions key={item.actionType}>
              {item.actionType === VIEW_ICON && (
                <VisibilityOutlined
                  className={item.actionType}
                  onClick={() => item.action({ ...params, rowData })}
                />
              )}
              {item.actionType === EDIT_ICON &&
                (isPublished ? (
                  <Tooltip
                    title={item.tooltipTitle}
                    placement={item?.tooltipPosition}
                  >
                    <ModeOutlined
                      className={`${item.actionType} ${
                        item.isTooltip ? "Mui-disabled" : ""
                      } `}
                      onClick={
                        !item.isTooltip
                          ? () => item.action({ ...params, rowData })
                          : undefined
                      }
                    />
                  </Tooltip>
                ) : (
                  <ModeOutlined
                    className={`${item.actionType} `}
                    onClick={() => item.action({ ...params, rowData })}
                  />
                ))}
              {item.actionType === DELETE_ICON && (
                <DeleteOutlined
                  className={item.actionType}
                  onClick={() => item.action({ ...params, rowData })}
                />
              )}
              {item.actionType === ADD_ICON && (
                <AddCircleOutlineOutlined
                  className={item.actionType}
                  onClick={() => item.action({ ...params, rowData })}
                />
              )}
            </CardActions>
          ));
        },
      };
    }

    return {
      ...column,
      valueGetter: (params: any) => {
        const rowData = filteredCurrentDetailsList[params.id];
        return rowData?.[column.field]?.value || "";
      },
    };
  });
};

const transformDataOfferList = (data: any[]): any[] => {
  const transformedData: any[] = [];

  for (const payment of data) {
    const paymentDetails: any = {};

    for (const key in payment) {
      if (key === "mediaChannels" && Array.isArray(payment[key])) {
        paymentDetails[key] = payment[key].map((channel: any) => {
          const transformedChannel: any = {};
          for (const channelKey in channel) {
            if (typeof channel[channelKey] === "boolean") {
              transformedChannel[channelKey] = { value: channel[channelKey] };
            } else if (
              typeof channel[channelKey] === "number" &&
              Number.isInteger(channel[channelKey])
            ) {
              transformedChannel[channelKey] = { value: channel[channelKey] };
            } else if (typeof channel[channelKey] === "number") {
              transformedChannel[channelKey] = { value: channel[channelKey] };
            } else {
              transformedChannel[channelKey] = {
                value: String(channel[channelKey]),
              };
            }
          }
          return transformedChannel;
        });
      } else if (key === "countries" && Array.isArray(payment[key])) {
        paymentDetails[key] = payment[key].map((country: any) => {
          return { value: String(country) };
        });
      } else {
        if (typeof payment[key] === "boolean") {
          paymentDetails[key] = { value: payment[key] };
        } else if (
          typeof payment[key] === "number" &&
          Number.isInteger(payment[key])
        ) {
          paymentDetails[key] = { value: payment[key] };
        } else if (typeof payment[key] === "number") {
          paymentDetails[key] = { value: payment[key] };
        } else {
          paymentDetails[key] = { value: String(payment[key]) };
        }
      }
    }

    transformedData.push(paymentDetails);
  }

  return transformedData;
};

const commonCurrencyShow = (
  currencyCode: keyof typeof currencyCodeJson,
  amount: number
): string => {
  const currencyData = (currencyCodeJson[currencyCode] as CurrencyData) ?? null;
  const amountString = amount?.toFixed(currencyData?.decimal_digits);
  return currencyData ? `${currencyData.symbol}${amountString}` : amountString;
};

const processObject = (obj: any) => {
  if (obj.hasOwnProperty("value")) {
    return obj["value"];
  }
  return getTableDataCleanObject(obj);
};

const processArray = (arr: any[]) => {
  return arr.map((item) => getTableDataCleanObject(item));
};

const getTableDataCleanObject = (obj: any) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (Array.isArray(obj[key])) {
          obj[key] = processArray(obj[key]);
        } else {
          obj[key] = processObject(obj[key]);
        }
      }
    }
  }
  return obj;
};

const getTitleByKey = (
  key: string,
  rowData: any,
  t: (key: string) => string
) => {
  if (rowData?.mcAnalyticsDataTypeApiName !== null) {
    return `${rowData.fromValue} - ${rowData.toValue}`;
  } else {
    switch (key) {
      case INTERESTS:
        return rowData.mcAnalyticsVariableApiName === "OTHER_INTERESTS_TYPE"
          ? rowData.customAnalyticsVariableName
          : getInterestType(rowData.mcAnalyticsVariableApiName, t);
      case LANGUAGES:
        return getLanguageType(rowData.mcAnalyticsVariableApiName, t);
      default:
        return rowData?.mcAnalyticsVariableApiName ?? "-";
    }
  }
};

const getValidationFunction = (
  formData: SocialMediaInterestForm,
  dynamicRouteKey: string,
  title: string,
  t: (key: string) => string,
  updateFieldObj: any,
  rangeValue: any[],
  minValue: number
): Partial<SocialMediaInterestFormErrors> => {
  const errors: SocialMediaInterestFormErrors = {};

  const validateField = (
    condition: boolean,
    field: keyof SocialMediaInterestFormErrors,
    errorMsg: string
  ) => {
    if (condition) errors[field] = t(errorMsg);
  };
  const getTranslatedKey = (suffix: string) => `${dynamicRouteKey}_${suffix}`;

  validateField(
    title === ABOUT_FOLLOWS_TAB_KEY &&
      updateFieldObj?.isFollowersPercentageField &&
      !formData.followersPercentage,
    "followersPercentage",
    "socialMediaFollowsPercentageFormFieldError"
  );

  validateField(
    updateFieldObj?.category.isCategory && !formData.mcAnalyticsCategoryApiName,
    "mcAnalyticsCategoryApiName",
    getTranslatedKey("CategoryFieldError")
  );

  validateField(
    updateFieldObj?.variable.isVariable && !formData.mcAnalyticsVariableApiName,
    "mcAnalyticsVariableApiName",
    getTranslatedKey("FormFieldError")
  );

  validateField(
    updateFieldObj?.range?.isRangeField && rangeValue[1] === minValue,
    "range",
    getTranslatedKey("FormFieldError")
  );

  return errors;
};

const getCommonTypeSelect = (fieldValues: any, interestFields: any[]) => {
  for (const field of interestFields) {
    if (fieldValues[field]) {
      return fieldValues[field];
    }
  }
  return "";
};

const getCustomOptionData = (
  option: any,
  t: (key: string) => string
): OptionData[] => {
  return option.map(function (option: any) {
    let label = option.isTranslation
      ? t(option.translationKey)
      : option.defaultTranslation;

    let value = option?.mcAnalyticsVariableApiName
      ? option?.mcAnalyticsVariableApiName
      : option?.mcAnalyticsCategoryApiName;
    if (!value) {
      value = "";
    }

    return {
      label: label,
      value: value,
    };
  });
};

const getSMCategoryFormFieldOptions = async (
  key: string,
  t: (key: string) => string
) => {
  const findObj = selfAnalyticsJson.selfAnalyticsType.find(
    (data) => data.apiName === key
  );

  if (findObj) {
    try {
      const module = await import(
        `data/cms/${findObj.categoryVariableDataCMSFileName}`
      );
      return getCustomOptionData(module.default?.categoryOptions, t);
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
  return [];
};

const getSMFormFieldOptions = async (
  key: string,
  categoryType: any,
  t: (key: string) => string
) => {
  const findObj = selfAnalyticsJson.selfAnalyticsType.find(
    (data: any) => data.apiName === key
  );

  if (!findObj) return;
  const module = await import(
    `data/cms/${findObj.categoryVariableDataCMSFileName}`
  );
  const variableOptions = module.default.variableOptions as VariableOption[];
  if (findObj.category.isCategory && findObj.variable.isVariable) {
    if (categoryType.value !== "") {
      const filterOptions = variableOptions?.filter(
        (data: VariableOption) =>
          data.mcAnalyticsCategoryApiName === categoryType.value
      );
      if (filterOptions && filterOptions.length > 0) {
        return getCustomOptionData(filterOptions, t);
      } else {
        return getCustomOptionData(variableOptions, t);
      }
    } else {
      return;
    }
  }
  if (findObj.variable.isVariable) {
    return getCustomOptionData(variableOptions, t);
  }
};

const getSMFormFieldInitialValue = async (
  dynamicRouteKey: string,
  fieldValue: string,
  t: (key: string) => string,
  fieldName: string
) => {
  const findObj = selfAnalyticsJson.selfAnalyticsType.find(
    (data) => data.apiName === dynamicRouteKey
  );

  if (!findObj) return;

  const module = await import(
    `data/cms/${findObj.categoryVariableDataCMSFileName}`
  );

  const options =
    fieldName === VARIABLE_KEY
      ? module.default.variableOptions
      : module.default.categoryOptions;

  const findFieldValueObj = options.find((option: any) =>
    fieldName === VARIABLE_KEY
      ? option.mcAnalyticsVariableApiName === fieldValue
      : option.mcAnalyticsCategoryApiName === fieldValue
  );

  if (!findFieldValueObj) {
    return { label: fieldValue, value: fieldValue };
  }

  const label = findFieldValueObj.isTranslation
    ? t(findFieldValueObj.translationKey)
    : findFieldValueObj.defaultTranslation;

  const value =
    fieldName === VARIABLE_KEY
      ? findFieldValueObj.mcAnalyticsVariableApiName
      : findFieldValueObj.mcAnalyticsCategoryApiName;

  return { label, value };
};

const customScroll = () => {
  const scrollElement = document.querySelector(".scrollBehavior");
  if (scrollElement) {
    const scrollHeightDiv = scrollElement.scrollHeight;
    scrollElement.scrollTo({
      top: -scrollHeightDiv,
      behavior: "smooth",
    });
  }
};
const getActiveMenu = () => {
  const findActiveMenu = selfAnalyticsJson.selfAnalyticsType.find(
    (data: any) => data.orderCount === 1
  );
  return findActiveMenu?.apiName ? findActiveMenu.apiName : "";
};

const aboutOrderListDataByGetOrdeRows = (
  listData: ListData,
  getOrdeRows: AnalyticsType[]
): ListData => {
  const orderMapping = Object.fromEntries(
    getOrdeRows.map((item) => [item.apiName, item.orderCount])
  );
  return Object.fromEntries(
    Object.entries(listData).sort(([a], [b]) => {
      return orderMapping[a] - orderMapping[b];
    })
  );
};

const removeActiveTabCookie = () => {
  deleteCookie([
    "activeMCTab",
    "activeSMTab",
    "activePaymentTab",
    "activeAnalyticsTab",
    "activeAboutMenuItem",
  ]);
};

const getfilteredMenuData = (formTab: string) => {
  return selfAnalyticsJson.selfAnalyticsType.filter((data) => {
    if (formTab === ABOUT_ME_TAB_KEY) {
      return data.isAboutMe;
    } else if (formTab === ABOUT_ORGANIZATION_TAB_KEY) {
      return data.isAboutMyOrganization;
    } else if (formTab === ABOUT_AUDIENCE_TAB_KEY) {
      return data.isAboutAudience;
    } else {
      return data.isAboutFollows;
    }
  });
};

const handleBack = () => {
  window.history.back();
};

const transformAdverterprojectData = (data: any[]): AdvertiserProject[] => {
  const transformedData: AdvertiserProject[] = [];

  for (const project of data) {
    const transformedProject: AdvertiserProject = {
      id: { value: project.id },
      name: { value: project.name },
      description: { value: project.description },
      advertiserProjectClients: project.advertiserProjectClients.map(
        (client: any) => ({
          advertiserProjectId: { value: client.advertiserProjectId },
          advertiserClientId: { value: client.advertiserClientId },
          companyName: { value: client.companyName },
          name: { value: client.name },
          description: { value: client.description },
        })
      ),
    };

    transformedData.push(transformedProject);
  }

  return transformedData;
};

const getTabIndexValue = (getLocalData: string) => {
  if (
    getLocalData === ADVERTISER_PROJECT_TAB_KEY ||
    getLocalData === ADVERTISER_CLIENT_TAB_KEY
  ) {
    return 0;
  } else if (
    getLocalData === ADVERTISER_CHANNEL_TAB_KEY ||
    getLocalData === ADVERTISER_CLIENT_CONTACT_TAB_KEY
  ) {
    return 1;
  } else if (
    getLocalData === ADVERTISER_AUDIENCE_TAB_KEY ||
    getLocalData === ADVERTISER_CLIENT_PROJECT_TAB_KEY
  ) {
    return 2;
  }
};

const transformAdverterClientprojectData = (
  data: any[]
): AdvertiserClients[] => {
  const transformedData: AdvertiserClients[] = [];

  for (const client of data) {
    const transformedclient: AdvertiserClients = {
      id: { value: client.id },
      companyName: { value: client.companyName },
      name: { value: client.name },
      description: { value: client.description },
      advertiserClientProjects: client?.advertiserClientProjects?.map(
        (project: any) => ({
          id: { value: project.id },
          name: { value: project.name },
          description: { value: project.description },
        })
      ),
    };

    transformedData.push(transformedclient);
  }

  return transformedData;
};

const transformAdverterCustomOfferFilterData = (
  data: any[]
): TransformedClient[] => {
  const transformedData: TransformedClient[] = [];

  for (const customFilter of data) {
    const typesArray: TransformedType[] = (customFilter.types || []).map(
      (typeObj: any) => {
        const typeKey = typeObj.typeApiName;

        // Ensure typeObj[typeKey] is an array before mapping
        const filters = Array.isArray(typeObj[typeKey]) ? typeObj[typeKey] : [];

        return {
          typeApiName: typeKey,
          [typeKey]: filters.map((filter: Filter) => ({
            id: { value: filter.id },
            typeApiName: { value: filter.typeApiName },
            isCustomType: { value: filter.isCustomType },
            customType: { value: filter.customType },
            isCustomCategory: { value: filter.isCustomCategory },
            customCategoryName: { value: filter.customCategoryName },
            isCustomVariable: { value: filter.isCustomVariable },
            customVariableName: { value: filter.customVariableName },
            mcCategoryApiName: { value: filter.mcCategoryApiName },
            mcVariableApiName: { value: filter.mcVariableApiName },
            mcDataTypeApiName: { value: filter.mcDataTypeApiName },
            fromValue: { value: filter.fromValue },
            toValue: { value: filter.toValue },
            description: { value: filter.description },
          })),
        };
      }
    );

    const transformedClient: TransformedClient = {
      id: { value: customFilter.id },
      favouriteCustomFilterName: {
        value: customFilter.favouriteCustomFilterName,
      },
      favouriteCustomFilterDescription: {
        value: customFilter.favouriteCustomFilterDescription,
      },
      types: typesArray,
    };

    transformedData.push(transformedClient);
  }

  return transformedData;
};

const transformAdverterCustomOfferFilterSelectData = (data: {
  [key: string]: any[];
}): any[] => {
  return Object.keys(data).map((key) => {
    const filtersArray = Array.isArray(data[key]) ? data[key] : [];
    return {
      typeApiName: { value: key },
      [key]: filtersArray.map((filter: Filter) => ({
        id: { value: filter.id },
        typeApiName: { value: filter.typeApiName },
        isCustomType: { value: filter.isCustomType },
        customType: { value: filter.customType },
        isCustomCategory: { value: filter.isCustomCategory },
        customCategoryName: { value: filter.customCategoryName },
        isCustomVariable: { value: filter.isCustomVariable },
        customVariableName: { value: filter.customVariableName },
        mcCategoryApiName: { value: filter.mcCategoryApiName },
        mcVariableApiName: { value: filter.mcVariableApiName },
        mcDataTypeApiName: { value: filter.mcDataTypeApiName },
        fromValue: { value: filter.fromValue },
        toValue: { value: filter.toValue },
        description: { value: filter.description },
      })),
    };
  });
};

const buildAnalyticsObject = (key: string, dataArray: any[]) => {
  return {
    [key]: dataArray.map((item) => ({
      id: item.id,
      amMCProfileId: item.amMCProfileId,
      mcProfileId: item.mcProfileId,
      UserMCProfileTypeApiName:
        item.typeApiName || item.UserMCProfileTypeApiName,
      isCustomCategory: item.isCustomCategory,
      customCategoryName: item.customCategoryName,
      isCustomVariable: item.isCustomVariable,
      customVariableName: item.customVariableName,
      mcCategoryApiName: item.mcCategoryApiName,
      mcVariableApiName: item.mcVariableApiName,
      followersPercentage: item.followersPercentage,
      mcAnalyticsDataTypeApiName: item.mcDataTypeApiName,
      fromValue: item.fromValue,
      toValue: item.toValue,
      description: item.description,
      interestCustomName: item.customVariableName,
    })),
  };
};

const transformAdverterCampaignData = (data: any[]): AdvertiserCampaign[] => {
  const transformedData: AdvertiserCampaign[] = [];

  for (const client of data) {
    const transformedclient: AdvertiserCampaign = {
      id: { value: client.id },
      name: { value: client.name },
      description: { value: client.description },
      campaignTopic: { value: client.campaignTopic },
      contentFormat: { value: client.contentFormat },
      campaignStatus: { value: client.campaignStatus },
      contentStatus: { value: client.contentStatus },
      advertiserProjectClients: client?.advertiserProjectClients?.map(
        (project: any) => ({
          name: { value: project.name },
          description: { value: project.description },
          companyName: { value: project.companyName },
          advertiserProjectId: { value: project.advertiserProjectId },
          advertiserClientId: { value: project.advertiserClientId },
        })
      ),
    };
    transformedData.push(transformedclient);
  }

  return transformedData;
};

const transformAdverterPublicationData = (
  data: PaymentDetails[]
): TransformedPayment[] => {
  const transformedData: TransformedPayment[] = [];

  for (const payment of data) {
    const paymentDetails: any = {};

    for (const key in payment) {
      if (key === "id") {
        paymentDetails[key] = { value: Number(payment[key]) };
      }
      // Handle "channels" array
      else if (key === "channels" && Array.isArray(payment[key])) {
        const channelNames = payment[key]
          .map((channel: { name: string }) => channel.name)
          .join(", ");
        paymentDetails[key] = { value: channelNames };
      }
      // Handle "amMediaChannelOffers" array
      else if (key === "amMediaChannelOffers" && Array.isArray(payment[key])) {
        paymentDetails[key] = payment[key].map((offer: any) => {
          const offerDetails: any = {
            id: { value: offer.id },
            orderCount: { value: offer.orderCount },
            name: { value: offer.name },
            description: { value: offer.description },
            totalAudience: { value: offer.totalAudience },
            totalAudienceAsString: { value: offer.totalAudienceAsString },
            totalAmount: { value: offer.totalAmount },
            currencyCode: { value: offer.currencyCode },
            countries: { value: offer.countries.join(", ") },
          };

          // Handle nested "mediaChannels" array inside each offer
          if (Array.isArray(offer.mediaChannels)) {
            offerDetails.mediaChannels = offer.mediaChannels.map(
              (mediaChannel: any) => ({
                id: { value: mediaChannel.id },
                orderCount: { value: mediaChannel.orderCount },
                mcName: { value: mediaChannel.mcName },
                mcDescription: { value: mediaChannel.mcDescription },
                mcTypeApiName: { value: mediaChannel.mcTypeApiName },
                audiencePercentage: { value: mediaChannel.audiencePercentage },
                totalAudience: { value: mediaChannel.totalAudience },
                totalAudienceAsString: {
                  value: mediaChannel.totalAudienceAsString,
                },
              })
            );
          }

          return offerDetails;
        });
      }
      // Handle "userMediaChannels" array
      else if (key === "userMediaChannels" && Array.isArray(payment[key])) {
        paymentDetails[key] = payment[key].map((userMediaChannel: any) => ({
          id: { value: userMediaChannel.id },
          mcName: { value: userMediaChannel.mcName },
          userMCProfileName: { value: userMediaChannel.userMCProfileName },
          userMCProfileDescription: {
            value: userMediaChannel.userMCProfileDescription,
          },
          mcVerificationStatusApiName: {
            value: userMediaChannel.mcVerificationStatusApiName,
          },
          userMCTypeApiName: { value: userMediaChannel.userMCTypeApiName },
        }));
      }
      // Handle "campaign" object
      else if (
        key === "campaign" &&
        typeof payment[key] === "object" &&
        payment[key] !== null
      ) {
        const campaign = payment[key];
        paymentDetails[key] = {
          id: { value: campaign.id },
          name: { value: campaign.name },
          description: { value: campaign.description },
          campaignTopic: { value: campaign.campaignTopic },
          contentFormat: { value: campaign.contentFormat || "" }, // Handle empty fields
          campaignStatus: { value: campaign.campaignStatus || "" },
          contentStatus: { value: campaign.contentStatus || "" },
          contentId: { value: campaign.contentId },
        };
      }
      // Handle other non-array fields
      else {
        paymentDetails[key] = { value: String(payment[key]) };
      }
    }

    transformedData.push(paymentDetails);
  }

  return transformedData;
};

const getAdvFormatTypeName = (value: string, t: (key: string) => string) => {
  const findValue = contentFormatJson.items.filter(
    (data: AdvertType) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getAdvContentCampaignStatusType = (
  value: string,
  t: (key: string) => string
) => {
  const findValue = campaignTypeJson.contentCampaignedStatusType.filter(
    (data: AdvertType) => data.apiName === value
  );
  return findValue[0]?.isTranslation
    ? t(findValue[0]?.translationKey)
    : findValue[0]?.defaultTranslation;
};

const getAdvContentFormatList = (value: string) => {
  const findValue = contentFormatJson.items.filter(
    (data: AdvertType) => data.apiName === value
  );
  return findValue[0]?.formatType;
};

const validateFields = (
  values: any,
  topicSelect: any,
  formatSelect: any,
  description: string,
  fileState: any,
  editorState: string,
  t: (key: string) => string
) => {
  const errors: any = {};
  // General required field validations
  if (!values.name) {
    errors.name = t("nameErrorMessage");
  }
  if (!description) {
    errors.description = t("descriptionErrorMessage");
  }
  if (!topicSelect.value) {
    errors.topicApiName = t("topicErrorMessage");
  }
  if (!values.formatApiName) {
    errors.formatApiName = t("formatErrorMessage");
  }

  if (CHECK_FILE_UPLOAD.includes(formatSelect)) {
    if (fileState?.name === "") {
      errors.fileUpload = t("fileUploadErrorMessage");
    }
  }

  if (CHECK_TEXT_EDITOR.includes(formatSelect)) {
    if (!editorState) {
      errors.textEditor = t("textContentErrorMessage");
    }
  }

  if (CHECK_WEB_LINK.includes(formatSelect)) {
    if (!values.weblink) {
      errors.weblink = t("webLinkErrorMessage");
    } else if (!isValidURL(values.weblink)) {
      errors.weblink = t("invalidErrorMessage");
    }
  }

  return errors;
};

const isValidURL = (string: any) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const getAdvPublicationFilterOptions = async (
  key: string,
  t: (key: string) => string
) => {
  if (key) {
    try {
      const module = await import(`data/cms/${key}`);
      let getFilteredData = {
        categoryOptions: module.default?.categoryOptions,
        variableOptions: module.default?.variableOptions,
      };
      return getFilteredData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  }
  return [];
};

const filterTableData = (options: any, finalFilters: any) => {
  if (Object.keys(finalFilters).length === 0) return options;

  return options.filter((item: any) =>
    Object.keys(finalFilters).every((key) =>
      finalFilters[key].includes(item[key]?.value)
    )
  );
};

export {
  convertDetailJsonToArray,
  carouselData,
  accordianData,
  getFaqDetailData,
  getBreadcrumbs,
  removeLocalStorage,
  getOptionData,
  windowScroll,
  setupMediaQueryListener,
  getCategoryStyles,
  sortWorkingAreas,
  sortInfoBoxes,
  getContactType,
  getSocialMediaType,
  getVerificationType,
  getUserContactVerificationType,
  getCountryNames,
  getCurrencyNames,
  getPaymentBankType,
  hasAnyKey,
  hasStep1Errors,
  hasStep2Errors,
  hasStep3Errors,
  checkIsPrimaryKeyValue,
  transformData,
  updateColumns,
  commonCurrencyShow,
  getUserAdvertType,
  getMcProfileVerificationType,
  getMediaChannelType,
  getUserMediaChannelType,
  getTableDataCleanObject,
  getInterestType,
  getLanguageType,
  getTitleByKey,
  getValidationFunction,
  getCommonTypeSelect,
  getMcAboutMeAndFollowsTitle,
  getSMFormFieldOptions,
  customScroll,
  getCustomOptionData,
  getSMCategoryFormFieldOptions,
  getActiveMenu,
  aboutOrderListDataByGetOrdeRows,
  removeActiveTabCookie,
  getfilteredMenuData,
  getSMFormFieldInitialValue,
  handleBack,
  transformAdverterprojectData,
  getTabIndexValue,
  transformAdverterClientprojectData,
  transformAdverterCustomOfferFilterData,
  transformAdverterCustomOfferFilterSelectData,
  buildAnalyticsObject,
  getAdvFormatTypeName,
  getAdvContentCampaignStatusType,
  validateFields,
  getAdvContentFormatList,
  transformAdverterCampaignData,
  transformAdverterPublicationData,
  transformDataOfferList,
  getAdvPublicationFilterOptions,
  filterTableData,
};
