import React from "react";
import { List } from "@mui/material";
import { AMNotificationTabs, AMSectionLoader } from "components/common";
import { useTranslation } from "react-i18next";
import { Notifications } from "utils/CommonTypes";

interface NotificationProps {
  notifications: Notifications;
  isLoading: boolean;
}

const AMNotification = ({
  notifications,
  isLoading,
}: Readonly<NotificationProps>) => {
  const { t } = useTranslation("gen-error-message");

  return (
    <List className="secloader-wrp">
      {notifications && !isLoading && (
        <AMNotificationTabs items={notifications} nofound={t("noNotifyTxt")} />
      )}
      {isLoading && <AMSectionLoader />}
    </List>
  );
};

export default AMNotification;
