import React, { ReactNode } from "react";
import {
  CardActions,
  Button,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Send } from "@mui/icons-material";
import { uiConfig } from "designsystem";
import { useTheme } from "@mui/material/styles";
import * as colors from "designsystem/Palette";

interface ButtonProps extends Omit<MuiButtonProps, "color" | "onClick"> {
  value: string;
  center?: boolean;
  filColor?: string;
  variant?: "text" | "outlined" | "contained";
  minWidth?: boolean;
  isLoading?: boolean;
  isSmall?: boolean;
  isSmHidden?: boolean;
  reducePadding?: boolean;
  margintop?: string | number;
  buttonClick?: () => void;
  isFilter?: boolean;
  icon?: ReactNode;
}

const AMButton = ({
  value,
  center,
  minWidth,
  margintop,
  filColor,
  buttonClick,
  isLoading,
  variant,
  isSmall,
  reducePadding,
  isSmHidden,
  isFilter,
  icon,
  ...props
}: Readonly<ButtonProps>) => {
  const ButtonClickFunction = () => {
    validationMessageShow();
    buttonClick?.();
  };
  const theme = useTheme();

  const validationMessageShow = () => {
    setTimeout(() => {
      const classname = "input-errors";
      const yOffset = -200;
      const element = document.getElementsByClassName(classname);
      if (element.length > 0) {
        const y =
          element[0].getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 1);
  };

  let bgcolor;
  if (filColor === "green") {
    bgcolor = "secondary.main";
  } else if (filColor === "red") {
    bgcolor = "error.dark";
  } else {
    bgcolor = "primary.dark";
  }
  const commonStyle = {
    bgcolor: isFilter
      ? `${colors.menuBgColor} !important`
      : variant === "outlined"
      ? "transparent"
      : bgcolor,
    color: isFilter
      ? `${colors.secondaryLightColor} !important`
      : variant === "outlined"
      ? bgcolor
      : `${colors.whiteColor} !important`,
    border: "2px solid",
    borderColor: isFilter
      ? `${colors.secondaryLightColor} !important`
      : bgcolor,
    padding: reducePadding
      ? uiConfig.AMBtnReducePadding
      : uiConfig.AMBtnPadding,
    "&.iconWrp ": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    "&:hover": {
      bgcolor: variant === "outlined" ? "transparent" : bgcolor,
      border: "2px solid",
      borderColor: bgcolor,
    },
    typography: "button",

    [theme.breakpoints.down("sm")]: {
      "&.hidden": {
        display: "none",
      },
    },
  };

  return (
    <CardActions
      sx={{
        padding: uiConfig.AM0,
        marginTop: margintop ?? uiConfig.AM16,
        justifyContent: center ? "center" : "flex-start",
      }}
    >
      {isLoading ? (
        <LoadingButton
          loading
          loadingPosition="end"
          className="common-button"
          endIcon={<Send />}
          variant="contained"
          sx={{ ...commonStyle }}
          aria-label={value}
          aria-labelledby="button"
          onClick={() => {
            ButtonClickFunction();
          }}
          {...props}
        >
          {value}
        </LoadingButton>
      ) : (
        <Button
          disableFocusRipple
          disableTouchRipple
          disableRipple
          size="small"
          aria-label={value}
          className={`${isSmall ? "small-btn " : ""} ${
            icon && "iconWrp"
          } common-button ${isSmHidden ? "hidden " : ""}`}
          variant={variant}
          aria-labelledby="button"
          sx={commonStyle}
          onClick={() => {
            ButtonClickFunction();
          }}
          {...props}
        >
          {icon}
          {value}
        </Button>
      )}
    </CardActions>
  );
};
export default AMButton;
