import {
  Card,
  CardActions,
  FormControl,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TablePagination,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AMLabel, AMNotFound, AMSectionLoader } from "components/common";
import { useTranslation } from "react-i18next";
import { TABLE_DEFAULT_ROWS_PER_PAGE } from "utils/CommonText";

interface AMTableProps {
  tableData: any;
  columns: any;
  rows: any[];
  isloading?: boolean;
  removeHeader?: boolean;
  isCheckBoxSelectionEnabled?: boolean;
  isRadioSelectionEnabled?: boolean;
  fromFormProjectRoute?: boolean;
  fromFormClientRoute?: boolean;
  handleSelectedFilterFav?: (rowData: any) => void;
  checkedData?: string;
  checkBoxselectedData?: (rowData: any) => void;
  customOfferList?: any;
  selectTypeKey?: string;
  isFromCustomFilterOffer?: boolean;
  onPageChange?: (rowData: any) => void;
  onRowsPerPageChange?: (rowData: any) => void;
  isFromCampaignform?: boolean;
  isFromPublicationform?: boolean;
  isFromAddPublication?: boolean;
  selectedRowData?: any[];
  isFromAdvOffers?: boolean;
  isFromAdvYourMc?: boolean;
}

const AMTable = ({
  isloading,
  tableData,
  columns,
  rows,
  removeHeader,
  isCheckBoxSelectionEnabled = false,
  isRadioSelectionEnabled = false,
  fromFormProjectRoute,
  fromFormClientRoute,
  checkedData,
  handleSelectedFilterFav,
  checkBoxselectedData,
  customOfferList,
  selectTypeKey,
  isFromCustomFilterOffer,
  onPageChange,
  onRowsPerPageChange,
  isFromCampaignform,
  isFromPublicationform,
  isFromAddPublication,
  selectedRowData,
  isFromAdvOffers,
  isFromAdvYourMc,
}: AMTableProps) => {
  const { t } = useTranslation("table");
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [selectedRow, setSelectedRow] = useState<string | null>(
    checkedData ?? null
  );
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    setDataLoaded((prevDataLoaded) => prevDataLoaded + 1);
  }, [rows]);

  useEffect(() => {
    if (onPageChange) {
      onPageChange(page + 1);
    }
  }, [page, onPageChange]);

  useEffect(() => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(rowsPerPage);
    }
  }, [rowsPerPage, onRowsPerPageChange]);

  useEffect(() => {
    if (!selectTypeKey) return;

    const preselectedRows = rows.reduce((selected, row, index) => {
      const matchingData = customOfferList.find(
        (data: any) =>
          data.typeApiName === selectTypeKey &&
          data[selectTypeKey]?.some(
            (item: any) =>
              item.mcVariableApiName.value === row.mcVariableApiName.value
          )
      );
      if (matchingData) {
        selected.push(index);
      }
      return selected;
    }, [] as number[]);

    if (preselectedRows.length > 0) {
      setSelectedRows(preselectedRows);
    }
  }, [rows, customOfferList, selectTypeKey]);

  useEffect(() => {
    if (isFromAddPublication && selectedRowData && selectedRowData.length > 0) {
      const selectedIndexes = rows.reduce((acc, row, index) => {
        const isMatch = selectedRowData.some(
          (selected) => selected?.name?.value === row.name?.value
        );
        if (isMatch) {
          acc.push(index);
        }
        return acc;
      }, []);

      setSelectedRows(selectedIndexes);
    }
  }, [isFromAddPublication, rows, selectedRowData]);

  useEffect(() => {
    if (isFromAdvOffers && selectedRowData && selectedRowData.length > 0) {
      const selectedIndexes = rows.reduce((acc, row, index) => {
        const isMatch = selectedRowData.some(
          (selected) =>
            selected?.name?.value || selected?.name === row.name?.value
        );
        if (isMatch) {
          acc.push(index);
        }
        return acc;
      }, []);

      setSelectedRows(selectedIndexes);
    }
  }, [isFromAdvOffers, rows, selectedRowData]);

  useEffect(() => {
    if (isFromAdvYourMc && selectedRowData && selectedRowData.length > 0) {
      const selectedIndexes = rows.reduce((acc, row, index) => {
        const isMatch = selectedRowData.some(
          (selected) =>
            selected?.name?.value || selected?.name === row.name?.value
        );
        if (isMatch) {
          acc.push(index);
        }
        return acc;
      }, []);

      setSelectedRows(selectedIndexes);
    }
  }, [isFromAdvYourMc, rows, selectedRowData]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as number;
    setRowsPerPage(value);
    setPage(0);
  };
  const handleChangeRowsPerPages = (event: SelectChangeEvent<number>) => {
    const value = Number(event.target.value);
    setRowsPerPage(value);
    setPage(0);
  };

  const handleRowSelect = (rowId: string, rowData: any) => {
    setSelectedRow(rowId);
    if (handleSelectedFilterFav) {
      handleSelectedFilterFav(rowData?.row);
    }
  };

  const handleSelectionChange = (selectionModel: any) => {
    const selectedRowIds = selectionModel.map((id: number) => id);
    const selectedRowData = selectedRowIds.map((id: number) =>
      rows.find((row) => Number(row.id?.value) || row.id?.value === id + 1)
    );
    if (checkBoxselectedData) {
      checkBoxselectedData(selectedRowData);
    }
    setSelectedRows(selectedRowIds);
  };

  const getAMNotFoundValue = () => {
    if (
      !fromFormProjectRoute &&
      !fromFormClientRoute &&
      !isFromCustomFilterOffer &&
      !isloading &&
      rows.length === 0 &&
      dataLoaded > 1
    ) {
      return t("noDataFound");
    }
    if (fromFormProjectRoute && rows.length === 0) {
      return t("selectClientAlert");
    }
    if (fromFormClientRoute && rows.length === 0) {
      return t("selectProjectAlert");
    }
    if (isFromCustomFilterOffer && rows.length === 0) {
      return t("searchByFilterNoDataFound");
    }
    if (isFromCampaignform && rows.length === 0) {
      return t("selectCampaignContent");
    }
    if (isFromPublicationform && rows.length === 0) {
      return t("selectPublicationContent");
    }
    return null;
  };

  const notFoundValue = getAMNotFoundValue();

  const radioColumn = {
    field: "radio",
    headerName: "",
    width: 20,
    sortable: false,
    renderCell: (params: any) => (
      <Radio
        disableFocusRipple
        disableTouchRipple
        disableRipple
        checked={selectedRow === params.id}
        onChange={() => handleRowSelect(params.id, params)}
        value={params.id}
        name="row-radio-button"
      />
    ),
  };
  const updatedColumns = isRadioSelectionEnabled
    ? [radioColumn, ...columns]
    : columns;

  return (
    <section>
      <Card
        className="infocardwrp"
        sx={{
          height: "100%",
          maxHeight: "800px",
          width: "100%",
          overflowX: "auto",
          position: "relative",
          "& .actions": { color: "text.secondary" },
          "& .textPrimary": { color: "text.primary" },
        }}
      >
        {isloading && <AMSectionLoader />}

        {rows.length > 0 && (
          <section
            style={{
              width: "100%",
            }}
          >
            <DataGrid
              className={removeHeader ? "removeheader" : ""}
              rows={rows
                .map((row, index) => ({ ...row, id: index }))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              columns={updatedColumns}
              pageSizeOptions={[]}
              hideFooterPagination={true}
              rowHeight={64}
              disableRowSelectionOnClick
              checkboxSelection={isCheckBoxSelectionEnabled}
              autoHeight
              onRowSelectionModelChange={handleSelectionChange}
              rowSelectionModel={selectedRows}
            />
            {rows.length > TABLE_DEFAULT_ROWS_PER_PAGE &&
            (tableData?.isRowPerPageEnabled || tableData?.isPagination) ? (
              <CardActions className="paginator-perpage">
                {tableData?.isRowPerPageEnabled && (
                  <FormControl variant="standard">
                    <AMLabel label={t("rowPerPage")} />
                    <Select
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPages}
                      variant="standard"
                      sx={{
                        position: "absolute",
                        bottom: theme.spacing(2),
                        right: theme.spacing(2),
                      }}
                      disabled={!tableData?.isRowPerPageEnabled}
                    >
                      {tableData?.rowPerPageItems.map((option: number) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {tableData.isPagination && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tableData.totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </CardActions>
            ) : (
              ""
            )}
          </section>
        )}
        {notFoundValue && <AMNotFound value={notFoundValue} />}
      </Card>
    </section>
  );
};
export default AMTable;
