import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AMLabel from "./AM_Label";
import { InputAdornment } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

const filter = createFilterOptions<OptionType>();

interface OptionType {
  label: string;
  value: string;
  inputValue?: string;
}

interface FreeSoloCreateOptionProps {
  label: string;
  required?: boolean;
  value: any;
  options: OptionType[];
  placeholder: string;
  onChange: (value: any | null) => void;
  isDisabled?: boolean;
}

const FreeSoloCreateOption = ({
  label,
  required,
  value,
  options,
  placeholder,
  onChange,
  isDisabled,
}: Readonly<FreeSoloCreateOptionProps>) => {
  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: string | OptionType | null
  ) => {
    if (typeof newValue === "string") {
      onChange(newValue);
    } else if (newValue && newValue.inputValue) {
      const newOption = {
        label: newValue.inputValue,
        value: newValue.inputValue,
      };
      onChange(newOption);
    } else {
      onChange(newValue);
    }
  };

  return (
    <div>
      <AMLabel label={label} required={required} />
      <Autocomplete
        value={value}
        onChange={handleChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          const isExisting = options.some(
            (option) => inputValue === option.label
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`,
              value: inputValue,
            });
          }

          // If no options available, push a custom message
          if (filtered.length === 0) {
            filtered.push({
              label: "No data available. Please Create",
              value: "no_data",
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{
              color: option.value === "no_data" ? "#999" : "inherit",
              pointerEvents: option.value === "no_data" ? "none" : "auto",
            }}
          >
            {option.label}
          </li>
        )}
        freeSolo
        noOptionsText="No locations"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={value.value ? "" : placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <ArrowDropDown />
                </InputAdornment>
              ),
            }}
            disabled={isDisabled}
          />
        )}
        disabled={isDisabled}
      />
    </div>
  );
};

export default FreeSoloCreateOption;
