import { useContextCommonDataModel } from 'context/CommonContext';
import { userProfileModelDefaultValues } from 'models/user/UserProfileModel';
import React, { useEffect } from 'react';
import { useNavigate, Navigate} from 'react-router-dom';
import { removeLocalStorage } from 'utils/CommonFunctions';
import ROUTES from 'utils/CommonRouter';
import { deleteCookie, getCookie } from 'utils/cookieService';

const GuestGuard = ({ element }: { element: React.ReactNode }) => {
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;
  const refreshToken = getCookie("rt");
  const { updateUserDataModel } = useContextCommonDataModel();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = () => {
      const userData = {
        userProfile: userProfileModelDefaultValues,
        authenticated: false,
        token: "",
        roles: [],
      };
      updateUserDataModel(userData);
      deleteCookie(["at", "rt"]);
      navigate(ROUTES["SIGN_IN"], {
        state: {
          message: "your session has been expired, please sign in again",
          enableToaster: true,
        },
      });
      removeLocalStorage();
    };
    if (user?.authenticated && !refreshToken) {
      logout();
    }
  }, [user, refreshToken, navigate, updateUserDataModel]);

  if (user?.authenticated && refreshToken) {
    return <Navigate to={ROUTES["MY_ADVERT_ROUTE"]} />;
  } else {
    return <section>{element}</section>;
  }
};

export default GuestGuard;