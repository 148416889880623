import React, { useEffect, useRef, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  Box,
  Collapse,
  List,
  IconButton,
  CardContent,
  useTheme,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuItems from "./MenuItem";
import { useTranslation } from "react-i18next";
import { MenuItemTypes, RenderSubMenuParams } from "utils/CommonTypes";
import ROUTES from "utils/CommonRouter";
import { MENU_PAGE } from "utils/CommonText";
import { removeActiveTabCookie } from "utils/CommonFunctions";

interface LeftMenuProps {
  isOpenMenu: boolean;
  customclass?: string;
}

const LeftMenu = ({ isOpenMenu, customclass }: LeftMenuProps) => {
  const { t } = useTranslation("menu-item");
  const theme = useTheme();

  const { pathname } = useLocation();
  const [openMenus, setOpenMenus] = useState<number[]>(() => {
    const isOpenMenusExit = localStorage.getItem("openMenus");
    return isOpenMenusExit && !customclass ? JSON.parse(isOpenMenusExit) : [];
  });
  const menuRef = useRef<HTMLDivElement>(null);
  const menuItemsRef = useRef<HTMLUListElement>(null);
  const menuItemDivRef = useRef<HTMLDivElement>(null);
  const [cumulativeLeft, setCumulativeLeft] = useState<number>(0);

  useEffect(() => {
    if (menuRef.current) {
      const selectedMenuItem = menuRef.current.querySelector(".active");
      if (selectedMenuItem) {
        setTimeout(() => {
          selectedMenuItem.scrollIntoView({
            block: "center",
            inline: "center",
          });
        }, 150);
      }
    }
  }, [pathname]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenMenus([]);
      }
    };

    const handleScroll = () => {
      setOpenMenus([]);
    };

    if (isOpenMenu && customclass) {
      document.addEventListener("mousedown", handleOutsideClick);
      document.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [customclass, isOpenMenu]);

  const handleMenuClick = (index: number, parentIndex: number | null) => {
    if (isOpenMenu && customclass) {
      removeActiveTabCookie();

      const currentIndex = openMenus.indexOf(index);
      const getParent = document
        .getElementsByClassName("parentmenu-" + index)[0]
        .getBoundingClientRect();
      if (getParent) {
        setCumulativeLeft(getParent.left);
      }

      const newOpenMenus: number[] = [];
      if (currentIndex === -1) {
        if (parentIndex === null) {
          newOpenMenus.push(index);
        } else {
          const parentMenuIndex = openMenus.indexOf(parentIndex);
          if (parentMenuIndex === -1) {
            newOpenMenus.push(parentIndex);
          }
          newOpenMenus.push(parentIndex, index);
        }
      } else {
        newOpenMenus.splice(currentIndex, 1);
      }

      setOpenMenus(newOpenMenus);
    }
  };

  const handleSubMenuItemClick = () => {
    setOpenMenus([]);
  };

  const getParentIndex = (index: number): number | null => {
    return Math.floor(index / 1000) || null;
  };

  const isSubItemActive = (subItems: MenuItemTypes[]): boolean => {
    return subItems.some((subItem: MenuItemTypes) => {
      if (subItem.submenu) {
        return isSubItemActive(subItem.submenu);
      }
      return pathname.startsWith(subItem.to);
    });
  };

  const isMenuItemActive = (item: MenuItemTypes, pathname: string): boolean => {
    return (
      (item.to === ROUTES["MY_ADVERT_ROUTE"] &&
        pathname === ROUTES["MY_ADVERT_ROUTE"]) ||
      (item.to !== ROUTES["MY_ADVERT_ROUTE"] && pathname.startsWith(item.to)) ||
      (item.submenu ? isSubItemActive(item.submenu) : false)
    );
  };

  const renderMenuItemContent = (
    item: MenuItemTypes,
    index: number,
    hasSubMenu: boolean | undefined,
    isActive: boolean,
    isOpen: boolean,
    handleMenuClick: (index: number, parentIndex: number | null) => void
  ) => (
    <MenuItem
      disableTouchRipple
      ref={menuItemDivRef}
      component={hasSubMenu ? "div" : Link}
      to={hasSubMenu ? undefined : item.to}
      className={isActive ? "active" : ""}
      id={`menu-${index}`}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
      onClick={() =>
        hasSubMenu
          ? handleMenuClick(index, getParentIndex(index))
          : handleSubMenuItemClick()
      }
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText>{t(item.name)}</ListItemText>
      {hasSubMenu && (
        <IconButton
          size="small"
          aria-label={isOpen ? "ExpandLess" : "ExpandMore"}
          aria-labelledby={isOpen ? "ExpandLess" : "ExpandMore"}
        >
          {isOpen ? (
            <ExpandLess width="27px" height="18px" />
          ) : (
            <ExpandMore width="27px" height="18px" />
          )}
        </IconButton>
      )}
    </MenuItem>
  );

  const renderSubMenu = ({
    item,
    index,
    isOpen,
    customclass,
    cumulativeLeft,
  }: RenderSubMenuParams) =>
    item.submenu && (
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{
          top: "auto",
          bottom: "auto",
          left: customclass ? `${cumulativeLeft - 20}px` : "auto",
        }}
      >
        <List component="div" disablePadding sx={{ paddingLeft: "20px" }}>
          {item.submenu.map((subItem, subIndex) =>
            renderMenuItem(subItem, index * 1000 + subIndex, index)
          )}
        </List>
      </Collapse>
    );

  const renderMenuItem = (
    item: MenuItemTypes,
    index: number,
    parentIndex: number | null = null
  ) => {
    const hasSubMenu = item.submenu && item.submenu.length > 0;
    const isOpen = openMenus.includes(index);
    const isActive = isMenuItemActive(item, pathname);

    return (
      <CardContent
        component="div"
        key={index}
        className={"parentmenu-" + index}
      >
        {renderMenuItemContent(
          item,
          index,
          hasSubMenu,
          isActive,
          isOpen,
          handleMenuClick
        )}

        {renderSubMenu({
          item,
          index,
          isOpen,
          customclass,
          cumulativeLeft,
        })}
      </CardContent>
    );
  };

  return (
    <Box bgcolor="transparent" zIndex={4} position="relative" ref={menuRef}>
      <MenuList
        sx={{
          height: "calc(100vh - 174px)",
          position: "static",
          [theme.breakpoints.down("md")]: {
            "&.mobilemenu": {
              height: "auto",
              overflow: "inherit",
              "& li.MuiButtonBase-root, & a.MuiButtonBase-root, & div.MuiButtonBase-root":
                {
                  paddingLeft: "16px",
                },
            },
          },
        }}
        className={`scroller ${customclass} ${
          isOpenMenu ? "usermenu" : "usermenu hoverusermenu"
        }`}
        ref={menuItemsRef}
      >
        <Typography variant="body2">{MENU_PAGE}</Typography>

        {MenuItems.map((item, index) => renderMenuItem(item, index))}
      </MenuList>
    </Box>
  );
};

export default LeftMenu;
