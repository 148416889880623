import { uiConfig, textStyles } from "designsystem";
import * as colors from "designsystem/Palette";

export const PaperStyles = {
  root: {
    ".MuiAutocomplete-listbox li.active, .MuiAutocomplete-listbox li:hover": {
      background: colors.secondaryDarkColor,
      color: colors.primaryDarkColor,
    },
    ".MuiAutocomplete-listbox li": {
      padding: "5px 10px",
      cursor: "pointer",
    },
    "&.infocardwrp": {
      boxShadow: uiConfig.AMBreadcrumbsShadow,
      borderRadius: uiConfig.AM15,
      position: textStyles.position.relative,
      "&>.titlewrp": {
        borderBottom: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.infoCardBr}`,
        padding: `${uiConfig.AM10} ${uiConfig.AM16}!important`,
        display: textStyles.display.flex,
        alignItems: textStyles.alignItems.center,
        justifyContent: textStyles.justifyContent.spaceBetween,
        ".title-statusbtn": {
          display: textStyles.display.flex,
          alignItems: textStyles.alignItems.center,
          gap: uiConfig.AM16,
          padding: uiConfig.AM0,
        },
        ".MuiSvgIcon-root": {
          cursor: textStyles.cursor.pointer,
        },
      },
      ".MuiOutlinedInput-root": {
        padding: uiConfig.AM0,
      },

      ".paginator-perpage": {
        justifyContent: textStyles.justifyContent.spaceBetween,
        borderTop: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.tableBorder}`,
        padding: uiConfig.AM12,
        background: colors.whiteColor,
        ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
          margin: uiConfig.AM0,
        },
        ".MuiToolbar-root": {
          padding: uiConfig.AM0,
          minHeight: uiConfig.AMInherit,
          ".MuiButtonBase-root": {
            padding: uiConfig.AM0,
            color: colors.primaryDarkColor,
            "&:hover": {
              backgroundColor: colors.secondaryContrastColor,
            },
            "&.Mui-disabled": {
              color: colors.disableClr,
            },
          },
        },
        ".MuiTablePagination-root": {
          display: textStyles.display.inlineBlock,
          width: uiConfig.AMAuto,
          overflow: textStyles.overflow.hidden,
          ".MuiTablePagination-spacer": {
            display: textStyles.display.none,
          },
        },
        ".MuiFormControl-root": {
          display: textStyles.display.inlineFlex,
          gap: uiConfig.AM10,
          border: uiConfig.AM0,
          alignItems: textStyles.alignItems.center,
          width: uiConfig.AM50per,
          flexDirection: uiConfig.AMInherit,
          margin: uiConfig.AM0,
        },
        ".MuiFormLabel-root": {
          position: uiConfig.AMInherit,
          textOverflow: uiConfig.AMInitial,
          whiteSpace: `${uiConfig.AMNowrap} !important`,
          overflow: uiConfig.AMInherit,
          fontSize: `${textStyles.fontSize.base} !important`,
          fontFamily: textStyles.fontFamily.buttonFont,
          color: colors.blackColor,
          fontWeight: textStyles.fontWeight.normal,
          flexShrink: uiConfig.AM0,
          transform: uiConfig.AMNone,
        },
        ".MuiInputBase-root": {
          width: uiConfig.AMAuto,
          border: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.infoCardBr} !important`,
          borderRadius: uiConfig.AM5,
          boxShadow: uiConfig.AMBreadcrumbsShadow,
          display: textStyles.display.inlineFlex,
          alignItems: textStyles.alignItems.center,
          position: uiConfig.AMInherit,
          left: uiConfig.AM0,
          right: uiConfig.AM0,
          bottom: uiConfig.AM0,
          margin: uiConfig.AM0,
          padding: uiConfig.AMDrwopDownBtn,
          ".MuiSelect-select": {
            minHeight: uiConfig.AMAuto,
            textAlign: textStyles.textAlign.left,
            textAlignLast: textStyles.textAlign.left,
            background: `${colors.transparent} !important`,
          },
        },
      },
    },

    ".MuiDataGrid-panelContent": {
      ".MuiDataGrid-filterForm": {
        gap: uiConfig.AM10,
      },
      ".MuiDataGrid-filterForm .MuiFormControl-root, .MuiDataGrid-filterForm .MuiInputBase-root":
        {
          width: uiConfig.AMAuto,
          border: `${uiConfig.AM0} !important`,
          padding: `${uiConfig.AM0} !important`,
          select: {
            fontWeight: textStyles.fontWeight.strong,
            textTransform: textStyles.transform.capitalize,
            background: `${colors.transparent} !important`,
          },
        },
    },
    "&.modalwrp": {
      position: textStyles.position.absolute,
      top: uiConfig.AM50per,
      left: uiConfig.AM50per,
      transform: "translate(-50%, -50%)",
      width: "83%",
      height: "85%",
      textAlign: textStyles.textAlign.center,
      padding: uiConfig.AM20,
      outline: `${uiConfig.AMNone} !important`,
      ".MuiCardMedia-root": {
        maxHeight: "85%",
        objectFit: "contain",
      },
    },
    "&.confirmmodalwrp": {
      position: textStyles.position.absolute,
      top: uiConfig.AM50per,
      left: uiConfig.AM50per,
      transform: "translate(-50%, -50%)",
      width: 440,
      textAlign: textStyles.textAlign.center,
      padding: uiConfig.AM20,
      outline: `${uiConfig.AMNone} !important`,
      ".MuiCardMedia-root": {
        margin: `${uiConfig.AMAuto} ${uiConfig.AMAuto} ${uiConfig.AM20} !important`,
        background: colors.confirmCardBg,
        border: `${uiConfig.AM8} ${textStyles.border.solid} ${colors.confirmCardBorder}`,
        padding: uiConfig.AM8,
        borderRadius: uiConfig.AM50,
      },
      ".MuiCardContent-root": {
        display: textStyles.display.flex,
        alignItems: textStyles.alignItems.center,
        gap: uiConfig.AM16,
        paddingTop: uiConfig.AM0,
        justifyContent: textStyles.justifyContent.center,
      },
    },
    "&.totallayersection": {
      background: `${uiConfig.AMNone} !important`,
      overflow: uiConfig.AMInherit,
      display: textStyles.display.flex,
      gap: uiConfig.AM24,
      flexDirection: textStyles.flexDirection.column,
      "&.media-abouttitle": {
        display: textStyles.display.flex,
        flexDirection: textStyles.flexDirection.column,
        "&>div": {
          flex: 1,
        },

        ".slick-slider .MuiTypography-h2": {
          width: uiConfig.AM117,
          textOverflow: uiConfig.AMEllipsis,
          overflow: textStyles.overflow.hidden,
          whiteSpace: uiConfig.AMNowrap,
          display: textStyles.display.inlineBlock,
        },
        ".bottomedit .MuiTypography-h2": {
          width: uiConfig.AM165,
        },
      },
      "&.remove-height-card": {
        ".secloader-wrp": {
          minHeight: uiConfig.AMAuto,
          margin: uiConfig.AM0,
        },
        ".slick-track": {
          display: `${textStyles.display.flex} !important`,
          margin: uiConfig.AM0,
        },
        ".slick-slide": {
          height: `${uiConfig.AMInherit} !important`,
          padding: `${uiConfig.AM5} ${uiConfig.AM0}`,
        },
        ".slick-slide>div, .slick-slide>div>div, .slick-slide>div>div>div, .slick-slide>div>div>div>div":
          {
            height: uiConfig.AM90po5per,
          },
      },

      ".eachlayerwrp": {
        padding: uiConfig.AM20,
        boxShadow: uiConfig.AMPaperShadow,
        borderRadius: uiConfig.AM15,
        ".MuiCardContent-root .common-button": {
          padding: uiConfig.AMBtnReducePadding,
        },
        ".contact-carousel": {
          marginLeft: uiConfig.AM0,
          width: uiConfig.AMfull,
          ".MuiGrid-item": {
            paddingLeft: uiConfig.AM0,
          },
        },
      },
      ".eachlayerwrp:nth-of-type(odd)": {
        background: colors.whiteColor,
      },
      ".eachlayerwrp:nth-of-type(even)": {
        background: colors.secondaryDarkColor,
      },
    },
  },
};
