import React, { useState } from "react";
import { Chip } from "@mui/material";
import * as colors from "designsystem/Palette";

interface AM_FilterChipProps {
  label: string;
  onDelete: () => void;
  onClick: () => void;
  defaultDeletable?: boolean;
}
const AM_FilterChip = ({
  label,
  onDelete,
  onClick,
  defaultDeletable = false,
}: Readonly<AM_FilterChipProps>) => {
  const [variant, setVariant] = useState<"outlined" | "filled">(
    defaultDeletable ? "filled" : "outlined"
  );
  const [isDeletable, setIsDeletable] = useState<boolean>(defaultDeletable);

  const handleClick = () => {
    onClick();
    setVariant(variant === "outlined" ? "filled" : "outlined");
    setIsDeletable(!isDeletable);
  };
  const handleDelete = () => {
    onDelete();
    setVariant(variant === "filled" ? "outlined" : "outlined");
    setIsDeletable(false);
  };

  return (
    <div>
      <Chip
        label={label}
        variant={variant}
        onClick={handleClick}
        {...(isDeletable ? { onDelete: handleDelete } : {})}
        sx={{
          "&.MuiChip-filled": {
            bgcolor: `${colors.secondaryDarkColor} !important`,
            border: `1px solid ${colors.primaryDarkColor} !important`,
            color: `${colors.primaryDarkColor} !important`,
            ".MuiSvgIcon-root": {
              color: `${colors.primaryDarkColor} !important`,
            },
          },
        }}
      />
    </div>
  );
};

export default AM_FilterChip;
