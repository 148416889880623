import React from "react";
import { Box, Card, CardMedia } from "@mui/material";

import { AMSectionCard } from "components/widgets";
import { AMButton, AMLink } from "components/common";
import { tuneitcon, tuneitconLogo } from "images/index";
import { useTheme } from "@mui/material/styles";
import { TUNEITCONLINK, TUNEITCONTEXT } from "utils/CommonText";
import { useTranslation } from "react-i18next";

const AMProject = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <section id="project">
      {/* PROJECTS DETAILS SECTION STARTS HERE */}

      <Box
        marginY="80px"
        sx={{
          [theme.breakpoints.down("sm")]: {
            marginY: "40px",
            gap: "20px",
          },
        }}
        className="productlist"
      >
        <AMSectionCard
          alignTxt="center"
          flexDirection="row-reverse"
          cardCenter={true}
          description={
            <Card
              elevation={0}
              sx={{
                marginX: "60px",
                [theme.breakpoints.down("sm")]: {
                  marginY: "20px",
                  gap: "20px",
                },
              }}
            >
              <CardMedia
                component="img"
                sx={{
                  height: "19px",
                  width: "104px",
                  margin: "0px auto 20px",
                }}
                image={tuneitconLogo}
                title="Tune Itcon"
              />

              <AMLink value={TUNEITCONTEXT} link={TUNEITCONLINK} />

              <AMButton center={true} value={t("learnMore")} />
            </Card>
          }
          cardImg={tuneitcon}
          imgWidth="300px"
        />
      </Box>
      {/* PROJECTS DETAILS SECTION ENDS HERE */}
    </section>
  );
};

export default AMProject;
