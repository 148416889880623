import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const BadgeStyles = {
  badge: {
    backgroundColor: colors.badgeBgColor,
    fontSize: uiConfig.AM8,
    top: uiConfig.AM11Point5,
    right: uiConfig.AM5Point5,
    height: uiConfig.AM12,
    minWidth: uiConfig.AM12,
    padding: uiConfig.AM0,
    "& .MuiBadge-badge": {
      height: uiConfig.AM10,
      minWidth: uiConfig.AM10,
      right: uiConfig.AM3Point5,
      fontSize: uiConfig.AM7,
    },
  },
  root: {
    "& .MuiSvgIcon-root": {
      fill: colors.blackColor,
      width: uiConfig.AM26,
      height: uiConfig.AM26,
    },
  },
};
