import React, { ReactNode } from "react";
import { Stepper, Step, StepLabel, Box, CardContent } from "@mui/material";
import { AMFormImage } from "components/common";
import { useTheme } from "@mui/material/styles";

interface StepInfo {
  label: ReactNode;
}
interface StepperProps {
  activeStep: number;
  steps: StepInfo[];
  content: ReactNode;
  showGrid?: boolean;
  imgSpace?: boolean;
  moreDescritpion?: ReactNode;
}

const AMStepper = ({
  activeStep,
  steps,
  content,
  showGrid = true,
  imgSpace = true,
  moreDescritpion,
}: Readonly<StepperProps>) => {
  const theme = useTheme();

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        sx={{
          ".MuiStepLabel-root .MuiSvgIcon-root": {
            height: "1.04em",
            width: "1.04em",
          },
          "&.MuiStepper-root": {
            margin: "0 -8px",
          },
          [theme.breakpoints.down("md")]: {
            ".MuiStepLabel-root": {
              textAlign: "center",
            },
            ".MuiStepLabel-labelContainer": {
              display: "none",
            },
            ".MuiStepLabel-iconContainer": {
              paddingRight: 0,
            },
            ".MuiStepLabel-label": {
              fontSize: "16px!important",
              lineHeight: "16px",
            },
          },
        }}
      >
        {steps.map((step: StepInfo, index: number) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={String(step.label)} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
        {moreDescritpion && (
          <CardContent
            sx={{
              paddingTop: "40px",
              paddingBottom: "0px!important",
              paddingX: 0,
              [theme.breakpoints.down("md")]: {
                paddingTop: 0,
              },
            }}
          >
            {moreDescritpion}
          </CardContent>
        )}
        {showGrid ? (
          <AMFormImage imgSpace={imgSpace}>
            <CardContent
              sx={{
                paddingTop: "40px",
                paddingBottom: "0px!important",
                paddingX: 0,
                [theme.breakpoints.down("md")]: {
                  paddingTop: 0,
                },
              }}
            >
              {content}
            </CardContent>
          </AMFormImage>
        ) : (
          <CardContent
            sx={{
              paddingTop: "40px",
              paddingBottom: "0px!important",
              paddingX: 0,
            }}
          >
            {content}
          </CardContent>
        )}
      </Box>
    </Box>
  );
};

export default AMStepper;
