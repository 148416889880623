import React from "react";
import GuestGuard from "components/auth/guestGuard";
import ROUTES from "utils/CommonRouter";
import { ADVERTISER, CONTACT, MEDIACHANNEL } from "utils/CommonText";

const ReportFraud = React.lazy(() => import("views/auth/ReportFraud"));
const Home = React.lazy(() => import("views/main/Home"));
const ClientDetail = React.lazy(() => import("views/main/ClientDetail"));
const About = React.lazy(() => import("views/main/About"));
const Contact = React.lazy(() => import("views/main/Contact"));
const DetailFaq = React.lazy(() => import("views/main/DetailFaq"));
const CreateAccount = React.lazy(() => import("views/auth/CreateAccount"));
const PreVerification = React.lazy(
  () => import("views/auth/CreateAccount/PreVerification")
);
const CreateAccountSuccess = React.lazy(
  () => import("views/auth/CreateAccount/Success")
);
const ReportError = React.lazy(() => import("views/auth/ReportError"));
const SignIn = React.lazy(() => import("views/auth/SignIn"));
const ForgotPassword = React.lazy(() => import("views/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("views/auth/ResetPassword"));
const Success = React.lazy(() => import("views/auth/ReportFraud/Success"));
const Chart = React.lazy(() => import("views/main/Chart"));
const AMSuccessPage = React.lazy(
  () => import("components/success-pages/AM_SuccessPage")
);
const AMSignOut = React.lazy(() => import("components/page/AM_SignOut"));
const AMComingsoon = React.lazy(() => import("components/page/AM_Comingsoon"));
const AMErrorPage = React.lazy(
  () => import("components/error-pages/AM_ErrorPage")
);

export const mainRoutes = [
  { path: ROUTES["INDEX_ROUTE"], element: <Home />, title: "home" },
  {
    path: ROUTES["MEDIA_CHANNEL_ROUTE"],
    element: <ClientDetail clientType={MEDIACHANNEL} />,
    title: "media",
  },
  {
    path: ROUTES["ADVERTISER_ROUTE"],
    element: <ClientDetail clientType={ADVERTISER} />,
    title: "advertiser",
  },
  { path: ROUTES["ABOUT_ROUTE"], element: <About />, title: "about" },
  { path: ROUTES["CONTACT_ROUTE"], element: <Contact />, title: "contact" },
  {
    path: ROUTES["ADVERTISER_FAQ_ROUTE"],
    element: <DetailFaq clientType={ADVERTISER} />,
    title: "detailFaq",
  },
  {
    path: ROUTES["MEDIA_CHANNEL_FAQ_ROUTE"],
    element: <DetailFaq clientType={MEDIACHANNEL} />,
    title: "detailFaq",
  },
  {
    path: ROUTES["CONTACT_FAQ_ROUTE"],
    element: <DetailFaq clientType={CONTACT} />,
    title: "detailFaq",
  },
  {
    path: ROUTES["CREATE_ACCOUNT"],
    element: <CreateAccount />,
    title: "createAccount",
    guard: GuestGuard,
  },
  {
    path: ROUTES["CREATE_ACCOUNT_PRE_VERIFICATION"],
    element: <PreVerification />,
    title: "createAccount",
  },
  {
    path: ROUTES["CREATE_ACCOUNT_VERIFICATION_SUCCESS"],
    element: <CreateAccountSuccess />,
    title: "createAccount",
  },
  { path: ROUTES["ERROR"], element: <AMErrorPage />, title: "error" },
  {
    path: ROUTES["ADVERTISER_SIGN_IN"],
    element: <SignIn signInType={ADVERTISER} />,
    title: "signin",
    guard: GuestGuard,
  },
  {
    path: ROUTES["MEDIA_CHANNEL_SIGN_IN"],
    element: <SignIn signInType={MEDIACHANNEL} />,
    title: "signin",
    guard: GuestGuard,
  },
  {
    path: ROUTES["SIGN_IN"],
    element: <SignIn />,
    title: "signin",
    guard: GuestGuard,
  },
  { path: ROUTES["SERVICES"], element: <AMComingsoon />, title: "services" },
  {
    path: ROUTES["EMPLOYMENT"],
    element: <AMComingsoon />,
    title: "employment",
  },
  {
    path: ROUTES["OURPOLICIES"],
    element: <AMComingsoon />,
    title: "ourPolicies",
  },
  {
    path: ROUTES["TERM_AND_CONDITIONS"],
    element: <AMComingsoon />,
    title: "termcont",
  },
  { path: ROUTES["BLOG"], element: <AMComingsoon />, title: "blog" },
  { path: ROUTES["QA"], element: <AMComingsoon />, title: "qa" },
  {
    path: ROUTES["FORGOT_PASSWORD"],
    element: <ForgotPassword />,
    title: "forgotPassword",
  },
  {
    path: ROUTES["RESET_PASSWORD"],
    element: <ResetPassword />,
    title: "resetPwd",
  },
  { path: ROUTES["SIGN_OUT"], element: <AMSignOut />, title: "signOut" },
  {
    path: ROUTES["REPORT_FRAUD_SUCCESS"],
    element: <Success />,
    title: "reportFraud",
  },
  {
    path: ROUTES["REPORT_FRAUD"],
    element: <ReportFraud />,
    title: "reportFraud",
  },
  {
    path: ROUTES["REPORT_ERROR"],
    element: <ReportError />,
    title: "reportError",
  },
  { path: ROUTES["SUCCESS"], element: <AMSuccessPage />, title: "success" },
  { path: ROUTES["CHART"], element: <Chart />, title: "Chart" },
];
