import React from "react";
import { Input } from "@mui/material";
import { FieldInputProps } from "react-final-form";
import AMLabel from "./AM_Label";

interface AMInputProps {
  input?: FieldInputProps<HTMLElement>;
  label?: string;
  required?: boolean;
  autocomplete?: string;
  isDisabled?: boolean;
}
const AMInput = ({
  input,
  label,
  required,
  autocomplete,
  isDisabled,
}: Readonly<AMInputProps>) => {
  return (
    <div>
      <AMLabel label={label} required={required} />
      <Input {...input} autoComplete={autocomplete} disabled={isDisabled} />
    </div>
  );
};

export default AMInput;
