import React, { useState, useEffect } from "react";
import { Card, Box, Container, Breadcrumbs, Typography } from "@mui/material";
import { NavigateNext, ArrowBack } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  useLocation,
  useRouteError,
  Link,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBreadcrumbs, setupMediaQueryListener } from "utils/CommonFunctions";
import { CustomError } from "utils/CommonTypes";
import { uiConfig } from "designsystem";
interface BreadcrumbItem {
  href: string;
  title: string;
}

interface Breadcrumbsprops {
  boxShadow?: string;
}

const AMBreadcrumbs = ({ boxShadow }: Readonly<Breadcrumbsprops>) => {
  const theme = useTheme();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("menu");
  const [breadcrumbdata, setBreadcrumbdata] = useState([
    { title: "", href: "" },
  ]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const error = useRouteError();

  useEffect(() => {
    setTimeout(() => {
      let routes =
        localStorage.getItem("breadcrumbs") != null
          ? JSON.parse(localStorage.getItem("breadcrumbs") ?? "[]")
          : [];
      let updateBreadcrumb: { title: string; href: string }[] = getBreadcrumbs(
        routes as string[],
        t,
        error as CustomError
      );

      if (location.state?.Breadcrumbs) {
        updateBreadcrumb[updateBreadcrumb.length - 1].title = `${
          location.state.route
        } ${t(location.state.Breadcrumbs)}`;
      }

      setBreadcrumbdata(updateBreadcrumb);
    }, 100);
  }, [pathname, t, error, state, location.state]);

  // FOR MOBILE VIEW NOTIFICATION STARTS
  useEffect(() => {
    const tabScreen = setupMediaQueryListener(
      "(max-width: 959.95px)",
      setIsSmallScreen
    );
    return tabScreen;
  }, []);
  // FOR MOBILE VIEW NOTIFICATION ENDS

  const backpage = () => {
    navigate(-1);
  };

  return (
    <Box
      boxShadow={boxShadow}
      width={uiConfig.AMfull}
      padding={uiConfig.AMBreadcrumb}
      zIndex={uiConfig.AM0}
    >
      {breadcrumbdata.length > 0 && (
        <Container maxWidth="xl">
          {isSmallScreen ? (
            <div>
              {/* IN MOBILE VIEW BREADCRUMBS BACK STARTS */}
              <Card
                elevation={0}
                sx={{
                  padding: 0,
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowBack
                  onClick={backpage}
                  sx={{
                    color: "customColor.menuIcon",
                    cursor: "pointer",
                    position: "absolute",
                    left: uiConfig.AM0,
                    top: uiConfig.AM0,
                  }}
                />
                <Typography variant="caption" margin={0}>
                  {breadcrumbdata[breadcrumbdata.length - 1].title}
                </Typography>
              </Card>
              {/* IN MOBILE VIEW BREADCRUMBS BACK ENDS */}
            </div>
          ) : (
            <div>
              {/* IN WEB VIEW BREADCRUMBS BACK STARTS */}
              <Breadcrumbs
                separator={
                  <NavigateNext
                    fontSize="small"
                    sx={{ color: "secondary.light" }}
                  />
                }
                aria-label="breadcrumb"
                sx={{
                  display: "block",
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                }}
              >
                {breadcrumbdata.map(
                  (breadcrumb: BreadcrumbItem, index: number) =>
                    index !== breadcrumbdata.length - 1 ? (
                      <Link key="1" to={breadcrumb.href}>
                        <Typography variant="caption" margin={0}>
                          {breadcrumb.title}
                        </Typography>
                      </Link>
                    ) : (
                      <Typography
                        key="3"
                        margin={0}
                        color="common.black"
                        variant="caption"
                      >
                        {breadcrumb.title}
                      </Typography>
                    )
                )}
              </Breadcrumbs>
              {/* IN MOBILE WEB BREADCRUMBS BACK ENDS */}
            </div>
          )}
        </Container>
      )}
    </Box>
  );
};

export default AMBreadcrumbs;
