import * as React from "react";
import { Typography } from "@mui/material";
import { textStyles, uiConfig } from "designsystem";

interface PageTitleProps {
  title?: string;
  isH2?: boolean;
  isMargin?: boolean;
  isPadding?: boolean;
  alignTxt?: string;
  isSmall?: boolean;
}

const AMPageTitle = ({
  title,
  isH2,
  isMargin,
  alignTxt,
  isPadding,
  isSmall,
}: Readonly<PageTitleProps>) => {
  return (
    <Typography
      variant={isH2 ? "h2" : "h1"}
      title={title}
      sx={{
        textAlign: alignTxt ?? "initial",
        marginY: isMargin ? uiConfig.AM16 : "",
        paddingLeft: isPadding ? uiConfig.AM40 : "",
        fontSize: isSmall
          ? textStyles.fontSize.small
          : isH2
          ? textStyles.fontSize.medium
          : textStyles.fontSize.heading,
      }}
    >
      {typeof title === "string" ? (
        <span dangerouslySetInnerHTML={{ __html: title }} />
      ) : (
        <span>{title}</span>
      )}
    </Typography>
  );
};
export default AMPageTitle;
