import { textStyles } from "designsystem";
import * as colors from "designsystem/Palette";

export const AccordionStyles = {
  root: {
    "&.expandarrowspace .expandarrow": {
      marginRight: 0,
      width: "auto",
      height: "auto",
    },
    "&.checkboxAccordion": {
      marginBottom: "8px!important",
      border: `1px solid ${colors.stackFormBorder}`,
      borderRadius: "8px",
      "&.active": {
        background: colors.secondaryDarkColor,
      },
      ".name-wrp": {
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
        ".MuiCheckbox-root": {
          padding: "0 9px",
          svg: { width: "20px", height: "20px" },
        },
        ".MuiListItemText-root": {
          margin: 0,
          ".MuiTypography-root": {
            margin: 0,
            fontFamily: textStyles.fontFamily.defaultFont,
            fontSize: textStyles.fontSize.large,
            color: colors.blackColor,
            border: 0,
          },
        },
      },
      ".MuiAccordionDetails-root": {
        padding: "8px 12px",
        ".search-more-info-wrp": {
          ".MuiTypography-root": {
            border: "0 !important",
            marginBottom: "10px!important",
            "&:last-child": {
              marginBottom: "0px!important",
            },
          },
        },
      },
      ".MuiAccordionSummary-root": {
        padding: "10px 12px",
      },
      ".MuiAccordionSummary-content": {
        margin: "0!important",
      },
    },
  },
};
