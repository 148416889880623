import React, { useEffect, useState, useRef } from "react";
import {
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  CardContent,
} from "@mui/material";
import { AMNotFound } from "components/common";
import { getCategoryStyles } from "utils/CommonFunctions";
import { uiConfig } from "designsystem";
import { NotificationItem, Notifications } from "utils/CommonTypes";

interface TabPanelProps {
  items: Notifications;
  nofound: string;
}

const AMNotificationTabs = ({ items, nofound }: Readonly<TabPanelProps>) => {
  const [value, setValue] = useState(0);
  const hasEffectRun = useRef(false);
  const [category, setCategory] = useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!hasEffectRun.current) {
      hasEffectRun.current = true;

      const categoriesWithItems = Object.entries(items).filter(
        ([, category]) => category.items.length > 0
      );

      if (categoriesWithItems.length === 0) {
        const firstTabWithItemsIndex = Object.keys(items).findIndex(
          (category) => items[category as keyof Notifications].items.length > 0
        );

        if (firstTabWithItemsIndex !== -1) {
          setValue(firstTabWithItemsIndex);
          setCategory(Object.keys(items)[firstTabWithItemsIndex]);
        }
      }
    } else {
      setCategory(Object.keys(items)[value]);
    }
  }, [items, value, hasEffectRun]);

  return (
    <Box sx={{ width: uiConfig.AMfull, padding: uiConfig.AM0 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Notification Tab"
          TabIndicatorProps={{
            sx: { bgcolor: getCategoryStyles(category).bgColor },
          }}
        >
          {Object.entries(items).map(([category, data], index) => (
            <Tab
              key={category}
              disableFocusRipple
              disableTouchRipple
              icon={
                <ListItemAvatar
                  sx={{
                    bgcolor: getCategoryStyles(category).bgColor,
                  }}
                >
                  {data.totalCount}
                </ListItemAvatar>
              }
              iconPosition="start"
              label={category}
            />
          ))}
        </Tabs>
      </Box>
      <CardContent sx={{ padding: `${uiConfig.AM0} !important` }}>
        {/* Your content for each tab goes here */}
        {Object.entries(items).map(([category, data], index) => (
          <List
            key={category}
            sx={{
              display: value === index ? "block" : "none",
            }}
            className="scroller tabListWrp"
          >
            {data.items.length > 0 ? (
              <div>
                {data.items.map((tabitem: NotificationItem) => (
                  <ListItem
                    key={tabitem.id}
                    sx={{
                      "&:hover": {
                        borderLeftColor: `${
                          getCategoryStyles(category).borderColor
                        } !important`,
                        bgcolor: getCategoryStyles(category).itemsBgColor,
                      },
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        marginTop: uiConfig.AM5,
                        marginRight: uiConfig.AM10,
                        bgcolor: getCategoryStyles(category).borderColor,
                      }}
                    >
                      {tabitem.id}
                    </ListItemAvatar>
                    <ListItemText
                      primary={tabitem.detailItemText}
                      secondary={tabitem.timestamp}
                    />
                  </ListItem>
                ))}
              </div>
            ) : (
              <AMNotFound value={nofound} />
            )}
          </List>
        ))}
      </CardContent>
    </Box>
  );
};

export default AMNotificationTabs;
