import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

interface MaintenanceLayoutProps {
  children?: ReactNode;
}

const MaintenanceLayout = ({ children }: Readonly<MaintenanceLayoutProps>) => {
  return (
    <div>
      {/* HEADER SECTION STARTS HERE */}
      <Header />
      {/* HEADER SECTION ENDS HERE */}
      {/* ALL PAGES SECTION STARTS HERE */}
      <main className="main-wrp">{children ?? <Outlet />}</main>
      {/* ALL PAGES SECTION ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <Footer />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
};
export default MaintenanceLayout;
