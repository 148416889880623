import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const FormControlLabelStyles = {
  root: {
    color: colors.secondaryLightColor,
    "& .MuiTypography-root": {
      fontSize: uiConfig.AM18,
      borderBottom: uiConfig.AM0,
    },
    "& .MuiSvgIcon-root": {
      width: uiConfig.AM16,
      height: uiConfig.AM16,
      position: textStyles.position.relative,
      top: uiConfig.AM3,
      color: colors.svgIconColor,
      marginLeft: uiConfig.AM5,
    },
    "&.common-checkbox": {
      gap: "10px",
      marginTop: "10px",
      ".MuiButtonBase-root": {
        padding: 0,
      },
      ".MuiSvgIcon-root": {
        top: 0,
        color: colors.primaryDarkColor,
      },
    },
  },
};
