import React, { ReactNode } from "react";
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import AMCommonInfoCard from "../AM_CommonInfoCard";
import { AMStatusButton } from "../../common";
import {
  DeleteOutlined,
  ModeOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  getContactType,
  getPaymentBankType,
  getUserContactVerificationType,
  getVerificationType,
} from "utils/CommonFunctions";
import { useTranslation } from "react-i18next";
import { DataKey } from "utils/CommonTypes";
import { useTheme } from "@mui/material/styles";

interface AMCarouselBoxItemProps {
  dataKey?: DataKey;
  editClick?: () => void;
  deleteClick?: () => void;
  viewClick?: () => void;
  gridFull?: boolean;
  cardDescription: ReactNode;
}

const AMCarouselBoxItem = ({
  cardDescription,
  dataKey,
  gridFull,
  editClick,
  deleteClick,
  viewClick,
}: Readonly<AMCarouselBoxItemProps>) => {
  const { t } = useTranslation("advertmingo-contacts");
  const paymentTranslate = useTranslation("advertmingo-bank-details").t;
  const theme = useTheme();

  const getTitle = () => {
    if (dataKey?.bankTypeApiName) {
      return getPaymentBankType(dataKey.bankTypeApiName, paymentTranslate);
    } else if (dataKey?.contactTypeApiName) {
      return getContactType(dataKey.contactTypeApiName, t);
    } else {
      return "";
    }
  };

  const getValue = () => {
    if (dataKey?.userContactVerificationTypeApiName) {
      return getUserContactVerificationType(
        dataKey.userContactVerificationTypeApiName,
        paymentTranslate
      );
    } else if (dataKey?.verificationStatus) {
      return getVerificationType(dataKey.verificationStatus, t);
    } else {
      return "";
    }
  };

  return (
    <Grid item xs={12} md={gridFull ? 12 : 6}>
      <AMCommonInfoCard
        isLgLabel
        title={`${getTitle()} ${dataKey?.alias ? "- " + dataKey.alias : ""} ${
          dataKey?.isPrimary ? "- Primary" : ""
        }`}
        description={
          <Card
            elevation={0}
            className="contactinfo"
            sx={{
              [theme.breakpoints.between("md", "lg")]: {
                flexDirection: "column",
                gap: "24px",
                ".maildescription": {
                  width: "100%",
                },
                ".actionprogress": {
                  width: "100%",
                  justifyContent: "flex-start",
                },
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                gap: "24px",
                ".maildescription": {
                  width: "100%",
                },
                ".actionprogress": {
                  width: "100%",
                  justifyContent: "flex-start",
                },
              },
            }}
          >
            <CardContent className="maildescription">
              {cardDescription}
            </CardContent>
            <CardContent className="actionprogress">
              <AMStatusButton
                value={getValue()}
                filColor={
                  dataKey?.userContactVerificationTypeApiName ??
                  dataKey?.verificationStatus
                }
              />
            </CardContent>
          </Card>
        }
        button={
          <CardActions className="cardactionbtn" sx={{ padding: 0 }}>
            {dataKey?.bankTypeApiName && (
              <VisibilityOutlined className="viewicon" onClick={viewClick} />
            )}
            <ModeOutlined className="editicon" onClick={editClick} />
            <DeleteOutlined className="deleteicon" onClick={deleteClick} />
          </CardActions>
        }
      />
    </Grid>
  );
};

export default AMCarouselBoxItem;
