import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  SetStateAction,
  Dispatch,
} from "react";
import { Tabs, Tab, Box, CardContent } from "@mui/material";
import { uiConfig } from "designsystem";
import { deleteCookie } from "utils/cookieService";
import {
  ABOUT_AUDIENCE_TAB_KEY,
  ABOUT_FOLLOWS_TAB_KEY,
  ABOUT_ME_TAB_KEY,
  ABOUT_ORGANIZATION_TAB_KEY,
  ADV_ALL_TAB,
  ADV_CUSTOM_OFFER_TAB,
  ADV_REJECTED_TAB,
  ADV_REQUIRED_TAB,
  ADV_SPECIAL_OFFER_TAB,
  ADV_VERIFICATION_PENDING_TAB,
  ADV_VERIFIED_TAB,
  ADVERTISER_AUDIENCE_TAB_KEY,
  ADVERTISER_CHANNEL_TAB_KEY,
  ADVERTISER_CLIENT_CONTACT_TAB_KEY,
  ADVERTISER_CLIENT_PROJECT_TAB_KEY,
  ADVERTISER_CLIENT_TAB_KEY,
  ADVERTISER_PROJECT_TAB_KEY,
  ALL_MEDIA_CHANNEL_TAB_KEY,
  CUSTOM_MC_TAB_KEY,
  CUSTOM_MEDIA_CHANNEL_TAB_KEY,
  GENERAL_TAB_KEY,
  MC_OFFERED_TAB_KEY,
  MC_OTHER_TAB_KEY,
  MC_PRIVATE_TAB_KEY,
  MC_REQUEST_PREFERRED_TAB_KEY,
  MC_REQUESTED_TAB_KEY,
  PAYMENT_CREDITED_PAYMENT_TAB_KEY,
  PAYMENT_CURRENT_PAYMENT_TAB_KEY,
  PAYMENT_INVOICE_TAB_KEY,
  PAYMENT_PAYMENT_HISTORY_TAB_KEY,
  REQUEST_PREFERRED_TAB_KEY,
  SOCIAL_MEDIA_TAB_KEY,
} from "utils/CommonText";

interface TabPanelProps {
  items: CustomTab;
  onCategoryChange: (category: string) => void;
  setStateFrom?: Dispatch<SetStateAction<any>>;
  setStateTo?: Dispatch<SetStateAction<any>>;
  setStateSearchValue?: Dispatch<SetStateAction<any>>;
  activeTab?: string;
  valueIndex?: number;
  handleValueIndex?: (indexValue: number) => void;
  orientation?: string;
}

interface CustomTab {
  [key: string]: {
    children: React.ReactNode;
  };
}
// Setting index for all tab keys
const setTabKeyIndex: { [key: string]: number } = {
  [ADVERTISER_PROJECT_TAB_KEY]: 0,
  [SOCIAL_MEDIA_TAB_KEY]: 0,
  [GENERAL_TAB_KEY]: 0,
  [PAYMENT_CURRENT_PAYMENT_TAB_KEY]: 0,
  [ABOUT_ME_TAB_KEY]: 0,
  [ADVERTISER_CLIENT_TAB_KEY]: 0,
  [ADVERTISER_CHANNEL_TAB_KEY]: 0,
  [ADV_SPECIAL_OFFER_TAB]: 0,
  [ADV_ALL_TAB]: 0,
  [MC_OFFERED_TAB_KEY]: 0,
  [ADVERTISER_AUDIENCE_TAB_KEY]: 1,
  [ALL_MEDIA_CHANNEL_TAB_KEY]: 1,
  [REQUEST_PREFERRED_TAB_KEY]: 1,
  [PAYMENT_CREDITED_PAYMENT_TAB_KEY]: 1,
  [MC_REQUEST_PREFERRED_TAB_KEY]: 2,
  [ABOUT_FOLLOWS_TAB_KEY]: 1,
  [ABOUT_ORGANIZATION_TAB_KEY]: 1,
  [ADVERTISER_CLIENT_CONTACT_TAB_KEY]: 1,
  [ADV_CUSTOM_OFFER_TAB]: 1,
  [ADV_VERIFIED_TAB]: 1,
  [MC_PRIVATE_TAB_KEY]: 1,
  [CUSTOM_MC_TAB_KEY]: 2,
  [PAYMENT_PAYMENT_HISTORY_TAB_KEY]: 2,
  [CUSTOM_MEDIA_CHANNEL_TAB_KEY]: 2,
  [ABOUT_AUDIENCE_TAB_KEY]: 2,
  [ADVERTISER_CLIENT_PROJECT_TAB_KEY]: 2,
  [ADV_VERIFICATION_PENDING_TAB]: 2,
  [MC_REQUESTED_TAB_KEY]: 2,
  [PAYMENT_INVOICE_TAB_KEY]: 3,
  [ADV_REJECTED_TAB]: 3,
  [MC_OTHER_TAB_KEY]: 3,
  [ADV_REQUIRED_TAB]: 4,
};

const AMCommonTab = ({
  items,
  onCategoryChange,
  setStateFrom,
  setStateTo,
  setStateSearchValue,
  activeTab,
  valueIndex,
  handleValueIndex,
  orientation,
}: Readonly<TabPanelProps>) => {
  const [value, setValue] = useState(valueIndex ?? 0);
  const hasEffectRun = useRef(false);
  const [category, setCategory] = useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    deleteCookie(["activeAboutMenuItem"]);
    setTimeout(() => {
      if (valueIndex !== undefined && valueIndex !== null) {
        if (handleValueIndex) {
          handleValueIndex(newValue);
        }
      } else {
        setValue(newValue);
      }
    }, 100);
  };

  useEffect(() => {
    if (valueIndex !== undefined && valueIndex !== null) {
      setValue(valueIndex);
    }
  }, [valueIndex]);

  const memoizedOnCategoryChange = useCallback(
    (category: string) => {
      onCategoryChange(category);
    },
    [onCategoryChange]
  );

  useEffect(() => {
    if (setStateFrom && setStateTo && setStateSearchValue) {
      setStateFrom(null);
      setStateTo(null);
      setStateSearchValue("");
    }
  }, [category, setStateFrom, setStateSearchValue, setStateTo]);

  useEffect(() => {
    if (!hasEffectRun.current) {
      hasEffectRun.current = true;
      if (activeTab && setTabKeyIndex?.hasOwnProperty(activeTab)) {
        setValue(setTabKeyIndex[activeTab]);
      }
    } else {
      const currentCategory = Object.keys(items)[value];
      setCategory(currentCategory);
      setTimeout(() => {
        memoizedOnCategoryChange(currentCategory);
      }, 100);
    }
  }, [
    items,
    value,
    hasEffectRun,
    category,
    memoizedOnCategoryChange,
    activeTab,
  ]);

  return (
    <Box sx={{ width: uiConfig.AMfull, padding: uiConfig.AM0 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        className="common-head-tabs"
        variant="scrollable"
        allowScrollButtonsMobile
        aria-label="Tabs"
      >
        {Object.entries(items).map(([category, data], index) => (
          <Tab
            key={category}
            disableFocusRipple
            disableTouchRipple
            iconPosition="start"
            label={category}
          />
        ))}
      </Tabs>
      <CardContent sx={{ padding: `${uiConfig.AM0} !important` }}>
        {/* Your content for each tab goes here */}
        {Object.entries(items).map(
          ([category, data], index) =>
            value === index && <Box key={category}>{data?.children}</Box>
        )}
      </CardContent>
    </Box>
  );
};

export default AMCommonTab;
