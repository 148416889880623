import React from "react";
import { Box, Slider, Typography } from "@mui/material";
import AMLabel from "./AM_Label";

const MAX = 100;
const MIN = 0;
interface AMRangeSliderProps {
  value: any;
  onChange: (event: Event, newValue: number | number[]) => void;
  label: string;
  required?: boolean;
  min: number;
  max: number;
}
const AMRangeSlider = ({
  label,
  required,
  value,
  min,
  max,
  onChange,
}: Readonly<AMRangeSliderProps>) => {
  const [val, setVal] = React.useState<number[]>(value);

  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(event, newValue);
    setVal(newValue as number[]);
  };
  return (
    <div>
      <AMLabel label={label} required={required} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "30px",
          alignItems: "center",
        }}
      >
        <Typography sx={{ cursor: "pointer", border: 0 }}>
          {min ?? MIN}
        </Typography>
        <Slider
          getAriaLabel={() => "Temperature range"}
          value={val}
          onChange={handleChange}
          valueLabelDisplay="on"
          min={min ?? MIN}
          max={max ?? MAX}
          disableSwap
        />
        <Typography sx={{ cursor: "pointer", border: 0 }}>
          {max ?? MAX}
        </Typography>
      </Box>
    </div>
  );
};

export default AMRangeSlider;
