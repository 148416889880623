import React, { ReactNode } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Drawer,
  CardActions,
  IconButton,
} from "@mui/material";
import AMButton from "./AM_Button";
import { HighlightOff } from "@mui/icons-material";

interface AMDrawerProps {
  title: string;
  description: ReactNode;
  handleClose: () => void;
  open: boolean;
  handleConfirmation: () => void;
  isDisabled?: boolean;
  width?: string;
  submitTxt?: string;
  ishandleContentForm?: boolean;
}

const AMDrawer = ({
  handleClose,
  open,
  description,
  title,
  handleConfirmation,
  isDisabled,
  width,
  submitTxt,
  ishandleContentForm,
}: Readonly<AMDrawerProps>) => {
  const handleDrawerClose = (event: React.MouseEvent | React.KeyboardEvent) => {
    const backdrop = (event.target as HTMLElement).closest(".MuiBackdrop-root");
    if (backdrop) {
      event.stopPropagation();
      return;
    }
    handleClose();
  };

  return (
    <Drawer
      open={open}
      onClose={handleDrawerClose}
      anchor="right"
      className="common-drawer-wrp"
      ModalProps={{
        BackdropProps: { invisible: true },
      }}
      PaperProps={{
        style: { width: width ? width : "650px" },
      }}
    >
      <Card elevation={0}>
        <CardHeader
          title={title}
          action={
            <IconButton
              aria-label="settings"
              disableFocusRipple
              disableTouchRipple
              onClick={handleClose}
            >
              <HighlightOff />
            </IconButton>
          }
        />
        <CardContent
          className="scroller"
          sx={{ height: "calc(100vh - 120px)" }}
        >
          {description}
        </CardContent>
        <CardActions className="common-drawer-footer">
          <AMButton
            margintop={""}
            value={"Cancel"}
            filColor={"red"}
            buttonClick={handleClose}
          />
          <AMButton
            id="drawer-submit-button"
            value={submitTxt ? submitTxt : "Submit"}
            margintop={""}
            disabled={isDisabled}
            buttonClick={
              ishandleContentForm
                ? () => document.getElementById("form-submit-button")?.click()
                : handleConfirmation
            }
          />
        </CardActions>
      </Card>
    </Drawer>
  );
};

export default AMDrawer;
