import React from "react";
import { Navigate } from "react-router-dom";
import ROUTES from "utils/CommonRouter";

const AuthGuard = ({ element }: { element: React.ReactNode }) => {
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;

  if ((user && !user.authenticated) || user === null) {
    return <Navigate to={ROUTES["SIGN_IN"]} />;
  }
  return <section>{element}</section>;
};

export default AuthGuard;
