import React, { useState, useEffect, useRef, ReactNode } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { carouselNav } from "images";
import { Typography, CardMedia, CardActions } from "@mui/material";

interface CarouselBoxProps {
  children: ReactNode;
  autoplaySpeed?: number;
  autoplay?: boolean;
  slidesToShow?: number;
  slidesToScroll?: number;
  cardOuterArrow?: boolean;
  initialValue?: number;
}

interface CarouselBoxArrowProps {
  onClick?: () => void;
  onKeyDown?: () => void;
}

interface CarouselBoxControllerProps extends Readonly<CarouselBoxArrowProps> {
  readonly className?: string;
}

function CarouselBoxController(props: Readonly<CarouselBoxControllerProps>) {
  const { onClick, onKeyDown, className } = props;
  return (
    <button
      className={`${className}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      aria-label={"Carousel Nav"}
      aria-labelledby={"Carousel Nav"}
    >
      <CardMedia
        component="img"
        width="6px"
        height="9px"
        image={carouselNav}
        alt="Carousel Nav"
      />
    </button>
  );
}

const AMCarouselBox = ({
  children,
  autoplaySpeed,
  autoplay,
  slidesToShow = 1,
  slidesToScroll = 1,
  cardOuterArrow = false,
  initialValue = 0,
}: Readonly<CarouselBoxProps>) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const sliderRef = useRef<Slider>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [visibleSlides, setVisibleSlides] = useState(slidesToShow);

  const getResponsiveSettings = (slidesToShow: number) => [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: Math.min(4, slidesToShow),
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: Math.min(3, slidesToShow),
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: Math.min(2, slidesToShow),
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
      },
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    arrows: false,
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed ?? 2000,
    slidesToShow: visibleSlides,
    slidesToScroll: slidesToScroll,
    pauseOnHover: true,
    initialSlide: initialValue,
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next);
    },
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
    responsive: getResponsiveSettings(slidesToShow),
  };

  useEffect(() => {
    if (sliderRef.current?.innerSlider?.list) {
      const slickSlideElements =
        sliderRef.current.innerSlider.list.querySelectorAll(".slick-slide");
      setTotalSlides(slickSlideElements.length);
    }
  }, [children]);

  useEffect(() => {
    const handleResize = () => {
      const carouselElement = carouselRef.current;
      if (!carouselElement) return;

      let visibleSlides = slidesToShow;
      const width = window.innerWidth;

      if (width < 550) {
        visibleSlides = 1;
      } else if (width < 769) {
        visibleSlides = Math.min(2, slidesToShow);
      } else if (width < 1024) {
        visibleSlides = Math.min(3, slidesToShow);
      } else if (width < 1200) {
        visibleSlides = Math.min(4, slidesToShow);
      } else {
        visibleSlides = slidesToShow;
      }

      setVisibleSlides(visibleSlides);

      const lastIndexToShow = currentSlide + visibleSlides - 1;

      const nextButton = carouselElement.querySelector(".slickNext");
      const prevButton = carouselElement.querySelector(".slickPrev");

      if (currentSlide === 0) {
        prevButton?.classList.add("slick-disabled");
      } else {
        prevButton?.classList.remove("slick-disabled");
      }

      if (lastIndexToShow >= totalSlides - 1) {
        nextButton?.classList.add("slick-disabled");
      } else {
        nextButton?.classList.remove("slick-disabled");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentSlide, slidesToShow, totalSlides]);

  useEffect(() => {
    const carouselElement = carouselRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting);
        });
      },
      { threshold: 0.5 }
    );

    if (carouselElement) {
      observer.observe(carouselElement);
    }

    return () => {
      if (carouselElement) {
        observer.unobserve(carouselElement);
      }
    };
  }, [carouselRef]);

  useEffect(() => {
    if (sliderRef.current) {
      if (autoplay && isInView) {
        sliderRef.current.slickPlay();
      } else {
        sliderRef.current.slickPause();
      }
    }
  }, [autoplay, isInView]);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0, true);
    }
  }, [children]);

  return (
    <div ref={carouselRef}>
      <Slider ref={sliderRef} {...settings}>
        {React.Children.map(children, (child) => (
          <div>{child}</div>
        ))}
      </Slider>
      <CardActions
        className={`${
          cardOuterArrow
            ? "arrow-wrapper outer-carousel-arrow"
            : "arrow-wrapper"
        }`}
      >
        <CarouselBoxController
          className="slickPrev"
          onClick={() => sliderRef.current?.slickPrev()}
        />
        <Typography>{`${Math.max(
          currentSlide + 1,
          1
        )} of ${totalSlides}`}</Typography>
        <CarouselBoxController
          className="slickNext"
          onClick={() => sliderRef.current?.slickNext()}
        />
      </CardActions>
    </div>
  );
};

export default AMCarouselBox;
