import React, { ReactNode } from "react";
import { Card, CardContent, Modal } from "@mui/material";
import { AMInfoTitle } from "components/widgets";
import { useTranslation } from "react-i18next";
interface AMModalPopupProps {
  title: string;
  description: ReactNode;
  handleClose?: () => void;
  open: boolean;
}
const AMModalPopup = ({
  handleClose,
  open,
  description,
  title,
}: Readonly<AMModalPopupProps>) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card className="infocardwrp modalwrp">
        <AMInfoTitle
          title={title}
          value={t("close")}
          buttonClick={handleClose}
        />
        <CardContent className="modal-description scroller">
          {description}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AMModalPopup;
