import { Card, CardActions, CardContent, Grid } from "@mui/material";
import {
  AMButton,
  AMErrorText,
  AMInput,
  AMPageTitle,
  AMSelect,
} from "components/common";
import React, { ChangeEvent, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { TableFormField } from "utils/CommonTypes";
import { TableFormValidation } from "utils/Validation";
import statusJson from "data/cms/table-status.json";
import { getOptionData, handleBack, windowScroll } from "utils/CommonFunctions";

const TableForm = () => {
  const { t } = useTranslation("table-form");
  const { id } = useParams();
  const { state } = useLocation();
  const fieldValues = state ? state?.inputValues || {} : {};

  const [statusSelect, setStatusSelect] = useState<string>(
    fieldValues.status ?? ""
  );

  const statusOptions = getOptionData(statusJson.status, t);
  const validate = (e: TableFormField): TableFormField =>
    TableFormValidation(e, t);

  const statusSelectOption = (event: ChangeEvent<{ value: unknown }>) => {
    setStatusSelect(event.target.value as string);
  };

  const onSubmit = (values: TableFormField) => {
    windowScroll();
  };

  return (
    <section id="create-table-form">
      <Card className="infocardwrp">
        <CardContent className="titlewrp">
          <AMPageTitle
            title={id ? `Edit Form - ${id}` : "Add Form"}
            isH2={true}
          />
          <AMButton
            isSmHidden
            margintop=""
            value="Back"
            variant="outlined"
            buttonClick={handleBack}
          />
        </CardContent>
        <CardContent sx={{ minHeight: "300px" }}>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={fieldValues}
            render={({ handleSubmit }) => (
              <form
                onSubmit={handleSubmit}
                className="form-wrp"
                autoComplete="off"
              >
                <Grid container spacing={2} rowSpacing={5}>
                  <Grid item xs={4}>
                    <Field name="name" type="name">
                      {({ input, meta }) => {
                        input = {
                          ...input,
                          placeholder: t("namePlaceholder"),
                        };
                        return (
                          <div>
                            <AMInput
                              required
                              label={t("nameLabel")}
                              input={input}
                            />
                            {meta.touched && meta.error ? (
                              <AMErrorText value={meta.error} />
                            ) : null}
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="age" type="number">
                      {({ input, meta }) => {
                        input = {
                          ...input,
                          placeholder: t("agePlaceholder"),
                          autoComplete: "off",
                        };
                        return (
                          <div>
                            <AMInput
                              required
                              label={t("ageLabel")}
                              input={input}
                            />
                            {meta.touched && meta.error ? (
                              <AMErrorText value={meta.error} />
                            ) : null}
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="email" type="text">
                      {({ input, meta }) => {
                        input = {
                          ...input,
                          placeholder: t("emailPlaceholder"),
                          autoComplete: "off",
                        };
                        return (
                          <div>
                            <AMInput
                              required
                              label={t("emailLabel")}
                              input={input}
                            />
                            {meta.touched && meta.error ? (
                              <AMErrorText value={meta.error} />
                            ) : null}
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="status"
                      defaultValue={statusSelect}
                      initialValue={statusSelect}
                    >
                      {({ input, meta }) => {
                        input = {
                          ...input,
                        };
                        return (
                          <div>
                            <AMSelect
                              input={input}
                              meta={meta}
                              required
                              label={t("statusLabel")}
                              placeholder={t("statusPlaceholder")}
                              inputtype={statusOptions}
                              value={statusSelect}
                              selectOption={statusSelectOption}
                            />
                            {meta.touched && meta.error ? (
                              <AMErrorText value={meta.error} />
                            ) : null}
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="progress" type="number">
                      {({ input, meta }) => {
                        input = {
                          ...input,
                          placeholder: t("progressPlaceholder"),
                          autoComplete: "off",
                        };
                        return (
                          <div>
                            <AMInput
                              required
                              label={t("progressLabel")}
                              input={input}
                            />
                            {meta.touched && meta.error ? (
                              <AMErrorText value={meta.error} />
                            ) : null}
                          </div>
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
                <CardActions
                  sx={{
                    borderTop: "1px solid #eee",
                    justifyContent: "flex-end",
                  }}
                >
                  <AMButton margintop="" type="submit" value="Submit" />
                </CardActions>
              </form>
            )}
          />
        </CardContent>
      </Card>
    </section>
  );
};

export default TableForm;
