import {
  SignInFields,
  CreateAccountFields,
  ForgotPasswordFields,
  ResetPasswordFields,
  ReportFraudStep1Fields,
  ReportFraudStep2Fields,
  ReportFraudStep3Fields,
  CreateAccountStep2Fields,
  ReportErrorStep1Fields,
  ReportErrorStep2Fields,
  TableFormField,
  ContactEmailFormField,
  ContactAddressFormField,
  ContactPhoneFormField,
  ContactFaxFormField,
  ContactSocialMediaFormField,
  BankDetailsStep1Field,
  BankDetailsStep2Field,
  BankDetailsStep3Field,
  MediaChannelFormStep1,
  MediaChannelFormStep3,
  PreferredMediaChannelFormStep2,
  CustomMediaChannelFormStep2,
  AdvertiserClientFormStep1Field,
  AdvertiserProjectFormStep1Field,
  AdvertiserProjectFormStep2Field,
  AdvPublisingCustomOffersFormField,
  AdvertiserCampaignFormStep1Field,
  AdvertiserCampaignPublicationField,
  MediaChannelAddFormStep1,
  PreferredMediaChannelAddForm,
} from "./CommonTypes";

const SignInValidation = (e: SignInFields, t: (key: string) => string) => {
  const errors: Partial<SignInFields> = {};
  if (!e.username) errors.username = t("signInEmail");
  if (e.username && !emailValidation(e.username))
    errors.username = t("signInEmailInvalid");
  if (!e.password) {
    errors.password = t("signInPassword");
  }
  return errors as SignInFields;
};

const CreateAccountValidation = (
  e: CreateAccountFields,
  t: (key: string) => string
) => {
  const errors: Partial<CreateAccountFields> = {};
  if (!e.firstname) errors.firstname = t("createFirstName");
  if (!e.title) errors.title = t("createTitle");
  if (!e.gender) errors.gender = t("createGender");
  if (!e.lastname) errors.lastname = t("createLastName");
  if (!e.mobilenumber) errors.mobilenumber = t("createMobileNumber");
  if (!e.amemail) errors.amemail = t("createEmail");
  if (e.amemail && !emailValidation(e.amemail))
    errors.amemail = t("createEmailInvalid");
  if (!e.ampassword) errors.ampassword = t("createPassword");
  if (!e.repeatpassword) {
    errors.repeatpassword = t("createRepeatPwd");
  } else if (e.repeatpassword !== e.ampassword) {
    errors.repeatpassword = t("createRepeatPwdMatch");
  }

  return errors as CreateAccountFields;
};

const CreateAccountStep2Validation = (
  e: CreateAccountStep2Fields,
  t: (key: string) => string
) => {
  const errors: Partial<CreateAccountStep2Fields> = {};
  if (!e.primaryrole) errors.primaryrole = t("createPrimaryRole");
  if (!e.secondaryrole) errors.secondaryrole = t("createSecondaryRole");

  return errors as CreateAccountStep2Fields;
};

const ResetPasswordValidation = (
  e: ResetPasswordFields,
  t: (key: string) => string
) => {
  const errors: Partial<ResetPasswordFields> = {};
  if (!e.title) errors.title = t("resetTitle");
  if (!e.firstName) errors.firstName = t("resetFirstName");
  if (!e.lastName) errors.lastName = t("resetLastName");
  if (!e.gender) errors.gender = t("resetGender");
  if (!e.email) errors.email = t("resetEmail");
  if (e.email && !emailValidation(e.email))
    errors.email = t("resetEmailInvalid");
  if (!e.password) errors.password = t("resetPassword");
  if (!e.repeatpassword) {
    errors.repeatpassword = t("resetRepeatPwd");
  } else if (e.repeatpassword !== e.password) {
    errors.repeatpassword = t("resetRepeatPwdMatch");
  }
  return errors as ResetPasswordFields;
};

const ForgotPasswordValidation = (
  e: ForgotPasswordFields,
  t: (key: string) => string
) => {
  const errors: Partial<ForgotPasswordFields> = {};
  if (!e.mobilePhone && !e.email) errors.mobilePhone = t("forgotMobileNumber");
  if (!e.email && !e.mobilePhone) errors.email = t("forgotEmail");
  if (e.email && !emailValidation(e.email))
    errors.email = t("forgotEmailInvalid");

  return errors as ForgotPasswordFields;
};

const ReportFraudStep1Validation = (e: ReportFraudStep1Fields, t: any) => {
  const errors: Partial<ReportFraudStep1Fields> = {};
  if (!e.referenceprovided) errors.referenceprovided = t("reportFraudProvided");
  if (!e.reporttype) errors.reporttype = t("reportFraudType");
  if (!e.datetime) errors.datetime = t("reportFraudDateTime");
  if (!e.tellusdetails) errors.tellusdetails = t("reportFraudTellUs");

  return errors as ReportFraudStep1Fields;
};

const ReportFraudStep2Validation = (
  e: ReportFraudStep2Fields,
  t: (key: string) => string
) => {
  const errors: Partial<ReportFraudStep2Fields> = {};
  if (!e.device) errors.device = t("reportFraudDevice");
  if (!e.otherdevice) errors.otherdevice = t("reportFraudOtherDevice");
  if (!e.otherelate) errors.otherelate = t("reportFraudOtheRelate");
  if (!e.receivealert) errors.receivealert = t("reportFraudReceiveAlert");

  return errors as ReportFraudStep2Fields;
};
const ReportFraudStep3Validation = (
  e: ReportFraudStep3Fields,
  t: (key: string) => string
) => {
  const errors: Partial<ReportFraudStep3Fields> = {};
  if (!e.title) errors.title = t("reportFraudTitle");
  if (!e.firstname) errors.firstname = t("reportFraudFirstName");
  if (!e.lastname) errors.lastname = t("reportFraudLastName");
  if (!e.gender) errors.gender = t("reportFraudGender");
  if (!e.mobilenumber) errors.mobilenumber = t("reportFraudMobileNumber");
  if (!e.amemail) errors.amemail = t("reportFraudEmail");
  if (e.amemail && !emailValidation(e.amemail))
    errors.amemail = t("reportFraudEmailInvalid");

  return errors as ReportFraudStep3Fields;
};

const ReportErrorStep1Validation = (e: ReportErrorStep1Fields, t: any) => {
  const errors: Partial<ReportErrorStep1Fields> = {};
  if (!e.referenceprovided) errors.referenceprovided = t("reportFraudProvided");
  if (!e.otherdevice) errors.otherdevice = t("reportFraudOtherDevice");
  if (!e.reporttype) errors.reporttype = t("reportFraudType");
  if (!e.tellusdetails) errors.tellusdetails = t("reportFraudTellUs");
  if (!e.datetime) errors.datetime = t("reportFraudDateTime");
  if (!e.device) errors.device = t("reportFraudDevice");

  return errors as ReportErrorStep1Fields;
};

const ReportErrorStep2Validation = (
  e: ReportErrorStep2Fields,
  t: (key: string) => string
) => {
  const errors: Partial<ReportErrorStep2Fields> = {};
  if (!e.title) errors.title = t("createTitle");
  if (!e.firstname) errors.firstname = t("reportFraudFirstName");
  if (!e.lastname) errors.lastname = t("reportFraudLastName");
  if (!e.mobilenumber) errors.mobilenumber = t("reportFraudMobileNumber");
  if (!e.amemail) errors.amemail = t("reportFraudEmail");
  if (e.amemail && !emailValidation(e.amemail))
    errors.amemail = t("reportFraudEmailInvalid");

  return errors as ReportErrorStep2Fields;
};

const TableFormValidation = (e: TableFormField, t: (key: string) => string) => {
  const errors: Partial<TableFormField> = {};

  if (!e.name) errors.name = t("tableFormName");
  if (!e.age) errors.age = t("tableFormAge");
  if (!e.status) errors.status = t("tableFormStatus");
  if (!e.progress) errors.progress = t("tableFormFilledQuality");
  if (!e.email) errors.email = t("tableFormEmail");
  if (e.email && !emailValidation(e.email))
    errors.email = t("tableFormInvalid");

  return errors as TableFormField;
};

const ContactEmailFormValidation = (
  e: ContactEmailFormField,
  t: (key: string) => string
) => {
  const errors: Partial<ContactEmailFormField> = {};

  if (!e.alias) errors.alias = t("contactEmailName");
  if (!e.contactTypeApiName) errors.contactTypeApiName = t("contactEmailType");
  if (!e.emailAddress) errors.emailAddress = t("contactEmailForm");
  if (e.emailAddress && !emailValidation(e.emailAddress))
    errors.emailAddress = t("contactEmailInvalid");
  if (!e.confirmEmail) {
    errors.confirmEmail = t("contactEmailConfirmEmail");
  } else if (e.confirmEmail !== e.emailAddress) {
    errors.confirmEmail = t("contactEmailConfirmEmailMatch");
  }

  return errors as ContactEmailFormField;
};

const ContactPhoneFormValidation = (
  e: ContactPhoneFormField,
  t: (key: string) => string
) => {
  const errors: Partial<ContactPhoneFormField> = {};

  if (!e.alias) errors.alias = t("contactEmailName");
  if (!e.contactTypeApiName) errors.contactTypeApiName = t("contactEmailType");
  if (!e.userEditNumber) errors.userEditNumber = t("contactPhoneNumber");
  if (!e.phoneType) errors.phoneType = t("contactPhoneType");
  return errors as ContactPhoneFormField;
};

const ContactSocialMediaFormValidation = (
  e: ContactSocialMediaFormField,
  t: (key: string) => string
) => {
  const errors: Partial<ContactSocialMediaFormField> = {};

  if (!e.alias) errors.alias = t("contactEmailName");
  if (!e.contactTypeApiName) errors.contactTypeApiName = t("contactEmailType");
  if (!e.socialMediaTypeApiName)
    errors.socialMediaTypeApiName = t("contactSocialMediaType");
  if (!e.socialMediaAddress)
    errors.socialMediaAddress = t("contactSocialMediaValue");
  return errors as ContactSocialMediaFormField;
};

const ContactAddressFormValidation = (
  e: ContactAddressFormField,
  t: (key: string) => string
) => {
  const errors: Partial<ContactAddressFormField> = {};

  if (!e.contactTypeApiName)
    errors.contactTypeApiName = t("contactAddressType");
  if (!e.alias) errors.alias = t("contactAddressTypeName");
  if (!e.name) errors.name = t("contactEmailName");
  if (!e.addressLine1) errors.addressLine1 = t("contactAddressLine1");
  if (!e.postcode) errors.postcode = t("contactAddressPostcode");
  if (!e.city) errors.city = t("contactAddressCity");

  return errors as ContactAddressFormField;
};

const ContactPhoneFaxValidation = (
  e: ContactFaxFormField,
  t: (key: string) => string
) => {
  const errors: Partial<ContactFaxFormField> = {};

  if (!e.alias) errors.alias = t("contactEmailName");
  if (!e.contactTypeApiName) errors.contactTypeApiName = t("contactEmailType");
  if (!e.userEditNumber) errors.userEditNumber = t("contactPhoneNumber");
  return errors as ContactFaxFormField;
};

const BankDetailsStep1FormValidation = (
  e: BankDetailsStep1Field,
  t: (key: string) => string
) => {
  const errors: Partial<BankDetailsStep1Field> = {};

  if (!e.bankTypeApiName) errors.bankTypeApiName = t("paymentBankType");
  if (!e.alias) errors.alias = t("paymentBankAddressTypeName");
  if (!e.accountName) errors.accountName = t("paymentBankAccountName");
  if (!e.accountNumber) errors.accountNumber = t("paymentBankAccountNumber");
  if (!e.sortCode) errors.sortCode = t("paymentBankSortCode");
  if (!e.iban) errors.iban = t("paymentBankIban");
  if (!e.swiftCode) errors.swiftCode = t("paymentBankSwiftCode");
  if (!e.accountCurrency)
    errors.accountCurrency = t("paymentBankAccountCurrency");

  return errors as BankDetailsStep1Field;
};

const BankDetailsStep2FormValidation = (
  e: BankDetailsStep2Field,
  t: (key: string) => string
) => {
  const errors: Partial<BankDetailsStep2Field> = {};

  if (!e.bankName) errors.bankName = t("paymentBankName");
  if (!e.bankBranchName) errors.bankBranchName = t("paymentBankBranchName");
  if (!e.bankAddressLine1)
    errors.bankAddressLine1 = t("paymentBankAddressLine1");
  if (!e.postcode) errors.postcode = t("paymentBankPostcode");
  if (!e.bankCity) errors.bankCity = t("paymentBankCity");
  if (e.bankEmail) {
    if (!e.bankEmail) errors.bankEmail = t("paymentBankEmailForm");
    if (e.bankEmail && !emailValidation(e.bankEmail))
      errors.bankEmail = t("paymentBankEmailInvalid");
    if (!e.bankConfirmEmail) {
      errors.bankConfirmEmail = t("paymentBankEmailConfirmEmail");
    } else if (e.bankConfirmEmail !== e.bankEmail) {
      errors.bankConfirmEmail = t("paymentBankEmailConfirmEmailMatch");
    }
  }

  return errors as BankDetailsStep2Field;
};

const BankDetailsStep3FormValidation = (
  e: BankDetailsStep3Field,
  t: (key: string) => string
) => {
  const errors: Partial<BankDetailsStep3Field> = {};

  if (e.beneficiaryEmail) {
    if (!e.beneficiaryEmail)
      errors.beneficiaryEmail = t("paymentBankEmailForm");
    if (e.beneficiaryEmail && !emailValidation(e.beneficiaryEmail))
      errors.beneficiaryEmail = t("paymentBankEmailInvalid");
    if (!e.beneficiaryConfirmEmail) {
      errors.beneficiaryConfirmEmail = t("paymentBankEmailConfirmEmail");
    } else if (e.beneficiaryConfirmEmail !== e.beneficiaryEmail) {
      errors.beneficiaryConfirmEmail = t("paymentBankEmailConfirmEmailMatch");
    }
  }

  return errors as BankDetailsStep3Field;
};

const MediaChannelFormStep1Validation = (
  e: MediaChannelFormStep1,
  t: (key: string) => string
) => {
  const errors: Partial<MediaChannelFormStep1> = {};

  if (!e.userMCTypeApiName)
    errors.userMCTypeApiName = t("mediaChannelTypeFieldError");
  return errors as MediaChannelFormStep1;
};

const MediaChannelFormStep3Validation = (
  e: MediaChannelFormStep3,
  t: (key: string) => string
) => {
  const errors: Partial<MediaChannelFormStep3> = {};

  if (!e.userMCProfileName)
    errors.userMCProfileName = t("mediaChannelNameFieldError");
  if (!e.userMCProfileDescription)
    errors.userMCProfileDescription = t("mediaChannelDescriptioneFieldError");
  return errors as MediaChannelFormStep3;
};

const PreferredMediaFormStep2Validation = (
  e: PreferredMediaChannelFormStep2,
  t: (key: string) => string
) => {
  const errors: Partial<PreferredMediaChannelFormStep2> = {};

  if (!e.name) errors.name = t("preferredMediaNameFieldError");
  if (!e.description)
    errors.description = t("preferredMediaDescriptioneFieldError");
  if (!e.mcTypeApiName)
    errors.mcTypeApiName = t("preferredMediaMcTypeApiNameFieldError");
  if (!e.mcUrl) errors.mcUrl = t("preferredMediaMcUrlFieldError");
  if (!e.mcEMail) errors.mcEMail = t("preferredMediaMcEMailFieldError");
  if (e.mcEMail && !emailValidation(e.mcEMail))
    errors.mcEMail = t("preferredMediaMcEMailFieldInvalidError");
  if (!e.mcPhone) errors.mcPhone = t("preferredMediaMcPhoneFieldError");
  if (!e.mcContactDetails)
    errors.mcContactDetails = t("preferredMediaMcContactDetailsFieldError");

  return errors as PreferredMediaChannelFormStep2;
};

const CustomMediaFormStep2Validation = (
  e: CustomMediaChannelFormStep2,
  t: (key: string) => string
) => {
  const errors: Partial<CustomMediaChannelFormStep2> = {};

  if (!e.name) errors.name = t("preferredMediaNameFieldError");
  if (!e.description)
    errors.description = t("preferredMediaDescriptioneFieldError");
  if (!e.mcTypeApiName)
    errors.mcTypeApiName = t("preferredMediaMcTypeApiNameFieldError");
  return errors as CustomMediaChannelFormStep2;
};

const AdvertiserClientFormStep1Validation = (
  e: AdvertiserClientFormStep1Field,
  t: (key: string) => string
) => {
  const errors: Partial<AdvertiserClientFormStep1Field> = {};

  if (!e.name) errors.name = t("NameFieldError");
  if (!e.description) errors.description = t("DescriptioneFieldError");
  if (!e.companyName) errors.companyName = t("CompanyNameFieldError");
  return errors as AdvertiserClientFormStep1Field;
};

const AdvertiserProjectFormStep1Validation = (
  e: AdvertiserProjectFormStep1Field,
  t: (key: string) => string
) => {
  const errors: Partial<AdvertiserProjectFormStep1Field> = {};

  if (!e.name) errors.name = t("NameFieldError");
  if (!e.description) errors.description = t("DescriptioneFieldError");
  return errors as AdvertiserProjectFormStep1Field;
};

const AdvertiserProjectFormStep2Validation = (
  e: AdvertiserProjectFormStep2Field,
  t: (key: string) => string
) => {
  const errors: Partial<AdvertiserProjectFormStep2Field> = {};

  if (!e.mcAnalyticsCategoryApiName)
    errors.mcAnalyticsCategoryApiName = t("audienceInterestCategoryError");
  if (!e.mcAnalyticsVariableApiName)
    errors.mcAnalyticsVariableApiName = t("audienceInterestTypeError");

  return errors as AdvertiserProjectFormStep2Field;
};

const AdvPublishingCustomOfferFormValidation = (
  e: AdvPublisingCustomOffersFormField,
  t: (key: string) => string
) => {
  const errors: Partial<AdvPublisingCustomOffersFormField> = {};

  if (!e.name) errors.name = t("NameFieldError");
  return errors as AdvPublisingCustomOffersFormField;
};

const AdvertiserCampaignFormStep1Validation = (
  e: AdvertiserCampaignFormStep1Field,
  t: (key: string) => string
) => {
  const errors: Partial<AdvertiserCampaignFormStep1Field> = {};

  if (!e.name) errors.name = t("NameFieldError");
  if (!e.description) errors.description = t("DescriptioneFieldError");
  if (!e.campaignType) errors.campaignType = t("CompanyTypeFieldError");
  if (!e.campaignTopic) errors.campaignTopic = t("CompanyTopicFieldError");
  return errors as AdvertiserCampaignFormStep1Field;
};

const AdvertiserCampaignFormStep2Validation = (
  e: AdvertiserCampaignPublicationField,
  t: (key: string) => string
) => {
  const errors: Partial<AdvertiserCampaignPublicationField> = {};

  if (!e.name) errors.name = t("NameFieldError");
  if (!e.description) errors.description = t("DescriptioneFieldError");
  if (!e.timeZone) errors.timeZone = t("timezoneFieldError");
  if (!e.startTimestamp) errors.startTimestamp = t("starttimeFieldError");
  if (!e.endTimestamp) errors.endTimestamp = t("endtimeFieldError");

  return errors as AdvertiserCampaignPublicationField;
};

const MediaChannelAddForm1Validation = (
  e: MediaChannelAddFormStep1,
  t: (key: string) => string
) => {
  const errors: Partial<MediaChannelAddFormStep1> = {};

  if (!e.name) errors.name = t("mediaChannelTypeFieldError");
  return errors as MediaChannelAddFormStep1;
};

const preferredMediaChannelAddFormValidation = (
  e: PreferredMediaChannelAddForm,
  t: (key: string) => string
) => {
  const errors: Partial<PreferredMediaChannelAddForm> = {};

  if (!e.mcTypeApiName) errors.mcTypeApiName = t("mcTypeError");
  if (!e.name) errors.name = t("mcProfileNameError");

  return errors as PreferredMediaChannelAddForm;
};

const emailValidation = (email: string): boolean => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export {
  SignInValidation,
  CreateAccountValidation,
  CreateAccountStep2Validation,
  ResetPasswordValidation,
  ForgotPasswordValidation,
  ReportFraudStep1Validation,
  ReportFraudStep2Validation,
  ReportFraudStep3Validation,
  ReportErrorStep1Validation,
  ReportErrorStep2Validation,
  TableFormValidation,
  ContactEmailFormValidation,
  ContactPhoneFormValidation,
  ContactSocialMediaFormValidation,
  ContactAddressFormValidation,
  ContactPhoneFaxValidation,
  BankDetailsStep1FormValidation,
  BankDetailsStep2FormValidation,
  BankDetailsStep3FormValidation,
  MediaChannelFormStep1Validation,
  MediaChannelFormStep3Validation,
  PreferredMediaFormStep2Validation,
  CustomMediaFormStep2Validation,
  AdvertiserClientFormStep1Validation,
  AdvertiserProjectFormStep1Validation,
  AdvertiserProjectFormStep2Validation,
  AdvPublishingCustomOfferFormValidation,
  AdvertiserCampaignFormStep1Validation,
  AdvertiserCampaignFormStep2Validation,
  MediaChannelAddForm1Validation,
  preferredMediaChannelAddFormValidation,
};
