import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const TableStyles = {
  root: {
    ".MuiTableCell-root": {
      fontFamily: textStyles.fontFamily.defaultFont,
      fontSize: textStyles.fontSize.large,
      lineHeight: textStyles.lineHeight.maxLarge,
      padding: uiConfig.AMBtnReducePadding,
      minHeight: uiConfig.AM56,
      maxHeight: uiConfig.AM56,
      textAlign: textStyles.textAlign.left,
    },

    ".paginator-perpage": {
      justifyContent: textStyles.justifyContent.spaceBetween,
      borderTop: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.tableBorder}`,
      ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
        margin: uiConfig.AM0,
      },
      ".MuiToolbar-root": {
        padding: uiConfig.AM0,
        ".MuiButtonBase-root": {
          padding: uiConfig.AM0,
          color: colors.primaryDarkColor,
          "&:hover": {
            backgroundColor: colors.secondaryContrastColor,
          },
          "&.Mui-disabled": {
            color: colors.disableClr,
          },
        },
      },
      ".MuiTablePagination-root": {
        display: textStyles.display.inlineBlock,
        width: uiConfig.AMAuto,
        overflow: textStyles.overflow.hidden,
        ".MuiTablePagination-spacer": {
          display: uiConfig.AMNone,
        },
      },
      ".MuiFormControl-root": {
        display: textStyles.display.inlineFlex,
        gap: uiConfig.AM10,
        border: uiConfig.AM0,
        alignItems: textStyles.alignItems.center,
        width: uiConfig.AM50per,
        flexDirection: uiConfig.AMInherit,
        margin: uiConfig.AM0,
      },
      ".MuiFormLabel-root": {
        position: uiConfig.AMInherit,
        textOverflow: uiConfig.AMInitial,
        whiteSpace: `${uiConfig.AMNowrap} !important`,
        overflow: uiConfig.AMInherit,
        fontSize: `${textStyles.fontSize.base} !important`,
        fontFamily: textStyles.fontFamily.buttonFont,
        color: colors.blackColor,
        fontWeight: textStyles.fontWeight.normal,
        flexShrink: uiConfig.AM0,
        transform: uiConfig.AMNone,
      },
      ".MuiInputBase-root": {
        width: uiConfig.AMAuto,
        border: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.infoCardBr} !important`,
        borderRadius: uiConfig.AM5,
        boxShadow: uiConfig.AMBreadcrumbsShadow,
        display: textStyles.display.inlineFlex,
        alignItems: textStyles.alignItems.center,
        position: uiConfig.AMInherit,
        left: uiConfig.AM0,
        right: uiConfig.AM0,
        bottom: uiConfig.AM0,
        margin: uiConfig.AM0,
        padding: uiConfig.AMDrwopDownBtn,
        ".MuiSelect-select": {
          minHeight: uiConfig.AMAuto,
          textAlign: textStyles.textAlign.left,
          textAlignLast: textStyles.textAlign.left,
          background: `${colors.transparent} !important`,
        },
      },
    },
  },
};
