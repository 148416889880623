import React, { ReactNode } from "react";
import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { uiConfig } from "designsystem";

interface ContainerProps {
  children: ReactNode;
}
const AMContainer = ({ children }: Readonly<ContainerProps>) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        width: uiConfig.AM678,
        [theme.breakpoints.down("lg")]: {
          width: uiConfig.AMfull,
        },
      }}
    >
      {children}
    </Container>
  );
};

export default AMContainer;
