import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { Card, MenuItem, MenuList } from "@mui/material";

interface carouselItem {
  title: string;
  isContent: ReactNode | string;
}

interface AMAnalyticsTabProps {
  carouselData: carouselItem[];
  setSelectedTypeApiName: Dispatch<SetStateAction<any>>;
}

const AMAnalyticsTab = ({
  carouselData,
  setSelectedTypeApiName,
}: Readonly<AMAnalyticsTabProps>) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleMenuItemClick = (index: number, title: string) => {
    setActiveIndex(index);
    setSelectedTypeApiName(title);
  };
  return (
    <section>
      <MenuList className="about-filter-wrp" component="div">
        <MenuList className="about-filter-inner scroller">
          {carouselData.map((carousel: any, index: number) => (
            <MenuItem
              key={index}
              onClick={() => handleMenuItemClick(index, carousel.title)}
              className={index === activeIndex ? "active" : ""}
            >
              {carousel.title}
            </MenuItem>
          ))}
        </MenuList>
      </MenuList>

      <Card
        elevation={0}
        className="totallayersection secloader-wrp remove-height-card media-abouttitle"
      >
        {carouselData.map(
          (carousel: any, index: number) =>
            index === activeIndex && (
              <div key={index}>{carousel?.isContent}</div>
            )
        )}
      </Card>
    </section>
  );
};

export default AMAnalyticsTab;
