import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  headers: {
    "Content-Type": "text/plain",
    Accept: "application/json",
  },
});

export default axiosInstance;
