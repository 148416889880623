import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const PopoverStyles = {
  root: {
    "& .MuiMenuItem-root": {
      fontSize: textStyles.fontSize.large,
      color: `${colors.inputColor} !important`,
      fontWeight: textStyles.fontWeight.strong,
    },

    "&.popover-wrp": {
      ".MuiPaper-root": {
        fontFamily: textStyles.fontFamily.defaultFont,
      },
      ".filter-popup-wrp ": {
        padding: "0px",
        gap: "0px",
        ".MuiButtonBase-root.MuiAccordionSummary-root": {
          background: `${colors.menuBgColor} !important`,
          padding: "5px 10px",
        },
        ".MuiAccordionDetails-root": {
          padding: "10px",
        },
      },
    },
    "&.filtered-list-view": {
      ".filtered-list-wrp": {
        width: "350px",
        maxHeight: "calc(100vh - 300px)",
        padding: 0,
        ".filter-category-wrp": {
          padding: "10px",
          background: `${colors.menuBgColor} !important`,
        },
      },
      ".filtered-chip-items": {
        gap: "10px",
        flexWrap: "wrap",
        marginBottom: "10px",
      },
    },
    "&.popover-wrp.userCount": {
      ".MuiPaper-root": {
        padding: uiConfig.AM10,
        boxShadow: uiConfig.AMBreadcrumbsShadow,
        borderRadius: uiConfig.AM15,
        width: "250px",
        maxHeight: "200px",
        ".MuiList-root": {
          padding: 0,
          ".MuiListItem-root": {
            padding: "2px 10px",
            "&:hover": {
              background: colors.secondaryDarkColor,
              borderRadius: "8px",
              color: colors.primaryDarkColor,
              cursor: "pointer",
            },
          },
        },
      },
    },
  },
};
