import React, { ReactNode } from "react";
import { Card, CardContent, CardActions, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { uiConfig } from "designsystem";

interface PopoverCardProps {
  title: string;
  icon?: ReactNode;
  children?: ReactNode;
  btnFooter?: ReactNode;
  closeTogglePopover?: () => void;
}

const AMPopoverCard = ({
  title,
  icon,
  children,
  btnFooter,
  closeTogglePopover,
}: Readonly<PopoverCardProps>) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        bgcolor: "transparent",
        maxWidth: 310,
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
      }}
    >
      <CardContent
        sx={{
          padding: "10px 10px 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "transparent",
        }}
      >
        {/* TITLE SECTION STARTS HERE */}
        <Box display="flex" alignItems="center" gap="6px"
          sx={{
            typography: "h2",
            [theme.breakpoints.down("lg")]: {
              marginBottom: "12px",
            },
          }}
        >
          {icon}
          {title}
        </Box>
        {/* TITLE SECTION ENDS HERE */}
        {/* WEB VIEW CLOSE SECTION STARTS HERE */}
        <Close
          sx={{
            color: "primary.light",
            width: "18px",
            marginLeft: uiConfig.AM5,
            cursor: "pointer",
            [theme.breakpoints.down("lg")]: {
              display: "none",
            },
          }}
          className="globeimg"
          onClick={closeTogglePopover}
        />
        {/* WEB VIEW CLOSE SECTION ENDS HERE */}
      </CardContent>

      {children}

      {/* BUTTON STARTS HERE */}
      {btnFooter && (
        <CardActions
          sx={{
            bgcolor: "#F9F3E6",
            justifyContent: "flex-end",
            padding: "7px 10px",
          }}
        >
          {btnFooter}
        </CardActions>
      )}
      {/* BUTTON ENDS HERE */}
    </Card>
  );
};

export default AMPopoverCard;
