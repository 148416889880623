import React, { CSSProperties } from "react";
import { Badge, CardActions } from "@mui/material";
import { NotificationAddOutlined } from "@mui/icons-material";
import { uiConfig } from "designsystem";

interface BadgeNotificationProps {
  smallBadge?: boolean;
  totalCount?: number | string;
  style?: CSSProperties;
}

const AMBadgeNotification = ({
  smallBadge,
  style,
  totalCount,
}: Readonly<BadgeNotificationProps>) => {
  return (
    <CardActions
      sx={{
        ...style,
        padding: `${uiConfig.AM0} !important`,
      }}
    >
      {smallBadge ? (
        <Badge badgeContent={totalCount} color="error">
          <NotificationAddOutlined
            sx={{
              width: uiConfig.AM20,
              marginLeft: uiConfig.AM6,
            }}
          />
        </Badge>
      ) : (
        <Badge badgeContent={totalCount} color="error">
          <NotificationAddOutlined />
        </Badge>
      )}
    </CardActions>
  );
};

export default AMBadgeNotification;
