import React from "react";

import { MainLayoutPage } from "views/layout";

const LayoutController = () => {
  return (
    <div>
      <MainLayoutPage />
    </div>
  );
};

export default LayoutController;
