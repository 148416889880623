import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ReactNode } from "react";
import * as colors from "designsystem/Palette";

interface TabData {
  label: string;
  count?: string;
  content: ReactNode;
}

interface AMFilterTabProps {
  tabs: TabData[];
}

const AMFilterTab: React.FC<AMFilterTabProps> = ({ tabs }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: "calc(100vh - 300px)"
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          width: "250px",
          ".MuiButtonBase-root": {
            margin: "0!important",
            paddingInline: "10px!important",
            paddingBlock: "10px!important",
            alignItems: "center",
            "&.Mui-selected": {
              backgroundColor: "secondary.dark",
              color: `${colors.primaryDarkColor}!important`,
            },
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            icon={<div className="tab-count">{tab.count}</div>}
            iconPosition="end"
            sx={{
              justifyContent: "space-between",
              ".tab-count": {
                width: "20px",
                height: "20px",
                borderRadius: "50px",
                backgroundColor: `${colors.primaryDarkColor}!important`,
                color: `${colors.whiteColor}!important`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:empty": {
                  display: "none",
                },
              },
            }}
          />
        ))}
      </Tabs>
      <Box sx={{ p: 1, width: "100%" }}>
        {tabs.map((tab, index) => value === index && <div>{tab.content}</div>)}
      </Box>
    </Box>
  );
};

export default AMFilterTab;
