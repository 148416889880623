import React from "react";
import { Select, MenuItem } from "@mui/material";
import { FieldRenderProps } from "react-final-form";
import AMLabel from "./AM_Label";

interface AMSelectOption {
  label: string;
  value: string;
}

interface SelectProps
  extends FieldRenderProps<string, HTMLElement, HTMLInputElement> {
  label: string;
  value: string;
  placeholder: string;
  inputtype?: AMSelectOption[];
  required?: boolean;
  selectOption: (event: React.ChangeEvent<{ value: unknown }>) => void;
  input: any;
  isDisabled?: boolean;
}

const AMSelect = ({
  input,
  selectOption,
  label,
  placeholder,
  required,
  value,
  inputtype = [],
  isDisabled,
}: Readonly<SelectProps>) => {
  return (
    <div className="component-select-wrp">
      <AMLabel label={label} required={required} />

      <Select
        {...input}
        label={label}
        value={value}
        displayEmpty
        onChange={selectOption}
        renderValue={value !== "" ? undefined : () => placeholder}
        disabled={isDisabled}
      >
        {inputtype.map((option, index) => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default AMSelect;
