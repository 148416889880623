import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const SelectStyles = {
  root: {
    width: uiConfig.AMfull,
    fontSize: uiConfig.AM18,
    color: `${colors.inputColor} !important`,
    borderRadius: uiConfig.AM0,
    borderBottom: `${uiConfig.AM1} ${textStyles.border.solid}`,
    borderColor: `${colors.datepickerBorder} !important`,
    padding: uiConfig.AMDateTimePicker,
  },
};
