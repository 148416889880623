import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const FormGroupStyles = {
  root: {
    color: colors.secondaryLightColor,
    lineHeight: uiConfig.AM26,
    overflow: uiConfig.AMInherit,
    transform: uiConfig.AMNone,
  },
};
