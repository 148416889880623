import React, { useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { IconButton } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { Dayjs } from "dayjs";
import AMLabel from "./AM_Label";
import { uiConfig } from "designsystem";
import { DateTimePickerProps } from "@mui/lab/DateTimePicker";
import { Field } from "react-final-form";
import AMErrorText from "./AM_ErrorText";

interface AMDateTimePickerProps {
  name: string;
  label?: string;
  placeholder: string;
  required?: boolean;
  value: Dayjs | null;
  selectDatePicker: DateTimePickerProps<Dayjs>["onChange"];
  error?: string; // For handling any additional error passed down
}

const AMDateTimePicker = ({
  name,
  label,
  placeholder,
  required,
  selectDatePicker,
  value,
  error, // Optional error prop
}: Readonly<AMDateTimePickerProps>) => {
  const [selectedDateTime, setSelectedDateTime] = useState<Dayjs | null>(null);

  // Handler for confirm button click
  const handleConfirm = (newValue: Dayjs | null) => {
    setSelectedDateTime(newValue);
    if (selectDatePicker) {
      selectDatePicker(newValue);
    }
  };

  // Handler for clear button click
  const handleClear = () => {
    setSelectedDateTime(null);
    if (selectDatePicker) {
      selectDatePicker(null);
    }
  };

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <div className="customclose">
          <AMLabel label={label} required={required} />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              {...input}
              value={value}
              onChange={(newValue) => {
                setSelectedDateTime(newValue);
                input.onChange(newValue); // Notify form library about the change
              }}
              name="datetime"
              slotProps={{
                textField: { placeholder: placeholder },
                field: { clearable: true },
              }}
              onClose={() => handleConfirm(selectedDateTime)}
              sx={{
                borderBottom: uiConfig.AM0,
                borderRadius: uiConfig.AM0,
                ".MuiInputBase-input": {
                  padding: `${uiConfig.AM0} !important`,
                },
              }}
            />
            {value && (
              <IconButton
                onClick={handleClear}
                edge="end"
                className="closeicon"
              >
                <ClearIcon />
              </IconButton>
            )}
          </LocalizationProvider>
          {/* Error message handling */}
          {(meta.touched && meta.error) || error ? (
            <AMErrorText value={meta.error || error} />
          ) : null}
        </div>
      )}
    </Field>
  );
};

export default AMDateTimePicker;
