import * as colors from "designsystem/Palette";
import { textStyles, uiConfig } from "designsystem";

export const DataGridStyles = {
  root: {
    background: colors.transparent,
    border: uiConfig.AM0,
    fontFamily: textStyles.fontFamily.defaultFont,
    fontSize: textStyles.fontSize.large,

    ".project-client-wrp": {
      padding: uiConfig.AM0,
      cursor: "pointer",
      ".MuiListItemAvatar-root": {
        width: "40px",
        height: "40px",
      },
      ".MuiListItemText-root": {
        margin: 0,
        ".MuiTypography-body1": {
          border: 0,
          lineHeight: "20px",
        },
        ".MuiTypography-body2": {
          margin: 0,
          fontFamily: textStyles.fontFamily.defaultFont,
        },
      },
    },

    ".MuiCheckbox-root": {
      backgroundColor: colors.transparent,
      padding: uiConfig.AM0,
      ".MuiSvgIcon-root": {
        width: uiConfig.AM20,
        height: uiConfig.AM20,
      },
    },
    ".MuiRadio-root": {
      padding: uiConfig.AM0,
      ".MuiSvgIcon-root": {
        width: uiConfig.AM20,
        height: uiConfig.AM20,
      },
      "&.Mui-checked": {
        ".MuiSvgIcon-root": {
          fill: colors.primaryDarkColor,
        },
      },
    },

    "&.removeheader": {
      margin: uiConfig.AM0,
      ".MuiDataGrid-columnHeaders": {
        display: textStyles.display.none,
      },
    },
    ".MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell": {
      borderColor: `${colors.tableBorder} !important`,
    },
    ".MuiDataGrid-toolbarContainer": {
      padding: `${uiConfig.AM10} ${uiConfig.AM16}`,
      borderBottom: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.infoCardBr}`,
      justifyContent: textStyles.justifyContent.spaceBetween,
    },
    ".MuiDataGrid-columnSeparator": {
      display: textStyles.display.none,
    },
    ".MuiDataGrid-menuIcon": {
      ".MuiButtonBase-root": {
        backgroundColor: colors.transparent,
        padding: uiConfig.AM0,
      },
    },
    ".MuiDataGrid-iconButtonContainer": {
      ".MuiButtonBase-root": {
        backgroundColor: colors.transparent,
        padding: uiConfig.AM0,
        ".MuiSvgIcon-root": {
          width: uiConfig.AM18,
          height: uiConfig.AM18,
          marginLeft: uiConfig.AM10,
        },
      },
    },

    ".MuiDataGrid-footerContainer": {
      border: uiConfig.AM0,
      display: textStyles.display.none,
    },
    ".MuiDataGrid-row": {
      minHeight: `${uiConfig.AMAuto} !important`,
      maxHeight: `${uiConfig.AMNone} !important`,
    },
    ".MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
      outline: `${uiConfig.AMNone} !important`,
      whiteSpace: `${uiConfig.AMBreakSpaces} !important`,
      minHeight: `${uiConfig.AMAuto} !important`,
      maxHeight: `${uiConfig.AMNone} !important`,
      padding: uiConfig.AM10,
      ".MuiButtonBase-root.MuiCheckbox-root": {
        padding: uiConfig.AM0,
        "&.Mui-checked": {
          ".MuiSvgIcon-root": {
            color: `${colors.primaryDarkColor} !important`,
          },
        },
      },
      ".MuiCheckbox-root .MuiSvgIcon-root": {
        color: "#c2c2c2",
      },
    },
    ".MuiDataGrid-row:hover": {
      background: `${colors.secondaryDarkColor}!important`,
    },
    ".MuiDataGrid-row:nth-of-type(odd)": {
      background: colors.whiteColor,
    },
    ".MuiDataGrid-row:nth-of-type(even)": {
      background: colors.secondaryDarkColor,
    },
    ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
      margin: uiConfig.AM0,
    },
    ".MuiTablePagination-displayedRows": {
      position: textStyles.position.absolute,
      right: uiConfig.AM90,
    },
    ".MuiTablePagination-actions": {
      position: textStyles.position.absolute,
      right: uiConfig.AM10,
    },
    ".MuiToolbar-root .MuiButtonBase-root": {
      padding: uiConfig.AM0,
      color: colors.primaryDarkColor,
      "&:hover": {
        backgroundColor: colors.secondaryContrastColor,
      },
      "&.Mui-disabled": {
        color: colors.disableClr,
      },
    },
    ".MuiTablePagination-root": {
      display: textStyles.display.inlineBlock,
      width: uiConfig.AMfull,
      overflow: textStyles.overflow.hidden,
      ".MuiInputBase-root": {
        width: uiConfig.AMAuto,
        border: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.infoCardBr} !important`,
        borderRadius: uiConfig.AM5,
        boxShadow: uiConfig.AMBreadcrumbsShadow,
        display: textStyles.display.inlineFlex,
        alignItems: textStyles.alignItems.center,
        ".MuiSelect-select": {
          minHeight: uiConfig.AMAuto,
          textAlign: textStyles.textAlign.left,
          textAlignLast: textStyles.textAlign.left,
        },
      },
      ".MuiTablePagination-spacer": {
        display: textStyles.display.none,
      },
    },
  },
};
