import React, { useCallback, useState } from "react";

import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment } from "@mui/material";
import { Close } from "@mui/icons-material";

interface SearchProps {
  onChangeSearchValue: (value: string) => void;
}

const AMSearch = ({ onChangeSearchValue }: Readonly<SearchProps>) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event: { target: { value: string } }) => {
    const searchQuery = event.target.value;
    setSearchValue(searchQuery);
  };

  const handleSearchClick = () => {
    memoizedOnChangeSearchValue(searchValue);
  };
  const handleClearClick = () => {
    setSearchValue("");
    memoizedOnChangeSearchValue("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      memoizedOnChangeSearchValue(searchValue);
    }
  };

  const memoizedOnChangeSearchValue = useCallback(
    (value: string) => {
      onChangeSearchValue(value);
    },
    [onChangeSearchValue]
  );

  return (
    <TextField
      onChange={handleSearchChange}
      value={searchValue}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleClearClick}>
              {searchValue !== "" && <Close />}
            </IconButton>
            <IconButton
              onClick={handleSearchClick}
              sx={{ marginRight: "-15px" }}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default AMSearch;
