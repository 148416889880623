import { AMErrorText, AMInput, AMSelect } from "components/common";
import React, { ChangeEvent } from "react";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";

interface FormFieldProps {
  name: string;
  label: string;
  placeholder: string;
  type?: string;
  options?: any[];
  value?: any;
  selectOption?: (event: ChangeEvent<{ value: unknown }>) => void;
  error?: string;
  required?: boolean;
  autoComplete?: string;
  formType: string;
  isDisabled?: boolean;
  onChange?: (name: string, value: string) => void; // Optional prop
}

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  placeholder,
  type = "text",
  options,
  value,
  selectOption,
  error,
  required,
  autoComplete,
  formType,
  isDisabled,
  onChange, // Optional prop
}) => {
  const errorTrans = useTranslation("gen-error-message").t;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(name, e.target.value);
    }
  };

  return (
    <Field name={name} defaultValue={value} initialValue={value}>
      {({ input, meta }) => {
        const inputProps = {
          ...input,
          placeholder,
          type,
          autoComplete,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            input.onChange(e);
            handleChange(e);
          },
        };

        return (
          <div>
            {formType === "select" && selectOption && (
              <AMSelect
                input={inputProps}
                meta={meta}
                required={required}
                label={label}
                placeholder={placeholder}
                inputtype={options}
                value={value}
                selectOption={selectOption}
                isDisabled={isDisabled}
              />
            )}
            {formType === "input" && (
              <AMInput required={required} label={label} input={inputProps} isDisabled={isDisabled}/>
            )}
            {(meta.touched && meta.error) || error ? (
              <AMErrorText value={meta.error || errorTrans(error ?? "")} />
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

export default FormField;
