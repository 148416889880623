const TextStyles = {
  fontFamily: {
    titleFont: "'Gravitas One', sans-serif",
    defaultFont: "'Harmattan', sans-serif",
    buttonFont: "'Bayon', sans-serif",
  },
  fontSize: {
    macro: 7,
    ms10: 10,
    small: 12,
    ms13: 13,
    medium: 14,
    ms15: 15,
    base: 16,
    large: 18,
    extraLarge: 20,
    heading: 24,
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semibold: 600,
    strong: 700,
  },
  lineHeight: {
    macro: "16px",
    small: "21px",
    medium: "24px",
    base: "26px",
    large: "40px",
    maxLarge: "56px",
  },
  letterSpacing: {
    macro: "0.4px",
  },
  transform: {
    uppercase: "uppercase",
    capitalize: "capitalize",
    sliderotate: "rotate(180deg)",
  },
  display: {
    grid: "grid",
    inlineBlock: "inline-block",
    flex: "flex",
    block: "block",
    inlineFlex: "inline-flex",
    none: "none",
  },
  textAlign: {
    left: "left",
    center: "center",
    right: "center",
  },
  alignItems: {
    center: "center",
    flexStart: "flex-start",
  },
  justifyContent: {
    spaceAround: "space-around",
    spaceBetween: "space-between",
    flexEnd: "flex-end",
    center: "center",
  },
  flexDirection: {
    column: "column",
  },
  position: {
    relative: "relative",
    absolute: "absolute",
    sticky: "sticky",
  },
  border: {
    solid: "solid",
  },
  cursor: {
    pointer: "pointer",
    notAllowed: "not-allowed",
  },
  overflow: {
    hidden: "hidden",
    visible: "visible",
  },
  zIndex: {
    Z1100: "1100",
    Z2: "2",
  },
};

export default TextStyles;
