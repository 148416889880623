import React, { useState, createContext, FC, useContext, useMemo, useEffect } from "react";
import { CommonDataModel } from "../models";
import UserModel, {
  userDataModelDefaultValues,
} from "../models/user/UserModel";
import { commonDataModelDefaultValues } from "../models/CommonDataModel";

const CommonContext = createContext<CommonDataModel>(
  commonDataModelDefaultValues
);

export const useContextCommonDataModel = () => {
  return useContext(CommonContext);
};

export const CommonContextProvider: FC = ({ children }) => {
  const [me, setMe] = useState<UserModel>(() => {
    const isUserExit = localStorage.getItem("user");
    return isUserExit ? JSON.parse(isUserExit).me : userDataModelDefaultValues;
  });
  const [authenticated, setAuthenticated] = useState<boolean>(() => {
    const isUserExit = localStorage.getItem("user");
    return isUserExit ? JSON.parse(isUserExit).authenticated : false;
  });
  const [token, setToken] = useState<string>(() => {
    const isUserExit = localStorage.getItem("user");
    return isUserExit ? JSON.parse(isUserExit).token : "";
  });

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify({ me, authenticated, token }));
  }, [me, authenticated, token]);

  const PushRouteInLocalStorage = (pathname: string) => {
    let routes =
      localStorage.getItem("breadcrumbs") != null
        ? JSON.parse(localStorage.getItem("breadcrumbs") ?? "[]")
        : [];
    let index = routes.indexOf(pathname);
    if (index !== -1) {
      routes.splice(index + 1);
    } else {
      routes.push(pathname);
    }
    localStorage.setItem("breadcrumbs", JSON.stringify(routes));
  };

  const memoizedValue = useMemo(
    () => ({
      me,
      authenticated,
      token,
      updateUserDataModel: (newUserDataModel: UserModel) => {
        setMe(newUserDataModel);
        setAuthenticated(newUserDataModel.authenticated)
        setToken(newUserDataModel.token)
      },
      pushRouteInLocalStorage: (location: string) =>
        PushRouteInLocalStorage(location),
    }),
    [me, authenticated, token]
  );

  return (
    <CommonContext.Provider value={memoizedValue}>
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
