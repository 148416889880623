import React, { useState } from "react";
import { Box, Card, CardContent, MenuItem, MenuList } from "@mui/material";
import {
  AMCarouselBox,
  AMCarouselBoxContainer,
  AMCarouselBoxCard,
  AMInfoCard,
} from "components/widgets";
import { AMConfirmationPopup, AMNotFound } from "components/common";
import { ADD } from "utils/CommonText";
import AMFilterCarouselItem from "./AM_FilterCarouselItem";
import { useTranslation } from "react-i18next";

interface AMFilterCarouselProps {
  addroute: (rowData: string) => void;
  carouselData: any;
  handleDelete: (type: string, rowData: any) => void;
  isOpenPopupHandle: boolean;
  slidesToShow?: number;
  closePopupHandle: () => void;
  handleDeleteRow?: { type: string; rowData: any };
  isOfferCarousel?: boolean;
  handlePublicationDelete?: (rowData: any) => void;
}

const AMFilterCarousel = ({
  addroute,
  carouselData,
  handleDelete,
  isOpenPopupHandle,
  closePopupHandle,
  handleDeleteRow,
  slidesToShow,
  isOfferCarousel,
  handlePublicationDelete,
}: Readonly<AMFilterCarouselProps>) => {
  const { t } = useTranslation("advertmingo-publishing-offers");
  const [activeIndex, setActiveIndex] = useState(0);
  const handleMenuItemClick = (index: number) => {
    setActiveIndex(index);
  };

  const publicationDelete = (rowData: any) => {
    if (handlePublicationDelete) {
      handlePublicationDelete(rowData);
    }
  };

  return (
    <section>
      <MenuList className="about-filter-wrp" component="div">
        <MenuList className="about-filter-inner scroller">
          {carouselData.map((data: any, index: number) => (
            <MenuItem
              key={data.typeApiName}
              onClick={() => handleMenuItemClick(index)}
              className={index === activeIndex ? "active" : ""}
            >
              {t(data.typeApiName)}
            </MenuItem>
          ))}
        </MenuList>
      </MenuList>

      <Card
        elevation={0}
        className="totallayersection secloader-wrp remove-height-card media-abouttitle"
      >
        {carouselData.map(
          (carousel: any, index: number) =>
            index === activeIndex && (
              <AMCarouselBoxCard
                key={carousel}
                title={t(carousel.typeApiName)}
                addBtn={
                  carousel.addBtnTxt
                    ? t(carousel.addBtnTxt)
                    : ADD + t(carousel.typeApiName)
                }
                classes="contact-carousel"
                addClick={() => addroute(carousel.typeApiName)}
              >
                <AMCarouselBoxContainer>
                  {carousel[carousel.typeApiName]?.length > 0 ? (
                    <AMCarouselBox
                      slidesToShow={slidesToShow ? slidesToShow : 5}
                      autoplay={false}
                    >
                      {isOfferCarousel
                        ? carousel[carousel.typeApiName]?.map(
                            (infoBox: any) => (
                              <Box
                                key={infoBox}
                                marginX={"12px"}
                                marginTop={"12px"}
                              >
                                <AMInfoCard
                                  title={infoBox.name.value || infoBox.name}
                                  data={infoBox}
                                  buttonValue={t("viewDecide")}
                                  fromOffer={true}
                                  isOfferViewMode
                                  handleDelete={publicationDelete}
                                />
                              </Box>
                            )
                          )
                        : carousel[carousel.typeApiName].map((item: any) => (
                            <AMFilterCarouselItem
                              key={item}
                              title={t(
                                item.mcVariableApiName?.value ||
                                  item.mcVariableApiName ||
                                  item.name?.value
                              )}
                              description={
                                <div>
                                  <CardContent className="ellipsis ellipsis-2">
                                    {item.description?.value ||
                                    item.description ? (
                                      item.description?.value ||
                                      item.description
                                    ) : (
                                      <AMNotFound
                                        minHeight="0"
                                        value={t("noDescriptionFound")}
                                      />
                                    )}
                                  </CardContent>
                                </div>
                              }
                              deleteClick={() =>
                                handleDelete(carousel.typeApiName, item)
                              }
                            />
                          ))}
                    </AMCarouselBox>
                  ) : (
                    <AMNotFound value={t("noDataFound")} />
                  )}
                </AMCarouselBoxContainer>
              </AMCarouselBoxCard>
            )
        )}
      </Card>
      <AMConfirmationPopup
        title={t("deleteConfirmationTitle")}
        description={t("deleteConfirmationDescription", {
          name: t(handleDeleteRow?.rowData?.mcVariableApiName?.value),
        })}
        handleClose={closePopupHandle}
        handleConfirmation={() => handleDelete("delete", "data")}
        open={isOpenPopupHandle}
      />
    </section>
  );
};

export default AMFilterCarousel;
