import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const TabsStyles = {
  flexContainer: {
    "& .MuiButtonBase-root": {
      fontSize: textStyles.fontSize.large,
      fontWeight: textStyles.fontWeight.normal,
      fontFamily: textStyles.fontFamily.defaultFont,
      textTransform: textStyles.transform.capitalize,
      padding: uiConfig.AMDrwopDownBtn,
      margin: uiConfig.AMBtnReducePadding,
      minWidth: uiConfig.AMAuto,
      lineHeight: uiConfig.AMNormal,
      color: ` ${colors.tabButtonClr}!important`,
      minHeight: uiConfig.AMAuto,
    },
  },
  root: {
    ".MuiTab-root": {
      marginLeft: "0!important",
    },
    ".MuiTabScrollButton-root.Mui-disabled": {
      opacity: 0.2,
    },
    minHeight: uiConfig.AMAuto,
    "& .MuiTabs-scroller": {
      borderBottom: uiConfig.AM0,
    },
    "& .MuiTabs-indicator": {
      height: uiConfig.AM3,
    },
    "&.common-head-tabs": {
      marginBottom: uiConfig.AM20,
      ".MuiTabs-indicator": {
        background: colors.primaryDarkColor,
      },
      "& .MuiButtonBase-root": {
        fontWeight: textStyles.fontWeight.semibold,
      },
      "& .MuiButtonBase-root[aria-selected='true']": {
        color: `${colors.primaryDarkColor}!important`,
      },
    },
  },
};
