import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const LinearProgressStyles = {
  root: {
    background: colors.progressBarbg,
    height: uiConfig.AM8,
    borderRadius: uiConfig.AM10,
    marginTop: uiConfig.AM6,
    width: uiConfig.AM80per,
    ".MuiLinearProgress-bar": {
      background: colors.primaryDarkColor,
      borderRadius: uiConfig.AM10,
    },
  },
};
