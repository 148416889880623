export const primaryColor = "#EC383F";
export const primaryLightColor = "#999";
export const primaryDarkColor = "#FB3B88";
export const primaryContrastColor = "#FCFAF6";
export const secondaryColor = "#1FC582";
export const secondaryLightColor = "#656565";
export const secondaryDarkColor = "#fff5fa";
export const secondaryContrastColor = "rgba(255, 245, 250, 0.50)";
export const blackColor = "#000";
export const whiteColor = "#fff";
export const errorMainColor = "#EB3F2D";
export const errorDarkColor = "#F60F0F";
export const menuIcon = "#08090F";
export const drawerBgColor = "rgba(101, 101, 101, 0.70)";
export const datepickerBorder = "#0000006B";
export const svgIconColor = "#FE3B9580";
export const inputColor = "#000000DE";
export const badgeBgColor = "#EC3840";
export const clientDetailBg = "#F5F7FF";
export const menuBgColor = "#f5f7fe";
export const infoCardBr = "#eee";
export const stackFormBorder = "#e7e7e7";
export const progressBarbg = "rgba(255, 157, 202, 0.30)";
export const itemSelectedBg = "rgba(254, 235, 255, 0.50)";
export const decorationLine = "rgba(101, 101, 101, 0.4)";
export const itemLeftBorder = "rgba(254, 59, 149, 0.50)";
export const tableBorder = "rgba(224, 224, 224, 1)";
export const transparent = "transparent";
export const sliderShadow = "#ffb8d3";
export const confirmCardBg = "#FEE4E2";
export const confirmCardBorder = "#FEF3F2";
export const stackSvg = "#707174";
export const editSvg = "#2196f3";
export const disableClr = "rgb(255 219 236)";
export const tabButtonClr = "rgba(0, 0, 0, 0.6)";
export const arrowTyClr = "rgb(101, 101, 101)";
export const menuBorder = "rgb(227, 232, 239)";
export const autoCompleteSvg = "rgba(0, 0, 0, 0.54)";
export const filterBg = "#E6E9EE";
