import React, { ReactNode } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { AMButton, AMPageTitle } from "components/common";
import { useTheme } from "@mui/material/styles";
import { uiConfig } from "designsystem";

interface AMCardProps {
  title: string;
  description?: ReactNode;
  children?: ReactNode;
  carouselTitle?: string;
  linktext?: string;
  bgcolor?: string;
  viewMore?: () => void;
}

const AMCard = ({
  title,
  description,
  children,
  linktext,
  carouselTitle,
  bgcolor,
  viewMore,
}: Readonly<AMCardProps>) => {
  const theme = useTheme();

  return (
    <Card
      elevation={0}
      sx={{
        bgcolor: bgcolor ?? "common.white",
        maxWidth: 310,
        borderRadius: uiConfig.AM20,
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
      }}
    >
      <CardContent sx={{ padding: "40px 20px", paddingBottom: "0px" }}>
        {/* TITLE SECTION STARTS */}
        <AMPageTitle title={title} isH2={true} alignTxt="center" />
        {/* TITLE SECTION ENDS */}
        {/* DESCRIPTION SECTION STARTS */}
        <Typography
          variant="caption"
          sx={{ minHeight: "105px" }}
          className="ellipsis ellipsis-5"
        >
          {typeof description === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <div>{description}</div>
          )}
        </Typography>
        {/* DESCRIPTION SECTION ENDS */}

        {/* CAROUSEL TITLE SECTION STARTS */}
        {carouselTitle && (
          <AMPageTitle
            title={carouselTitle}
            isH2={true}
            alignTxt="center"
            isMargin={true}
          />
        )}
        {/* CAROUSEL TITLE SECTION ENDS */}

        {/* CAROUSEL FULL DESCRIPTION SECTION STARTS */}
        {children}
        {/* CAROUSEL FULL DESCRIPTION SECTION END */}

        {/* LINK TEXT SECTION STARTS */}
        {linktext && (
          <AMButton buttonClick={viewMore} value={linktext} center={true} />
        )}
        {/* LINK TEXT SECTION ENDS */}
      </CardContent>
    </Card>
  );
};

export default AMCard;
