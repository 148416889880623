import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const InputStyles = {
  root: {
    width: uiConfig.AMfull,
    fontSize: textStyles.fontSize.large,
    color: `${colors.inputColor} !important`,
    borderBottom: `${uiConfig.AM1} ${textStyles.border.solid}`,
    borderColor: `${colors.datepickerBorder} !important`,
    borderRadius: uiConfig.AM0,
    "&.MuiOutlinedInput-root": {
      borderRadius: uiConfig.AM0,
    },
    "& input ": {
      fontSize: `${uiConfig.AM18} !important`,
      color: `${colors.inputColor} !important`,
      fontWeight: textStyles.fontWeight.strong,
      padding: uiConfig.AM0,
    },
    "&:after, &:before, & .MuiOutlinedInput-notchedOutline": {
      display: uiConfig.AMNone,
    },
    "& div": {
      fontSize: textStyles.fontSize.large,
      color: `${colors.inputColor} !important`,
      fontWeight: textStyles.fontWeight.strong,
      padding: uiConfig.AM0,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      display: uiConfig.AMNone,
    },
  },
};
