import { textStyles } from "designsystem";
import * as colors from "designsystem/Palette";

export const IconButttonStyles = {
  root: {
    "&.info-wrp": {
      background: colors.secondaryDarkColor,
      ".MuiSvgIcon-root": {
        fill: colors.primaryDarkColor,
      },
    },
    "&.filtered-list-view": {
      backgroundColor: colors.secondaryDarkColor,
      border: `1px solid ${colors.primaryDarkColor} !important`,
      color: `${colors.primaryDarkColor} !important`,
      borderRadius: "50px",
      fontSize: textStyles.fontSize.medium,
      padding: "4px 10px 4px 18px",
    },
    "&.userCount": {
      backgroundColor: `${colors.primaryDarkColor} !important`,
      color: colors.whiteColor,
      fontSize: textStyles.fontSize.small,
      marginLeft: "10px",
      borderRadius: "50px",
    },
    "&.clientCount": {
      backgroundColor: `${colors.primaryDarkColor} !important`,
      color: colors.whiteColor,
      fontSize: textStyles.fontSize.small,
      marginLeft: "10px",
      borderRadius: "50px",
      width: "25px",
      height: "25px",
      display: "inline-block",
      padding: 0,
    },
  },
};
