
export const setCookie = (name: string, value: string | boolean, daysToExpire: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  const serializedValue = JSON.stringify(value);
  document.cookie = name + '=' + serializedValue + ';' + expires + ';path=/';
};

export const setCookieExpiryMins = (name: string, value: string, minutesToExpire: number): void => {
  const date = new Date();
  date.setTime(date.getTime() + minutesToExpire * 60 * 1000); // Convert minutes to milliseconds
  const expires = 'expires=' + date.toUTCString();
  const serializedValue = JSON.stringify(value);
  document.cookie = name + '=' + serializedValue + ';' + expires + ';path=/';
};


export const deleteCookie = (names: string[]): void => {
  names.forEach(name => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
  });
};

export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim());
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
    return null;
};


