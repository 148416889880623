import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const StackStyles = {
  root: {
    justifyContent: textStyles.justifyContent.flexEnd,
    marginBottom: uiConfig.AM20,
    background: colors.whiteColor,
    boxShadow: uiConfig.AMBreadcrumbsShadow,
    borderRadius: uiConfig.AM15,
    padding: uiConfig.AM12,
    "& .MuiFormControl-root": {
      width: uiConfig.AM272,
      border: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.stackFormBorder} !important`,
      borderRadius: uiConfig.AM10,
      padding: uiConfig.AMBtnReducePadding,
      ".MuiInputBase-root": {
        border: uiConfig.AM0,
      },
    },
    ".MuiSvgIcon-root ": {
      fill: colors.stackSvg,
      fontSize: textStyles.fontSize.extraLarge,
    },
    "&.editor-wrp": {
      padding: 0,
      borderRadius: 0,
      boxShadow: "none",
      border: "1px solid #eee",
      ".rsw-editor": {
        borderRadius: 0,
        border: 0,
        ".rsw-toolbar": {
          backgroundColor: "transparent",
          " .rsw-btn": {
            padding: "5px 15px",
            width: "auto",
            height: "auto",
            fontSize: "15px",
            svg: {
              width: "17px",
            },
          },
        },
      },
    },
  },
};
