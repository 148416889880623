import UserModel, { userDataModelDefaultValues } from "./user/UserModel";

interface CommonDataModel {
  me: UserModel;
  authenticated: boolean;
  token: string;
  updateUserDataModel: (userData: UserModel) => void;
  pushRouteInLocalStorage: (location: string) => void;
}

export const commonDataModelDefaultValues: CommonDataModel = {
  me: userDataModelDefaultValues,
  authenticated: false,
  token: "",
  updateUserDataModel: (userData: UserModel) => {},
  pushRouteInLocalStorage: (location: string) => {},
};

export default CommonDataModel;
