import React from "react";
import { Box, Toolbar, Container, Typography } from "@mui/material";
import { AMApplicationLogo } from "components/common/index";
import { useTranslation } from "react-i18next";
import { CopyRight } from "views/layout";

const Footer = () => {
  const { t } = useTranslation("under-construction");

  return (
    <Box bgcolor="primary.contrastText" paddingY="15px">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          sx={{
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          {/* LOGO STARTS HERE */}
          <AMApplicationLogo />
          {/* LOGO ENDS HERE */}

          <Typography variant="caption">
            {typeof t("info.address") === "string" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("info.address"),
                }}
              />
            ) : (
              <div>{t("info.address")}</div>
            )}
          </Typography>
        </Toolbar>
        {/* COPY RIGHT CONTENT STARTS HERE */}
        <CopyRight />
        {/* COPY RIGHT CONTENT ENDS HERE */}
      </Container>
    </Box>
  );
};

export default Footer;
