import React, { ReactNode } from "react";
import { Grid } from "@mui/material";
import AMInfoTitle from "../AM_InfoTitle";
interface AMCarouselBoxCardProps {
  title: string;
  addBtn?: string;
  id?: string;
  classes?: string;
  addClick?: () => void;
  children?: ReactNode;
  idClasses?: string;
}
const AMCarouselBoxCard = ({
  title,
  addBtn,
  classes,
  addClick,
  children,
  idClasses,
  id,
}: Readonly<AMCarouselBoxCardProps>) => {
  return (
    <Grid
      item
      xs={12}
      className={`eachlayerwrp secloader-wrp ${idClasses}`}
      id={id}
    >
      {title && (
        <AMInfoTitle title={title} value={addBtn} buttonClick={addClick} />
      )}
      <Grid container spacing={3} className={`eachgridwrp ${classes}`}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AMCarouselBoxCard;
