
export const READ_ONLY = 'read_only';




interface RolesModel  {
    id: number,
    name: string,
    // action types: read_only, read_only_grayed, disabled, disabled_grayed, read_write
    actionType: string,


}

export const rolesModelDefaultValues : RolesModel = {
    id: 0,
    name: "",
    actionType: "",
}

export default RolesModel;

