import React from "react";
import { advertmingoLogo } from "images/index";
import { Link } from "react-router-dom";
import { CardMedia } from "@mui/material";
import ROUTES from "utils/CommonRouter";
import { uiConfig } from "designsystem";

const AMApplicationLogo = () => {
  const companyName = process.env.REACT_APP_COMPANY_NAME;

  return (
    <Link to={ROUTES["INDEX_ROUTE"]}>
      <CardMedia
        component="img"
        image={advertmingoLogo}
        alt={companyName}
        sx={{
          maxWidth: uiConfig.AM170,
        }}
        width="170px"
        height="31px"
      />
    </Link>
  );
};

export default AMApplicationLogo;
