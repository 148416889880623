import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { Dayjs } from "dayjs";
import AMLabel from "./AM_Label";
import { uiConfig } from "designsystem";
import { DateTimePickerProps } from "@mui/lab/DateTimePicker";

interface AMDatePickerProps {
  label?: string;
  placeholder: string;
  required?: boolean;
  value: Dayjs | null;
  selectDatePicker: DateTimePickerProps<Dayjs>["onChange"];
  toDate?: Dayjs | null;
  fromDate?: Dayjs | null;
}

const AMDatePicker = ({
  label,
  placeholder,
  required,
  selectDatePicker,
  value,
  toDate,
  fromDate,
}: Readonly<AMDatePickerProps>) => {
  // Handler for confirm button click
  const handleConfirm = (newValue: Dayjs | null) => {
    selectDatePicker(newValue);
  };

  // Handler for clear button click
  const handleClear = () => {
    selectDatePicker(null);
  };

  return (
    <div className="customclose">
      <AMLabel label={label} required={required} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={(newValue) => handleConfirm(newValue)}
          name="datetime"
          slotProps={{
            textField: { placeholder: placeholder },
            field: { clearable: true },
          }}
          sx={{
            borderBottom: uiConfig.AM0,
            borderRadius: uiConfig.AM0,
          }}
          minDate={toDate ?? undefined}
          maxDate={fromDate ?? undefined}
        />
        <IconButton onClick={handleClear} edge="end" className="closeicon">
          {value !== null && <ClearIcon />}
        </IconButton>
      </LocalizationProvider>
    </div>
  );
};

export default AMDatePicker;
