


interface UserProfileModel{
    gender: string,
    firstName: string,
    lastName: string,
    eMail: string

}


export const userProfileModelDefaultValues : UserProfileModel = {
    gender: "",
    firstName: "",
    lastName: "",
    eMail: ""
}

export default UserProfileModel;