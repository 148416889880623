import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const ListStyles = {
  root: {
    ".client-item-wrp": {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      borderBottom: "1px solid #eee",
      "span b": {
        color: colors.primaryDarkColor,
      },
    },
    "&.tabListWrp": {
      width: uiConfig.AMfull,
      padding: uiConfig.AM0,
      maxWidth: 360,
      bgcolor: colors.transparent,
      maxHeight: "calc(100vh - 250px)",
      "& .MuiListItem-root": {
        alignItems: textStyles.alignItems.flexStart,
        padding: `${uiConfig.AM16} ${uiConfig.AM8}`,
        borderLeft: `${uiConfig.AM6} ${textStyles.border.solid} ${colors.transparent}`,
        "&:hover .MuiTypography-root": {
          color: colors.blackColor,
        },
      },
      "& .MuiListItemText-root": {
        margin: uiConfig.AM0,
        "& .MuiTypography-root": {
          fontSize: textStyles.fontSize.large,
          lineHeight: textStyles.lineHeight.small,
          fontFamily: textStyles.fontFamily.defaultFont,
        },
        "& .MuiListItemText-secondary": {
          fontSize: textStyles.fontSize.small,
          textTransform: textStyles.transform.capitalize,
          fontFamily: textStyles.fontFamily.defaultFont,
        },
      },
    },
    "&.usermenu": {
      paddingLeft: uiConfig.AM5,
      "&>.MuiTypography-root": {
        padding: `${uiConfig.AM6} ${uiConfig.AM16}`,
        marginBottom: uiConfig.AM10,
        borderBottom: `${uiConfig.AM1} ${textStyles.border.solid} ${colors.menuBorder} !important`,
        display: textStyles.display.block,
      },
      "& .MuiCardContent-root": {
        padding: `${uiConfig.AM0} !important`,
      },
      ".MuiButtonBase-root": {
        // transition: "all 0.2s ease-in-out 0s",
        "&:hover, &.active ": {
          background: colors.secondaryDarkColor,
          ".MuiSvgIcon-root": {
            color: colors.primaryDarkColor,
          },
          ".MuiListItemText-root .MuiTypography-root": {
            color: colors.primaryDarkColor,
          },
        },
      },
      ".MuiListItemIcon-root ": {
        minWidth: uiConfig.AM20,
        ".MuiSvgIcon-root ": {
          width: uiConfig.AMAuto,
          height: uiConfig.AMAuto,
        },
      },
      ".MuiListItemText-root .MuiTypography-root": {
        borderBottom: `${uiConfig.AM0} !important`,
        lineHeight: uiConfig.AMNormal,
        color: colors.secondaryLightColor,
        // transition: "all 0.2s ease-in-out 0s",
      },
    },
    "&.usermenu.mobilemenu": {
      height: uiConfig.AMAuto,
      overflow: uiConfig.AMInherit,
      "& li.MuiButtonBase-root, & a.MuiButtonBase-root, & div.MuiButtonBase-root":
        {
          paddingLeft: uiConfig.AM23,
          lineHeight: uiConfig.AMNormal,
          minHeight: uiConfig.AMAuto,
        },
      "& button.MuiButtonBase-root": {
        padding: uiConfig.AM0,
      },
      ".MuiListItemIcon-root": {
        display: uiConfig.AMNone,
      },
      ".MuiListItemText-root .MuiTypography-root": {
        fontFamily: textStyles.fontFamily.buttonFont,
        color: colors.blackColor,
      },
      ".MuiButtonBase-root": {},
    },
    "&.hoverusermenu": {
      ".MuiTypography-root": {
        fontSize: textStyles.fontSize.ms15,
      },
      ".MuiListItemText-root .MuiTypography-root": {
        borderBottom: `${uiConfig.AM0} !important`,
        color: colors.secondaryLightColor,
        // transition: "all 0.2s ease-in-out 0s",
      },
      "&>.MuiCardContent-root>.MuiButtonBase-root>.MuiListItemText-root>.MuiTypography-root":
        {
          lineHeight: textStyles.lineHeight.macro,
          marginTop: uiConfig.AM10,
          whiteSpace: uiConfig.AMBreakSpaces,
          textAlign: textStyles.textAlign.center,
        },
      ".MuiCollapse-root": {
        position: textStyles.position.absolute,
        left: uiConfig.AMfull,
        // transform: "translateY(-50px)",
        ".MuiCollapse-root": {
          transform: "translateY(-40px)",
          bottom: `${uiConfig.AMAuto} !important`,
          // top: 0,
        },
        background: colors.primaryContrastColor,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: uiConfig.AM5,
      },
      ".MuiButtonBase-root>.MuiSvgIcon-root": {
        display: uiConfig.AMNone,
      },
      ".MuiCollapse-wrapperInner .MuiList-root": {
        paddingLeft: uiConfig.AM0,
      },
      "&>div>.MuiButtonBase-root, &>.MuiBox-root>.MuiButtonBase-root": {
        flexDirection: textStyles.flexDirection.column,
        gap: uiConfig.AM0,
      },
    },
    "&.horizontalmenu": {
      display: textStyles.display.flex,
      gap: uiConfig.AM10,
      height: uiConfig.AMAuto,
      background: colors.menuBgColor,
      padding: `${uiConfig.AM10} ${uiConfig.AM0}`,
      overflowY: textStyles.overflow.hidden,
      "&.scroller::-webkit-scrollbar, &.scroller::-webkit-scrollbar-track": {
        background: colors.menuBgColor,
      },
      ".MuiIconButton-sizeSmall": {
        padding: uiConfig.AM0,
        width: uiConfig.AM18,
        height: uiConfig.AM18,
      },
      "&>.MuiTypography-root": {
        display: uiConfig.AMNone,
      },
      "&>.MuiCardContent-root": {
        "&>.MuiCollapse-root": {
          position: textStyles.position.absolute,
          background: colors.whiteColor,
          boxShadow: uiConfig.AMBreadcrumbsShadow,
          borderRadius: uiConfig.AM10,
          ".MuiList-root": {
            paddingLeft: uiConfig.AM0,
          },
          "& .MuiList-root>.MuiCardContent-root": {
            position: textStyles.position.relative,
            "&>.MuiButtonBase-root": {
              minWidth: "180px",
            },
          },
          "& .MuiCollapse-root": {
            position: textStyles.position.absolute,
            left: uiConfig.AMfull,
            top: uiConfig.AM0,
            background: colors.whiteColor,
            boxShadow: uiConfig.AMBreadcrumbsShadow,
            borderRadius: uiConfig.AM10,
          },
        },
        "&>.MuiButtonBase-root": {
          border: `2px ${textStyles.border.solid} ${colors.transparent}`,
          borderRadius: uiConfig.AM50,
          padding: `${uiConfig.AM6} ${uiConfig.AM12}`,
          lineHeight: uiConfig.AM16,
          minHeight: uiConfig.AMAuto,
        },
        ".MuiSvgIcon-root": {
          height: uiConfig.AM18,
        },
        ".MuiListItemText-root .MuiTypography-root": {
          lineHeight: uiConfig.AM16,
          marginTop: uiConfig.AM0,
        },
        "&:hover>.MuiButtonBase-root, &>.MuiButtonBase-root.active": {
          background: colors.whiteColor,
          border: `2px ${textStyles.border.solid} ${colors.primaryDarkColor}`,
          ".MuiSvgIcon-root": {
            color: colors.primaryDarkColor,
          },
          ".MuiListItemText-root .MuiTypography-root": {
            color: colors.primaryDarkColor,
          },
        },
      },
    },
    "&.profilelist": {
      "& .MuiListItem-root": {
        paddingLeft: `${uiConfig.AM0} !important`,
        display: textStyles.display.flex,
        alignItems: textStyles.alignItems.center,
        "&>.MuiTypography-root": {
          width: "15%",
        },
        "& .MuiListItemText-root": {
          margin: uiConfig.AM0,
        },
      },
    },
    "&.about-filter-wrp": {
      background: colors.menuBgColor,
      padding: `${uiConfig.AM0} ${uiConfig.AM16}`,
      margin: `${uiConfig.AM0} -${uiConfig.AM16} ${uiConfig.AM16}`,
      ".about-filter-inner": {
        display: textStyles.display.flex,
        overflowY: textStyles.overflow.hidden,
        gap: uiConfig.AM16,
        paddingTop: uiConfig.AM12,
        "&.scroller:hover::-webkit-scrollbar-thumb": {
          background: "#eaedf7",
        },
        "&.scroller::-webkit-scrollbar-track": {
          background: colors.clientDetailBg,
        },
      },
      ".MuiMenuItem-root": {
        border: 0,
        borderRadius: uiConfig.AM50,
        padding: `${uiConfig.AM6} ${uiConfig.AM20}`,
        lineHeight: uiConfig.AM16,
        minHeight: uiConfig.AMAuto,
      },
      "& .MuiMenuItem-root:hover, & .MuiMenuItem-root.active": {
        background: colors.primaryDarkColor,
        color: colors.whiteColor,
      },
    },
    "&.drawer-search-list": {
      padding: 0,
      maxHeight: "calc(100vh - 230px)",
    },
  },
};
