import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const CardHeaderStyles = {
  root: {
    padding: uiConfig.AM0,
    ".MuiCardHeader-avatar": {
      marginRight: uiConfig.AM10,
    },
    ".MuiAvatar-root": {
      background: colors.primaryDarkColor,
      width: uiConfig.AM30,
      height: uiConfig.AM30,
      fontSize: textStyles.fontSize.large,
      fontWeight: textStyles.fontWeight.strong,
    },
    ".MuiTypography-root": {
      fontFamily: textStyles.fontFamily.defaultFont,
      lineHeight: textStyles.lineHeight.macro,
      margin: uiConfig.AM0,
      "&.MuiCardHeader-title": {
        fontWeight: textStyles.fontWeight.strong,
      },
    },
  },
};
