import {
  LayoutController,
  MaintenanceLayoutController,
} from "controllers/layout";
import UserLayoutController from "controllers/layout/UserLayoutController";
import React, { Suspense } from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import ROUTES from "utils/CommonRouter";
import { ExtendedRouteObject } from "utils/CommonTypes";
import { MainLayoutPage } from "views/layout";
import { AMPageLoader } from "components/common";
import { mainRoutes } from "routes/GuestRoutes";
import { userRoutes } from "routes/AuthRoutes";
const UnderConstruction = React.lazy(
  () => import("views/main/MyAdvertmingo/BasicComponents/UnderConstruction")
);
const AMErrorPage = React.lazy(
  () => import("components/error-pages/AM_ErrorPage")
);

const routes: ExtendedRouteObject[] = [
  {
    element: <LayoutController />,
    errorElement: (
      <MainLayoutPage>
        <AMErrorPage />
      </MainLayoutPage>
    ),
    children: mainRoutes.map((route) => ({
      ...route,
      element: (
        <Suspense fallback={<AMPageLoader />}>
          {route.guard ? (
            <route.guard element={route.element} />
          ) : (
            route.element
          )}
        </Suspense>
      ),
    })),
  },
  {
    element: <MaintenanceLayoutController />,
    children: [
      {
        path: ROUTES["UNDER_CONSTRUCTION"],
        element: <UnderConstruction />,
      },
    ],
  },
  {
    element: <UserLayoutController />,
    children: userRoutes.map((route) => ({
      ...route,
      element: (
        <Suspense fallback={<AMPageLoader />}>
          {route.guard ? (
            <route.guard element={route.element} />
          ) : (
            route.element
          )}
        </Suspense>
      ),
    })),
  },
];

export const routers = routes;

export const router = createHashRouter(routes);

export function navigate(dest: string) {
  router.navigate(dest);
}

const ErrorPage = () => <RouterProvider router={router} />;
export default ErrorPage;
