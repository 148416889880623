import * as React from "react";
import { LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface AMLineProgressProps {
  value: number;
}

const AMLineProgress = ({ value }: Readonly<AMLineProgressProps>) => {
  return (
    <Box display="flex" justifyContent="space-between" gap="20px">
      <LinearProgress
        variant="determinate"
        value={value}
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-label="Loading progress"
        aria-labelledby="progress-label"
      />
      <Typography variant="h5">{value}%</Typography>
    </Box>
  );
};

export default AMLineProgress;
