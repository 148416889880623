import { FilterAltOutlined, CloseOutlined } from "@mui/icons-material";
import { Card, CardHeader, IconButton, CardActions } from "@mui/material";
import { t } from "i18next";
import React, { ReactNode } from "react";
import AMButton from "./AM_Button";
import AMPopover from "./AM_Popover";
import * as colors from "designsystem/Palette";

interface AMFilterPopupProps {
  children: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  handleOpenFilter: () => void;
  handleConfirmation?: () => void;
  isCustomWidth?: string;
}
const AMFilterPopup = ({
  children,
  onClose,
  isOpen,
  handleOpenFilter,
  handleConfirmation,
  isCustomWidth,
}: Readonly<AMFilterPopupProps>) => {
  return (
    <AMPopover
      isOpen={isOpen}
      onClose={onClose}
      icon={
        <AMButton
          isFilter
          icon={<FilterAltOutlined sx={{ fontSize: "15px" }} />}
          value={t("filter", { ns: "common" })}
          margintop={""}
          variant="outlined"
          buttonClick={handleOpenFilter}
        />
      }
    >
      <Card
        elevation={0}
        className="filter-popup-wrp"
        sx={{
          width: isCustomWidth ? isCustomWidth : "500px",
        }}
      >
        <CardHeader
          title={"Filter"}
          sx={{
            paddingInline: "10px",
            paddingBottom: "10px",
            borderBottom: `1px solid ${colors.infoCardBr}!important`,
          }}
          action={
            <IconButton
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onClick={onClose}
            >
              <CloseOutlined fontSize="small" />
            </IconButton>
          }
        />
        <div
          className="scroller filter-content-wrp"
          style={{
            maxHeight: "calc(100vh - 300px)",
          }}
        >
          {children}
        </div>
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingBottom: "15px",
            borderTop: `1px solid ${colors.infoCardBr}!important`,
          }}
        >
          <AMButton
            value={"Clear"}
            filColor="red"
            margintop={"5px"}
            buttonClick={onClose}
          />
          <AMButton
            value={"Apply"}
            margintop={"5px"}
            buttonClick={handleConfirmation}
          />
        </CardActions>
      </Card>
    </AMPopover>
  );
};

export default AMFilterPopup;
