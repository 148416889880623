import React from "react";
import AMLabel from "./AM_Label";
import Editor from "react-simple-wysiwyg";
import { Stack } from "@mui/material";

interface AMEditorProps {
  label: string;
  required?: boolean;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
}

const AMEditor = ({
  label,
  required,
  value,
  onChange,
  isDisabled,
}: Readonly<AMEditorProps>) => {
  const handleEditorChange = (
    e: React.ChangeEvent<HTMLTextAreaElement> | any
  ) => {
    const newValue = e.target?.value || "";
    onChange(newValue);
  };

  return (
    <div>
      <AMLabel label={label} required={required} />
      <Stack className={`editor-wrp ${isDisabled ? "Mui-disabled" : ""}`}>
        <Editor
          value={value}
          disabled={isDisabled}
          onChange={handleEditorChange}
        />
      </Stack>
    </div>
  );
};

export default AMEditor;
