import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const CopyRight = () => {
  const { t } = useTranslation("menu");

  const currentYear = new Date().getFullYear();
  const companyName = process.env.REACT_APP_COMPANY_NAME;

  return (
    <section id="sign-in">
      <Typography variant="caption">
        {companyName} © {t("year")}-{currentYear}. {t("allright")}
      </Typography>
    </section>
  );
};

export default CopyRight;
