import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const AppBarStyles = {
  root: {
    background: colors.whiteColor,
    "& .MuiToolbar-root": {
      display: textStyles.display.grid,
      gridTemplateColumns: uiConfig.AMAppBarGrid,
      justifyContent: textStyles.justifyContent.spaceBetween,
    },
    "& .navLink .MuiButton-root": {
      borderRadius: uiConfig.AM0,
      padding: uiConfig.AM0,
      minWidth: uiConfig.AM0,
      borderBottomWidth: uiConfig.AM3,
      borderBottomStyle: "solid",
      display: textStyles.display.flex,
      letterSpacing: uiConfig.AMPoint17,
      lineHeight: uiConfig.AM16,
      fontFamily: textStyles.fontFamily.buttonFont,
      "&:hover": {
        bgcolor: colors.transparent,
        borderBottomColor: colors.primaryColor,
      },
    },
  },
};
