import { textStyles, uiConfig } from "designsystem";

export const AlertStyles = {
  root: {
    fontSize: textStyles.fontSize.medium,
    marginBottom: uiConfig.AM20,
    fontFamily: textStyles.fontFamily.defaultFont,
    ".MuiAlert-message": {
      overflow: uiConfig.AMInitial,
    },
  },
};
