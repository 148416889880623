import React from "react";
import { CardMedia } from "@mui/material";

interface AMImageProps {
  imgUrl: string;
  imgName: string;
}
const AMImage = ({ imgUrl, imgName }: Readonly<AMImageProps>) => {
  return <CardMedia component="img" image={imgUrl} alt={imgName}></CardMedia>;
};

export default AMImage;
