import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const DrawerStyles = {
  root: {
    "& .MuiBackdrop-root": {
      background: `${colors.drawerBgColor} !important`,
      backdropFilter: "blur(2px)",
    },
    "& .MuiDrawer-paper": {
      width: uiConfig.AM285,
      height: "auto",
      paddingBottom: uiConfig.AM0,
      borderBottomRightRadius: uiConfig.AM20,
      backgroundColor: colors.primaryContrastColor,
    },
    "&.userdrawer": {
      zIndex: textStyles.zIndex.Z2,
      display: textStyles.display.inlineBlock,
      position: textStyles.position.sticky,
      background: colors.whiteColor,
      top: uiConfig.AM0,
      "& .MuiPaper-root": {
        top: uiConfig.AM174,
        background: uiConfig.AMNone,
        borderRight: uiConfig.AM0,
        overflow: textStyles.overflow.visible,
      },
    },
    "&.common-drawer-wrp": {
      ".MuiStack-root .component-select-wrp": {
        ".MuiInputBase-root.MuiOutlinedInput-root": {
          border: "1px solid #e7e7e7 !important",
          borderRadius: "10px",
          paddingLeft: "10px",
          minWidth: "120px",
        },
      },
      ".sortOrder-icon": {
        border: "1px solid #e7e7e7 !important",
        borderRadius: "8px",
        padding: "0 8px",
      },
      ".MuiDrawer-paper ": {
        height: "100%",
        borderRadius: 0,
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
        background: colors.whiteColor,
        ".search-list": {
          ".MuiInputBase-root": {
            padding: 0,
          },
          svg: {
            cursor: "pointer",
            marginLeft: "5px",
          },
        },
        ".MuiCardHeader-root": {
          background: "#f5f7fe",
          borderBottom: "1px solid #eaedf9",
          justifyContent: "space-between",
          padding: "5px 20px",
          ".MuiCardHeader-title": {
            fontFamily: textStyles.fontFamily.titleFont,
            fontSize: textStyles.fontSize.medium,
            fontWeight: "normal",
          },
          ".MuiCardHeader-action .MuiButtonBase-root": {
            background: "none!important",
          },
        },
        ".common-drawer-footer": {
          background: "#f5f7fe",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          justifyContent: "flex-end",
          padding: "20px",
        },
      },
    },
  },
};
