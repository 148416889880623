import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { SetupWorker } from "msw/lib/browser";

const container = document.getElementById("root");
const root = createRoot(container!);

async function enableMocking() {
  if (
    process.env.NODE_ENV !== "development" &&
    process.env.NODE_ENV !== "production"
  ) {
    return;
  }

  const { worker: importedWorker } = await import("./api_mocks/browser.js");
  const worker: SetupWorker = importedWorker;

  async function isWorkerActive(): Promise<boolean> {
    const registrations = await navigator.serviceWorker.getRegistrations();
    console.log("Service Worker Registrations:", registrations);
    return registrations.some((reg) =>
      reg?.active?.scriptURL.includes("mockServiceWorker.js")
    );
  }

  async function ensureWorkerIsActive(): Promise<void> {
    const isActive = await isWorkerActive();

    if (!isActive) {
      console.log("MSW is not active. Starting the worker again...");
      try {
        await worker.start({
          onUnhandledRequest: "bypass",
        });
        console.log("MSW worker started successfully.");
      } catch (error) {
        console.error("Failed to start MSW worker:", error);
      }
    } else {
      console.log("MSW is already active.");
    }
  }

  try {
    await worker.start({
      onUnhandledRequest: "bypass",
    });
    console.log("MSW worker started successfully on initial load.");
  } catch (error) {
    console.error("Failed to start MSW worker on initial load:", error);
  }

  setInterval(() => {
    ensureWorkerIsActive().catch((error) =>
      console.error("Error ensuring worker is active:", error)
    );
  }, 10000);
}

enableMocking().catch((error) =>
  console.error("Error enabling mocking:", error)
);

enableMocking().then(() => {
  root.render(<App />);
});
