import React from "react";
import { Avatar, CardHeader } from "@mui/material";

interface ProfileProps {
  profileLetter?: string;
  profileName?: string;
  profileMore?: string;
  profileimage?: string;
}

const AMProfile = ({
  profileLetter,
  profileName,
  profileMore,
  profileimage,
}: Readonly<ProfileProps>) => {
  return (
    <CardHeader
      avatar={
        <Avatar alt={profileName} aria-label="recipe" src={profileimage}>
          {profileLetter}
        </Avatar>
      }
      title={profileName}
      subheader={profileMore}
    />
  );
};
export default AMProfile;
