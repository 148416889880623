import React, { ReactNode } from "react";
import { CardContent } from "@mui/material";
import { AMContainer, AMFormImage } from "components/common";

interface AMContactFormProps {
  children?: ReactNode;
  imgSpace?: boolean;
}
const AMContactForm = ({
  children,
  imgSpace = true,
}: Readonly<AMContactFormProps>) => {
  return (
    <AMContainer>
      <AMFormImage imgSpace={imgSpace}>
        <CardContent
          sx={{
            paddingTop: "0px",
            paddingBottom: "0px!important",
            paddingX: 0,
          }}
        >
          {children}
        </CardContent>
      </AMFormImage>
    </AMContainer>
  );
};

export default AMContactForm;
