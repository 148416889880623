import React from "react";
import { Card, CardContent, CardMedia, Modal, Typography } from "@mui/material";
import { uiConfig } from "designsystem";
import AMPageTitle from "./AM_PageTitle";
import { errorIcon } from "images";
import AMButton from "./AM_Button";
import { useTranslation } from "react-i18next";
interface AMConfirmationPopupProps {
  title: string;
  description: string;
  handleClose: () => void;
  open: boolean;
  handleConfirmation: () => void;
  confirmationbuttonKey?: string;
  cancelButtonKey?: string;
  isCustomFilterComponent?: boolean;
}
const AMConfirmationPopup = ({
  handleClose,
  open,
  description,
  title,
  handleConfirmation,
  confirmationbuttonKey,
  cancelButtonKey,
  isCustomFilterComponent,
}: Readonly<AMConfirmationPopupProps>) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card className="infocardwrp confirmmodalwrp">
        <CardMedia
          component="img"
          image={errorIcon}
          alt={title}
          sx={{
            width: uiConfig.AM58,
            height: uiConfig.AM58,
          }}
        ></CardMedia>
        <AMPageTitle title={title} isH2={true} alignTxt="center" />
        <Typography variant="caption">{description}</Typography>
        <CardContent>
          <AMButton
            value={confirmationbuttonKey ?? t("yes")}
            filColor={"red"}
            buttonClick={
              isCustomFilterComponent ? handleClose : handleConfirmation
            }
          />
          <AMButton
            value={cancelButtonKey ?? t("no")}
            filColor={"green"}
            buttonClick={
              isCustomFilterComponent ? handleConfirmation : handleClose
            }
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default AMConfirmationPopup;
