import React from "react";
import { Box } from "@mui/material";
import { AMButton, AMPageTitle } from "components/common";

interface AMInfoTitleProps {
  title: string;
  value?: string;
  buttonClick?: () => void;
}
const AMInfoTitle = ({
  title,
  value,
  buttonClick,
}: Readonly<AMInfoTitleProps>) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems={"center"}
      gap="20px"
      marginBottom={"20px"}
    >
      <AMPageTitle title={title} isH2={true} />
      {value && (
        <AMButton
          variant="outlined"
          buttonClick={buttonClick}
          margintop=""
          value={value}
          center={true}
        />
      )}
    </Box>
  );
};

export default AMInfoTitle;
