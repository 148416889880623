import React from "react";
import { RadioGroup } from "@mui/material";
import AMRadioSwitch from "./AM_RadioSwitch";
interface Option {
  label: string;
  value: string;
  icon?: React.ReactNode;
}

interface AMRadioGroupProps {
  name: string;
  value?: string;
  options: Option[];
  selectToggle?: React.ChangeEventHandler<HTMLInputElement>;
}

const AMRadioGroup = ({
  name,
  value,
  selectToggle,
  options,
}: Readonly<AMRadioGroupProps>) => {
  return (
    <RadioGroup name={name} value={value} onChange={selectToggle}>
      {options.map((option) => (
        <div key={option.value}>
          <AMRadioSwitch
            label={
              <span>
                {option.label}
                {option.icon}
              </span>
            }
            value={option.value}
          />
        </div>
      ))}
    </RadioGroup>
  );
};

export default AMRadioGroup;
