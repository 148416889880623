import React, { ReactNode } from "react";
import { CardMedia, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { loginBg } from "images";

interface AMFormImageProps {
  children: ReactNode;
  imgSpace?: boolean;
}

const AMFormImage = ({
  children,
  imgSpace = false,
}: Readonly<AMFormImageProps>) => {
  const theme = useTheme();

  return (
    <Grid
      spacing={8}
      container
      m={0}
      justifyContent="space-between"
      gap="20px"
      sx={{
        width: "100%!important",
        [theme.breakpoints.down("lg")]: {
          flexDirection: "column-reverse",
          width: "100%!important",
          gap: "40px",
          marginTop: "40px",
        },
      }}
    >
      <Grid item lg={6} padding="0!important">
        {children}
      </Grid>
      <Grid
        item
        lg={4}
        sx={{
          padding: "0!important",
        }}
      >
        <CardMedia
          component="img"
          image={loginBg}
          alt="Carousel Nav"
          sx={{
            width: "265px",
            float: "right",
            margin: imgSpace ? "60px auto 0" : "auto",
            [theme.breakpoints.down("lg")]: {
              margin: "0px auto",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
export default AMFormImage;
