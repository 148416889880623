import React, { ReactNode } from "react";
import { CardMedia, InputLabel, Typography } from "@mui/material";
import { uiConfig } from "designsystem";

interface AMLabelInfoProps {
  label: string | ReactNode;
  required?: boolean;
  large?: boolean;
  icon?: ReactNode;
  classes?: string;
}
const AMLabel = ({
  label,
  required,
  icon,
  large,
  classes,
}: Readonly<AMLabelInfoProps>) => {
  return (
    <InputLabel
      className={`input-label ${large ? "input-labelfs18" : ""} ${classes}`}
    >
      {label}
      {required && (
        <Typography
          variant="body2"
          sx={{
            color: "error.dark",
            marginLeft: uiConfig.AM5,
            lineHeight: "1rem",
          }}
        >
          *
        </Typography>
      )}

      {icon && (
        <CardMedia
          component="span"
          sx={{
            "& .MuiSvgIcon-root": {
              width: uiConfig.AM16,
              height: uiConfig.AM16,
              position: "relative",
              top: uiConfig.AM3,
              color: "palette.svgIconColor",
              marginLeft: uiConfig.AM5,
            },
            display: "inline-block",
          }}
        >
          {icon}
        </CardMedia>
      )}
    </InputLabel>
  );
};

export default AMLabel;
