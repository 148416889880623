import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const SVGStyles = {
  root: {
    "&.togglemenu": {
      background: colors.secondaryDarkColor,
      cursor: textStyles.cursor.pointer,
      borderRadius: uiConfig.AM8,
      width: uiConfig.AM40,
      height: uiConfig.AM34,
      padding: uiConfig.AM5,
      overflow: textStyles.overflow.hidden,
      transition: "all 0.2s ease-in-out 0s",
      color: colors.primaryColor,
      zIndex: textStyles.zIndex.Z1100,
    },
    "&.clearBtn": {
      color: colors.primaryColor,
      width: uiConfig.AM20,
      cursor: textStyles.cursor.pointer,
    },
    "&.viewicon": {
      color: colors.primaryDarkColor,
      width: uiConfig.AM20,
      cursor: textStyles.cursor.pointer,
    },
    "&.editicon": {
      color: colors.editSvg,
      width: uiConfig.AM20,
      cursor: textStyles.cursor.pointer,
    },
    "&.addicon": {
      color: colors.secondaryColor,
      width: uiConfig.AM20,
      cursor: textStyles.cursor.pointer,
    },
    "&.deleteicon": {
      color: colors.primaryColor,
      width: uiConfig.AM20,
      cursor: textStyles.cursor.pointer,
    },
  },
};
