import { textStyles, uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

export const ListItemStyles = {
  root: {
    borderLeft: `${uiConfig.AM6} ${textStyles.border.solid} ${colors.transparent}`,
    color: colors.secondaryLightColor,
    fontFamily: textStyles.fontFamily.defaultFont,
    padding: uiConfig.AMItemPadding,
    fontWeight: textStyles.fontWeight.normal,
    justifyContent: textStyles.justifyContent.spaceBetween,
    "&.Mui-selected.Mui-selected, &:hover, &.Mui-selected.Mui-selected:hover": {
      backgroundColor: `${colors.itemSelectedBg} !important`,
      borderLeft: `${uiConfig.AM6} ${textStyles.border.solid} ${colors.itemLeftBorder}`,
      color: colors.blackColor,
    },
  },
};
