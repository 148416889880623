import React, { useState } from "react";
import { IconButton, Input, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FieldInputProps } from "react-final-form";
import AMLabel from "./AM_Label";
import { uiConfig } from "designsystem";
import * as colors from "designsystem/Palette";

interface AMPasswordProps {
  input?: FieldInputProps<HTMLElement>;
  label: string;
  required?: boolean;
  autocomplete?: string;
}
const AMPassword = ({
  input,
  label,
  required,
  autocomplete,
}: Readonly<AMPasswordProps>) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <AMLabel label={label} required={required} />

      <Input
        {...input}
        type={showPassword ? "text" : "password"}
        autoComplete={autocomplete}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              disableFocusRipple
              disableTouchRipple
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((show) => !show)}
              edge="end"
              sx={{
                backgroundColor: "transparent!important",
                padding: uiConfig.AM0,
                margin: uiConfig.AM0,
              }}
            >
              {!showPassword ? (
                <VisibilityOff
                  sx={{
                    fill: colors.secondaryLightColor,
                    fontSize: uiConfig.AM16,
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: colors.secondaryLightColor,
                    fontSize: uiConfig.AM16,
                  }}
                />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
};

export default AMPassword;
