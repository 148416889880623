import React, { ReactNode } from "react";
import {
  Typography,
  CardMedia,
  CardContent,
  Card,
  Box,
  CardContentProps,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AMPageTitle } from "components/common";

interface SectionCardProps extends CardContentProps {
  title?: string;
  description?: ReactNode;
  cardImg?: string;
  imgWidth?: string;
  imgHeight?: string;
  flexDirection?: string;
  gap?: string;
  descWidth?: string;
  className?: string;
  noRadius?: boolean;
  cardCenter?: boolean;
  isBgColor?: boolean;
  alignTxt?: string;
  moreDescription?: ReactNode;
  isH2?: boolean;
}

const AMSectionCard = ({
  title,
  description,
  cardImg,
  imgWidth,
  imgHeight,
  noRadius,
  gap,
  descWidth,
  alignTxt,
  flexDirection,
  className,
  cardCenter,
  isBgColor,
  moreDescription,
  isH2,
  ...rest
}: Readonly<SectionCardProps>) => {
  const theme = useTheme();

  const renderCardContent = () => {
    return (
      <Box
        display={cardImg ? "flex" : "inline-block"}
        flexDirection="column"
        width={cardImg ? "auto" : "100%"}
        padding={0}
      >
        <AMPageTitle title={title} alignTxt={alignTxt} isH2={isH2} />
        <Typography
          component="div"
          variant="caption"
          className={className}
          sx={{
            marginTop: title ? "20px" : "0",
            width: descWidth,
            [theme.breakpoints.down("lg")]: {
              width: "100%",
            },
          }}
        >
          {typeof description === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            <div>{description}</div>
          )}
        </Typography>
      </Box>
    );
  };

  const renderCardImage = () => {
    return (
      <CardContent
        sx={{
          padding: "10px!important",
          flex: "1 0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: imgWidth ?? "260px",
          height: imgHeight ?? "260px",
          borderRadius: noRadius ? 0 : "20px",
          [theme.breakpoints.down("lg")]: {
            width: "400px",
            margin: "auto",
            borderRadius: "10px",
          },
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
            height: "100%",
            borderRadius: noRadius ? 0 : "20px",
            [theme.breakpoints.down("lg")]: {
              borderRadius: "10px",
            },
          }}
          image={cardImg}
          alt={"card img"}
        />
      </CardContent>
    );
  };

  return (
    <Box
      sx={{
        padding: 0,
      }}
    >
      <Card
        elevation={0}
        sx={{
          display: cardImg ? "flex" : "inline-block",
          alignItems: cardCenter ? "center" : "flex-end",
          justifyContent: cardCenter ? "center" : "",
          gap: gap ?? "10px",
          width: cardImg ? "auto" : "100%",
          bgcolor: isBgColor ? "transparent!important" : "",
          flexDirection: flexDirection ?? "",
          paddingLeft: 0,
          borderRadius: "unset",
          paddingBottom: 0,
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column-reverse",
            justifyContent: "center",
            borderRadius: "10px",
            width: "100%",
          },
        }}
      >
        {renderCardContent()}
        {cardImg && renderCardImage()}
      </Card>
      {moreDescription && (
        <Typography
          variant="caption"
          sx={{
            "& br": {
              display: "none",
            },
          }}
        >
          {typeof moreDescription === "string" ? (
            <div dangerouslySetInnerHTML={{ __html: moreDescription }} />
          ) : (
            <div>{moreDescription}</div>
          )}
        </Typography>
      )}
    </Box>
  );
};

export default AMSectionCard;
