import { DeleteOutlined, ModeOutlined } from "@mui/icons-material";
import { Card, CardActions, Grid } from "@mui/material";
import { AMCommonInfoCard } from "components/widgets";
import React, { ReactNode } from "react";

interface AMFilterCarouselItemProps {
  title: string;
  description: ReactNode;
  editClick?: () => void;
  deleteClick: () => void;
}

const AMFilterCarouselItem = ({
  title,
  description,
  editClick,
  deleteClick,
}: AMFilterCarouselItemProps) => {
  return (
    <Grid item xs={12}>
      <AMCommonInfoCard
        isLgLabel
        title={title}
        isSmall={true}
        description={
          <Card elevation={0} className="editmedia-wrp">
            {description}
          </Card>
        }
        button={
          <CardActions className="cardactionbtn" sx={{ padding: 0 }}>
            {editClick && (
              <ModeOutlined className="editicon" onClick={editClick} />
            )}
            <DeleteOutlined className="deleteicon" onClick={deleteClick} />
          </CardActions>
        }
      />
    </Grid>
  );
};

export default AMFilterCarouselItem;
