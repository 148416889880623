import React from "react";

import { MaintenanceLayout } from "views/layout";

const MaintenanceLayoutController = () => {
  return (
    <div>
      <MaintenanceLayout />
    </div>
  );
};

export default MaintenanceLayoutController;
