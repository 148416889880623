import React, { ReactNode } from "react";
import { Box, BoxProps, Card } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface CarouselCardProps extends BoxProps {
  children?: ReactNode;
  bgColor?: string;
  maxWidth?: string;
  mb?: string;
  border?: number;
}

export default function AMCarouselContainer({
  children,
  bgColor,
  maxWidth,
  mb,
  border,
  ...rest
}: Readonly<CarouselCardProps>) {
  const theme = useTheme();

  return (
    <Box
      elevation={0}
      component="div"
      {...rest}
      maxWidth={maxWidth ?? 310}
      marginBottom={mb ?? "50px"}
      overflow={"inherit"}
      padding="0px"
      bgcolor="transparent!important"
      position="relative"
      sx={{
        [theme.breakpoints.down("sm")]: {
          maxWidth: "100%",
        },
      }}
    >
      <Card
        elevation={0}
        sx={{
          padding: "16px",
          bgcolor: bgColor ?? "secondary.contrastText",
          borderRadius: "20px",
          border: border ?? "1px solid rgba(255, 157, 202, 0.30)",
        }}
      >
        {children}
      </Card>
    </Box>
  );
}
