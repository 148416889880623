import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LANGUAGES_JSON from "data/cms/supported-languages.json";
import Cookies from "js-cookie";
import { List, Box, ListItemButton, CardMedia } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { check } from "images/index";
import { setupMediaQueryListener } from "utils/CommonFunctions";
import AMButton from "./AM_Button";
import { uiConfig } from "designsystem";
interface ButtonProps {
  closePopover: (() => void) | undefined;
}

const AMSelectLanguages = ({
  closePopover = () => {},
}: Readonly<ButtonProps>) => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [lanCode, setLanCode] = useState(() => {
    const cookieValue = Cookies.get("TILang") ?? LANGUAGES_JSON.defaultLanguage;
    return cookieValue;
  });

  // SELECT FOR LANG DROPDOWN
  const onChangeLanguage = (code: string) => {
    setLanCode(code);
  };
  const onlanguagechange = (language: string) => {
    i18n.changeLanguage(language);
    setIsOpen(!isOpen);
    Cookies.set("TILang", language); // expires in 7 days
  };

  // FOR MOBILE VIEW LANGUAGE STARTS
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const tabScreen = setupMediaQueryListener(
      "(max-width: 1299px)",
      setIsSmallScreen
    );
    return tabScreen;
  }, []);
  // FOR MOBILE VIEW LANGUAGE ENDS

  const theme = useTheme();

  return (
    <div className={`mainlng-wrp ${isSmallScreen ? "mobileview" : ""}`}>
      <List
        className="scroller"
        sx={{
          maxHeight: uiConfig.AM270,
        }}
      >
        {isSmallScreen ? (
          // IN MOBILE VIEW LANGUAGE LIST STARTS HERE
          <div>
            {LANGUAGES_JSON.languages.map(({ code, label }) => (
              <ListItemButton
                disableRipple
                key={code}
                selected={lanCode === code}
                onClick={() => {
                  onlanguagechange(code);
                  setLanCode(code);
                  closePopover();
                }}
                onKeyDown={() => {
                  onlanguagechange(code);
                  setLanCode(code);
                  closePopover();
                }}
              >
                {label}
                {lanCode === code && (
                  <CardMedia
                    component="img"
                    image={check}
                    alt="check"
                    sx={{
                      width: uiConfig.AM16,
                    }}
                  />
                )}
              </ListItemButton>
            ))}
          </div>
        ) : (
          // IN MOBILE VIEW LANGUAGE LIST EDNS HERE
          // IN WEB VIEW LANGUAGE LIST STARTS HERE
          <div>
            {LANGUAGES_JSON.languages.map(({ code, label }) => (
              <ListItemButton
                disableRipple
                key={code}
                selected={lanCode === code}
                onClick={() => {
                  onChangeLanguage(code);
                }}
                onKeyDown={() => {
                  onChangeLanguage(code);
                }}
              >
                {label}
              </ListItemButton>
            ))}
          </div>
          // IN WEB VIEW LANGUAGE LIST ENDS HERE
        )}
      </List>

      <Box
        bgcolor="#F9F3E6"
        justifyContent="flex-end"
        padding="16px"
        display="flex"
        sx={{
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        }}
      >
        <AMButton
          value={t("confirm")}
          margintop="0"
          buttonClick={() => {
            onlanguagechange(lanCode);
            closePopover();
          }}
        />
      </Box>
    </div>
  );
};

export default AMSelectLanguages;
