import * as React from "react";
import CircularProgress, {
  CircularProgressProps as MuiCircularProgressProps, // Renamed import
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as colors from "designsystem/Palette";

interface CircularProgressWithLabelProps extends MuiCircularProgressProps {
  value: number;
}

function CircularProgressWithLabel(props: Readonly<CircularProgressWithLabelProps>) {
  return (
    <Box position="relative" display="inline-flex">
      <Box sx={{ position: "relative" }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: colors.progressBarbg,
          }}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          value={props.value}
          sx={{
            position: "absolute",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
          }}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body1"
          component="div"
          className="progressvalue"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const CircularWithValueLabel = ({ value }: { value: number }) => {
  return <CircularProgressWithLabel value={value} />;
};

export default CircularWithValueLabel;
