import { UserLoginModel } from "models";
import InitAnonymousLoadReqModel from "../models/init-load/InitAnonymousLoadReqModel";
import endpoint from "../api-contracts/api-endpoints/common-api-endpoints.json";
import axiosInstance from "./headers";
import {
  ABOUT_AUDIENCE_TAB_KEY,
  ABOUT_FOLLOWS_TAB_KEY,
  ABOUT_ORGANIZATION_TAB_KEY,
  DATA_RETURN_TYPE_ALL,
  ABOUT_ME_TAB_KEY,
  WEB,
} from "utils/CommonText";
import { getCookie } from "utils/cookieService";
import { GetUserPaymentsParams, Title } from "utils/CommonTypes";

const getEndpoint = () => {
  let env = process.env.REACT_APP_ENV ?? "local";
  if (!(env in endpoint)) {
    throw new Error(`Invalid environment: ${env}`);
  }

  return endpoint[env as keyof typeof endpoint];
};

const isAuthenticated = () => {
  const storedUser = getCookie("at");
  return !!storedUser;
};

const getMCEndpointByTitle = (title: Title, method: string): string => {
  const createEndpoints: Record<Title, string> = {
    [ABOUT_ME_TAB_KEY]: "createUserMCProfileAboutMeSelfAnalytics",
    [ABOUT_FOLLOWS_TAB_KEY]: "createUserMCProfileAboutFollowersSelfAnalytics",
    [ABOUT_ORGANIZATION_TAB_KEY]:
      "createUserMCProfileAboutOrganizationSelfAnalytics",
    [ABOUT_AUDIENCE_TAB_KEY]: "createUserMCProfileAboutAudienceSelfAnalytics",
  };
  const updateEndpoints: Record<Title, string> = {
    [ABOUT_ME_TAB_KEY]: "updateUserMCProfileAboutMeSelfAnalytics",
    [ABOUT_FOLLOWS_TAB_KEY]: "updateUserMCProfileAboutFollowersSelfAnalytics",
    [ABOUT_ORGANIZATION_TAB_KEY]:
      "updateUserMCProfileAboutOrganizationSelfAnalytics",
    [ABOUT_AUDIENCE_TAB_KEY]: "updateUserMCProfileAboutAudienceSelfAnalytics",
  };

  const deleteEndpoints: Record<Title, string> = {
    [ABOUT_ME_TAB_KEY]: "deleteUserMCProfileAboutMeSelfAnalytics",
    [ABOUT_FOLLOWS_TAB_KEY]: "deleteUserMCProfileAboutFollowersSelfAnalytics",
    [ABOUT_ORGANIZATION_TAB_KEY]:
      "deleteUserMCProfileAboutOrganizationSelfAnalytics",
    [ABOUT_AUDIENCE_TAB_KEY]: "deleteUserMCProfileAboutAudienceSelfAnalytics",
  };

  if (method === "create") {
    return createEndpoints[title];
  } else if (method === "update") {
    return updateEndpoints[title];
  } else if (method === "delete") {
    return deleteEndpoints[title];
  }

  throw new Error(`Invalid method: ${method}`);
};

const loginUser = async (userLoginModel: UserLoginModel) => {
  const { data: response, headers } = await axiosInstance.post(
    (getEndpoint() as any)["authSignIn"],
    userLoginModel
  );
  return { response, headers };
};

const registerUser = async (CreateAccountFields: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAccount"],
    CreateAccountFields
  );
  return response;
};

const fetchUserProfile = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["user"],
    {
      params: { cli_type: WEB },
    }
  );
  return response;
};

const fetchInitAnonymousProfile = async (
  initAnonymousLoadReqModel: InitAnonymousLoadReqModel
) => {
  const { data: response } = await axiosInstance.post(
    "http://localhost:8081/fetchInitAnonymousProfile",
    initAnonymousLoadReqModel
  );
  return response.data;
};

const forgotPassword = async (ForgetPasswordFields: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["forgotPassword"],
    ForgetPasswordFields
  );
  return response;
};

const resetPassword = async (ResetPasswordFields: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["resetPassword"],
    ResetPasswordFields
  );
  return response;
};

const refreshToken = async () => {
  const { data: response, headers } = await axiosInstance.post(
    (getEndpoint() as any)["authSignIn"],
    null,
    {
      params: {
        grant_type: "refresh_token",
        cli_type: "web_cli",
      },
    }
  );
  return { response, headers };
};

const notificationList = async (payload: {}) => {
  const authenticated = isAuthenticated();
  const endpoint = authenticated
    ? (getEndpoint() as any)["userNotifications"]
    : (getEndpoint() as any)["initLoadNotifications"];

  const { data: response } = await axiosInstance.post(endpoint, payload);
  return response;
};

const getNotificationCount = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["initLoadNotificationCount"],
    payload
  );
  return response;
};

const getDashboardList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["dashboard"]
  );
  return response;
};

const getProfileContactsList = async () => {
  const { data: response } = await axiosInstance.get(
    // (getEndpoint() as any)["userAccountContactUrl"] +
    (getEndpoint() as any)["fetchUserAccountContacts"]
  );
  return response;
};

const getProfileContactsEmailCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserAccountContactEmail"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsEmailUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserAccountContactEmail"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsEmailDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserAccountContactEmail"],
    payload
  );
  return response;
};

const getProfileContactsAddressCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserAccountContactAddress"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsAddressUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserAccountContactAddress"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsAddressDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserAccountContactAddress"],
    payload
  );
  return response;
};

const getProfileContactsPhoneCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserAccountContactPhone"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsPhoneUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserAccountContactPhone"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsPhoneDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserAccountContactPhone"],
    payload
  );
  return response;
};

const getProfileContactsFaxCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserAccountContactFax"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsFaxUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserAccountContactFax"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsFaxDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserAccountContactFax"],
    payload
  );
  return response;
};

const getProfileContactsSocialMediaCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserAccountContactSocialMedia"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsSocialMediaUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserAccountContactSocialMedia"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfileContactsSocialMediaDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserAccountContactSocialMedia"],
    payload
  );
  return response;
};

const getProfilePaymentList = async () => {
  const { data: response } = await axiosInstance.get(
    // (getEndpoint() as any)["userAccountContactUrl"] +
    (getEndpoint() as any)["fetchUserAccountPayments"]
  );
  return response;
};

const getProfilePaymentBankCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserAccountPaymentBank"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfilePaymentBankUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserAccountPaymentBank"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getProfilePaymentBankDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserAccountPaymentBank"],
    payload
  );
  return response;
};

const getUserCurrentPayments = async (params: GetUserPaymentsParams = {}) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserCurrentPayments"],
    {
      params: {
        sbFromDate: params.fromDate,
        sbToDate: params.toDate,
        sbName: params.searchValue,
        page: params.currentPage,
        limit: params.rowsPerPage,
      },
    }
  );
  return response;
};

const getUserCreditPayment = async (params: GetUserPaymentsParams = {}) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserCreditPayments"],
    {
      params: {
        sbFromDate: params.fromDate,
        sbToDate: params.toDate,
        sbName: params.searchValue,
        page: params.currentPage,
        limit: params.rowsPerPage,
      },
    }
  );
  return response;
};

const getUserPaymentHistory = async (params: GetUserPaymentsParams = {}) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserPaymentHistory"],
    {
      params: {
        sbFromDate: params.fromDate,
        sbToDate: params.toDate,
        sbName: params.searchValue,
        page: params.currentPage,
        limit: params.rowsPerPage,
      },
    }
  );
  return response;
};

const getUserInvoiceDetails = async (params: GetUserPaymentsParams = {}) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserInvoices"],
    {
      params: {
        sbFromDate: params.fromDate,
        sbToDate: params.toDate,
        sbName: params.searchValue,
        page: params.currentPage,
        limit: params.rowsPerPage,
      },
    }
  );
  return response;
};

const getUserPaymentDetails = async (rowId: number | string) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserPaymentDetails"],
    {
      params: {
        id: rowId,
      },
    }
  );
  return response;
};

const getUserPaymentOrderDetails = async (rowId: number | string) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserOrderDetails"],
    {
      params: {
        id: rowId,
      },
    }
  );
  return response;
};
const getUserPaymentInvoiceDetails = async (rowId: number | string) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAMMCUserInvoiceDetails"],
    {
      params: {
        id: rowId,
      },
    }
  );
  return response;
};

const getMediaChannelAmUserProfileList = async (
  pageNumber: number,
  limit: number
) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
      },
    }
  );
  return response;
};

const getMediaChannelProfileList = async (
  pageNumber: number,
  limit: number
) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAllAMOfferedUserMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
        mcApiType: "SOCIAL_MEDIA_MC_TYPE",
      },
    }
  );
  return response;
};

const getMCPreferredList = async (pageNumber: number, limit: number) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAllPreferredMCUserMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
        mcSourceApiType: "REQUEST_PREFERRED_MC_USER_MC_TYPE",
      },
    }
  );
  return response;
};

const getMediaChannelAmProfileList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchMCProfiles"]
  );
  return response;
};

const getUserMcVerifyLater = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAMOfferedUserMCProfile"],
    payload
  );
  return response;
};

const getUserMcVerifyAndProceed = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createVerifyUserMCProfile"],
    payload
  );
  return response;
};

const getPreferredMediaAmProfileList = async (
  pageNumber: number,
  limit: number
) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAllPreferredMCUserMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
      },
    }
  );
  return response;
};

const getUserRequestPreferredMC = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createPreferredMCUserMCProfile"],
    payload
  );
  return response;
};

const getMCSocialMediaGeneralList = async (rowId: number | string) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfileGeneral"],
    {
      params: {
        id: rowId,
      },
    }
  );
  return response;
};

const getMCSocialMediaAboutMeList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfileAboutMeSelfAnalytics"],
    {
      params: {
        analyticsType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getMCSocialMediaAboutFollowersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfileAboutFollowersSelfAnalytics"],
    {
      params: {
        analyticsType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getMCSocialMediaAboutAudienceList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfileAboutAudienceSelfAnalytics"],
    {
      params: {
        analyticsType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getMCAnalyticsAboutMyOrganizationList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfileAboutOrganizationSelfAnalytics"],
    {
      params: {
        analyticsType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getSMAboutMeAndFollowsCreate = async (payload: {}, title: Title) => {
  const endpoint = getMCEndpointByTitle(title, "create");
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)[endpoint],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getSMAboutMeAndFollowsUpdate = async (payload: {}, title: Title) => {
  const endpoint = getMCEndpointByTitle(title, "update");
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)[endpoint],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getSMAboutMeAndFollowsDelete = async (payload: {}, title: Title) => {
  const endpoint = getMCEndpointByTitle(title, "delete");
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)[endpoint],
    payload
  );
  return response;
};

const getMCProfileVerifiedList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCProfileVerified"]
  );
  return response;
};

const getUserMCProfileUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserMCProfileGeneral"],
    payload
  );
  return response;
};

const getUserAdvertiserClients = async (pageNumber: number, limit: number) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserClients"],
    {
      params: {
        page: pageNumber,
        limit: limit,
      },
    }
  );
  return response;
};

const getUserAdvertiserClientsContacts = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserClientContacts"]
  );
  return response;
};

const getUserAdvertiserCreateClients = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserClient"],
    payload
  );
  return response;
};
const getUserAdvertiserUpdateClients = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAdvertiserClient"],
    payload
  );
  return response;
};
const getUserAdvertiserDeleteClients = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserClient"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactCreateEmail = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserClientContactEmail"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactUpdateEmail = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAdvertiserClientContactEmail"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactDeleteEmail = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserClientContactEmail"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactCreatePhone = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserClientContactPhone"],
    payload
  );
  return response;
};
const getUserAdvertiserClientsContactUpdatePhone = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAdvertiserClientContactPhone"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactDeletePhone = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserClientContactPhone"],
    payload
  );
  return response;
};
const getUserAdvertiserClientsContactCreateAddress = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserClientContactAddress"],
    payload
  );
  return response;
};
const getUserAdvertiserClientsContactUpdateAddress = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAdvertiserClientContactAddress"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactDeleteAddress = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserClientContactAddress"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactCreateFax = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserClientContactFax"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getUserAdvertiserClientsContactUpdateFax = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAdvertiserClientContactFax"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getUserAdvertiserClientsContactDeleteFax = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserClientContactFax"],
    payload
  );
  return response;
};

const getUserAdvertiserClientsContactCreateSocialMedia =
  async (payload: {}) => {
    const { data: response } = await axiosInstance.post(
      (getEndpoint() as any)["createAdvertiserClientContactSocialMedia"],
      payload,
      {
        params: {
          dataReturnType: DATA_RETURN_TYPE_ALL,
        },
      }
    );
    return response;
  };

const getUserAdvertiserClientsContactUpdateSocialMedia =
  async (payload: {}) => {
    const { data: response } = await axiosInstance.post(
      (getEndpoint() as any)["updateAdvertiserClientContactSocialMedia"],
      payload,
      {
        params: {
          dataReturnType: DATA_RETURN_TYPE_ALL,
        },
      }
    );
    return response;
  };

const getUserAdvertiserClientsContactDeleteSocialMedia =
  async (payload: {}) => {
    const { data: response } = await axiosInstance.post(
      (getEndpoint() as any)["deleteAdvertiserClientContactSocialMedia"],
      payload
    );
    return response;
  };

const getUserAdvertiserProjects = async (pageNumber: number, limit: number) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjects"],
    {
      params: {
        page: pageNumber,
        limit: limit,
      },
    }
  );
  return response;
};

const getUserAdvertiserCreateProjects = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserProject"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getUserAdvertiserUpdateProjects = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAdvertiserProject"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getUserAdvertiserDeleteProjects = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserProject"],
    payload
  );
  return response;
};

const getUserAdvertiserDeleteProjectClients = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserProjectClient"],
    payload
  );
  return response;
};

const getUserAdvertiserViewProject = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjectViewProject"]
  );
  return response;
};

const getUserAdvertiserProjectClients = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjectClients"]
  );
  return response;
};

const getUserAdvertiserViewAdvertisingChannel = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjectViewAdvertisingChannel"]
  );
  return response;
};

const getUserAdvertiserViewAudienceInterest = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjectViewAudienceInterest"]
  );
  return response;
};

const getUserAdvertiserCreateProjectClient = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAdvertiserProjectClients"],
    payload,
    {
      params: {
        dataReturnType: DATA_RETURN_TYPE_ALL,
      },
    }
  );
  return response;
};

const getUserAdvertiserViewClients = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserClientView"]
  );
  return response;
};

const getUserAdvertiserViewClientProject = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserClientViewProject"]
  );
  return response;
};

const getUserAdvertiserProjectAudienceInterestList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjectAudienceInterests"]
  );
  return response;
};

const getUserAdvertiserProjectMCList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserProjectMCs"]
  );
  return response;
};

const getUserMCAcceptedOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCAcceptedOffers"]
  );
  return response;
};

const getUserMCPendingTaskList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCPendingTasks"]
  );
  return response;
};

const getUserMCClarificationOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCClarificationOffers"]
  );
  return response;
};

const getUserMCHistoryOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCHistoryOfOffers"]
  );
  return response;
};

const getUserMCNewOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCNewOffers"]
  );
  return response;
};

const getUserMCHistoryTaskList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserMCHistoryOfTasks"]
  );
  return response;
};

const getUserAdvertiserAcceptedOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserAcceptedOffers"]
  );
  return response;
};

const getUserAdvertiserClarificationOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserClarificationOffers"]
  );
  return response;
};

const getUserAdvertiserHistoryOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserHistoryOfOffers"]
  );
  return response;
};

const getUserAdvertiserNewOffersList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserNewOffers"]
  );
  return response;
};

const getUserAdvertiserPendingTaskList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserPendingTasks"]
  );
  return response;
};

const getUserAdvertiserHistoryTaskList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserHistoryOfTasks"]
  );
  return response;
};

const getUserAdvertiserCustomOfferFilterList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchUserAdvertiserCustomOfferFilter"]
  );
  return response;
};

const getUserAdvertiserCustomOfferList = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserOfferSearchFilters"]
  );
  return response;
};

const getUserAdvertiserCustomOfferFilterSelectType = async (key: string) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserOfferSearchFilterSelectType"],
    {
      params: {
        typeApiName: key,
      },
    }
  );
  return response;
};

const advertiserOffersForCustomFilters = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["searchAdvertiserOffersForCustomFilters"],
    payload
  );
  return response;
};

const getUserAdvertiserContentList = async (
  pageNumber: number,
  limit: number
) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserContents"],
    {
      params: {
        page: pageNumber,
        limit: limit,
      },
    }
  );
  return response;
};

const getUserAdvertiserDeleteContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserContent"],
    payload
  );
  return response;
};

const getUserAdvertiserCreateVerifyLaterContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAndVerifyLaterAdvertiserContent"],
    payload
  );
  return response;
};

const getUserAdvertiserCreateVerifyContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAndVerifyAdvertiserContent"],
    payload
  );
  return response;
};

const getUserAdvertiserUpdateVerifyLaterContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAndVerifyLaterAdvertiserContent"],
    payload
  );
  return response;
};

const getUserAdvertiserUpdateVerifyContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAndVerifyAdvertiserContent"],
    payload
  );
  return response;
};

const getUserAdvertiserCampaign = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserCampaign"]
  );
  return response;
};

const getUserAdvertiserPublication = async () => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAdvertiserPublication"]
  );
  return response;
};

const getUserAdvCampaignCreateContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAddContent"],
    payload
  );
  return response;
};

const getUserAdvCampaignCreatePublication = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAddContent"],
    payload
  );
  return response;
};

const getUserAdvDeleteCampaign = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserCampaign"],
    payload
  );
  return response;
};

const getUserAdvDeleteCampaignContent = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserCampaignContent"],
    payload
  );
  return response;
};

const getUserAdvDeleteCampaignPublication = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAdvertiserCampaignPublication"],
    payload
  );
  return response;
};

const getUserAdvOffersViewDetails = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["fetchAdvOfferViewDetails"],
    payload
  );
  return response;
};

const getUserAdvCreateAndRequestPublicationLater = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAndRequestPublicationLater"],
    payload
  );
  return response;
};

const getUserAdvCreateAndRequestPublication = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAndRequestPublication"],
    payload
  );
  return response;
};

const getUserAdvUpdateAndRequestPublicationLater = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAndRequestPublicationLater"],
    payload
  );
  return response;
};

const getUserAdvUpdateAndRequestPublication = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAndRequestPublication"],
    payload
  );
  return response;
};

const getUserAdvPublicationCancel = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["cancelPublication"],
    payload
  );
  return response;
};

const getUserAdvPublicationPause = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["pausePublication"],
    payload
  );
  return response;
};

const getUserAdvPublicationDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["delete"],
    payload
  );
  return response;
};

const getUserPreferredMCCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createPreferredMCUserMCProfile"],
    payload
  );
  return response;
};

const getUserPreferredMCUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserPrivateMCProfile"],
    payload
  );
  return response;
};

const getUserPreferredMCDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deletePreferredMCUserMCProfile"],
    payload
  );
  return response;
};

const getUserMCOfferedMCProfiles = async (
  pageNumber: number,
  limit: number
) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAllAMOfferedUserMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
        mcApiType: "SOCIAL_MEDIA_MC_TYPE",
      },
    }
  );
  return response;
};

const getUserPrivateMCProfiles = async (pageNumber: number, limit: number) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAllUserPrivateMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
        mcApiType: "SOCIAL_MEDIA_MC_TYPE",
      },
    }
  );
  return response;
};

const getUserPublicMCProfiles = async (pageNumber: number, limit: number) => {
  const { data: response } = await axiosInstance.get(
    (getEndpoint() as any)["fetchAllUserPublicMCProfiles"],
    {
      params: {
        page: pageNumber,
        limit: limit,
        mcApiType: "SOCIAL_MEDIA_MC_TYPE",
      },
    }
  );
  return response;
};

const getUserMCOfferedMCProfileDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteAMOfferedUserMCProfile"],
    payload
  );
  return response;
};

const getUserPrivateMCProfileDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserPrivateMCProfile"],
    payload
  );
  return response;
};

const getUserPublicMCProfileDelete = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["deleteUserPublicMCProfile"],
    payload
  );
  return response;
};

const getUserMCOfferedMCProfileCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createAMOfferedUserMCProfiles"],
    payload
  );
  return response;
};

const getUserPrivateMCProfileCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserPrivateMCProfile"],
    payload
  );
  return response;
};

const getUserPublicMCProfileCreate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["createUserPublicMCProfile"],
    payload
  );
  return response;
};

const getUserMCOfferedMCProfileUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateAMOfferedUserMCProfile"],
    payload
  );
  return response;
};

const getUserPrivateMCProfileUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserPrivateMCProfile"],
    payload
  );
  return response;
};

const getUserPublicMCProfileUpdate = async (payload: {}) => {
  const { data: response } = await axiosInstance.post(
    (getEndpoint() as any)["updateUserPublicMCProfile"],
    payload
  );
  return response;
};

export {
  loginUser,
  registerUser,
  fetchUserProfile,
  fetchInitAnonymousProfile,
  forgotPassword,
  resetPassword,
  refreshToken,
  notificationList,
  getNotificationCount,
  getDashboardList,
  getProfileContactsList,
  getProfileContactsEmailCreate,
  getProfileContactsEmailUpdate,
  getProfileContactsEmailDelete,
  getProfileContactsAddressCreate,
  getProfileContactsAddressUpdate,
  getProfileContactsAddressDelete,
  getProfileContactsPhoneCreate,
  getProfileContactsPhoneUpdate,
  getProfileContactsPhoneDelete,
  getProfileContactsFaxCreate,
  getProfileContactsFaxUpdate,
  getProfileContactsFaxDelete,
  getProfileContactsSocialMediaCreate,
  getProfileContactsSocialMediaUpdate,
  getProfileContactsSocialMediaDelete,
  getProfilePaymentList,
  getProfilePaymentBankCreate,
  getProfilePaymentBankUpdate,
  getProfilePaymentBankDelete,
  getUserCurrentPayments,
  getUserPaymentDetails,
  getUserPaymentOrderDetails,
  getUserPaymentInvoiceDetails,
  getUserInvoiceDetails,
  getUserPaymentHistory,
  getUserCreditPayment,
  getMediaChannelAmProfileList,
  getMediaChannelAmUserProfileList,
  getUserMcVerifyLater,
  getUserMcVerifyAndProceed,
  getPreferredMediaAmProfileList,
  getUserRequestPreferredMC,
  getMCSocialMediaGeneralList,
  getMCSocialMediaAboutMeList,
  getMCSocialMediaAboutFollowersList,
  getSMAboutMeAndFollowsCreate,
  getSMAboutMeAndFollowsUpdate,
  getSMAboutMeAndFollowsDelete,
  getMCProfileVerifiedList,
  getUserMCProfileUpdate,
  getMCSocialMediaAboutAudienceList,
  getMCAnalyticsAboutMyOrganizationList,
  getUserAdvertiserClients,
  getUserAdvertiserCreateClients,
  getUserAdvertiserUpdateClients,
  getUserAdvertiserDeleteClients,
  getUserAdvertiserClientsContacts,
  getUserAdvertiserClientsContactCreateEmail,
  getUserAdvertiserClientsContactUpdateEmail,
  getUserAdvertiserClientsContactDeleteEmail,
  getUserAdvertiserClientsContactCreatePhone,
  getUserAdvertiserClientsContactUpdatePhone,
  getUserAdvertiserClientsContactDeletePhone,
  getUserAdvertiserClientsContactCreateAddress,
  getUserAdvertiserClientsContactUpdateAddress,
  getUserAdvertiserClientsContactDeleteAddress,
  getUserAdvertiserClientsContactCreateFax,
  getUserAdvertiserClientsContactUpdateFax,
  getUserAdvertiserClientsContactDeleteFax,
  getUserAdvertiserClientsContactCreateSocialMedia,
  getUserAdvertiserClientsContactUpdateSocialMedia,
  getUserAdvertiserClientsContactDeleteSocialMedia,
  getUserAdvertiserProjects,
  getUserAdvertiserCreateProjects,
  getUserAdvertiserUpdateProjects,
  getUserAdvertiserDeleteProjects,
  getUserAdvertiserProjectClients,
  getUserAdvertiserDeleteProjectClients,
  getUserAdvertiserViewProject,
  getUserAdvertiserViewAdvertisingChannel,
  getUserAdvertiserViewAudienceInterest,
  getUserAdvertiserCreateProjectClient,
  getUserAdvertiserViewClients,
  getUserAdvertiserViewClientProject,
  getUserAdvertiserProjectAudienceInterestList,
  getUserAdvertiserProjectMCList,
  getUserMCAcceptedOffersList,
  getUserMCClarificationOffersList,
  getUserMCHistoryOffersList,
  getUserMCNewOffersList,
  getUserMCPendingTaskList,
  getUserMCHistoryTaskList,
  getUserAdvertiserAcceptedOffersList,
  getUserAdvertiserPendingTaskList,
  getUserAdvertiserClarificationOffersList,
  getUserAdvertiserHistoryOffersList,
  getUserAdvertiserNewOffersList,
  getUserAdvertiserHistoryTaskList,
  getUserAdvertiserCustomOfferFilterList,
  getUserAdvertiserCustomOfferList,
  getUserAdvertiserCustomOfferFilterSelectType,
  advertiserOffersForCustomFilters,
  getUserAdvertiserContentList,
  getUserAdvertiserDeleteContent,
  getUserAdvertiserCreateVerifyLaterContent,
  getUserAdvertiserCreateVerifyContent,
  getUserAdvertiserUpdateVerifyLaterContent,
  getUserAdvertiserUpdateVerifyContent,
  getUserAdvertiserCampaign,
  getUserAdvertiserPublication,
  getUserAdvCampaignCreateContent,
  getUserAdvCampaignCreatePublication,
  getUserAdvDeleteCampaign,
  getUserAdvDeleteCampaignContent,
  getUserAdvDeleteCampaignPublication,
  getUserAdvOffersViewDetails,
  getUserAdvCreateAndRequestPublicationLater,
  getUserAdvCreateAndRequestPublication,
  getUserAdvUpdateAndRequestPublicationLater,
  getUserAdvUpdateAndRequestPublication,
  getUserAdvPublicationPause,
  getUserAdvPublicationCancel,
  getUserAdvPublicationDelete,
  getUserPreferredMCCreate,
  getMCPreferredList,
  getMediaChannelProfileList,
  getUserPreferredMCUpdate,
  getUserPreferredMCDelete,
  getUserMCOfferedMCProfiles,
  getUserPrivateMCProfiles,
  getUserPublicMCProfiles,
  getUserMCOfferedMCProfileDelete,
  getUserPrivateMCProfileDelete,
  getUserPublicMCProfileDelete,
  getUserMCOfferedMCProfileCreate,
  getUserPrivateMCProfileCreate,
  getUserPublicMCProfileCreate,
  getUserMCOfferedMCProfileUpdate,
  getUserPrivateMCProfileUpdate,
  getUserPublicMCProfileUpdate
};
