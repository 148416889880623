import {
  AccountBalanceOutlined,
  AccountCircleOutlined,
  AutorenewOutlined,
  BrushOutlined,
  CampaignOutlined,
  ContactsOutlined,
  DashboardOutlined,
  DnsOutlined,
  EngineeringOutlined,
  FactCheckOutlined,
  ListAltOutlined,
  ManageAccountsOutlined,
  NewspaperOutlined,
  PaymentsOutlined,
  PeopleOutlineOutlined,
  PercentOutlined,
  Person4Outlined,
  PublishOutlined,
  ReceiptOutlined,
  ReportGmailerrorredOutlined,
  RotateLeftOutlined,
  SecurityOutlined,
  SmartButtonOutlined,
  Speed,
  StarBorder,
  SubscriptionsOutlined,
  TabOutlined,
  TaskOutlined,
  TimelineOutlined,
  TocOutlined,
  ViewCarouselOutlined,
  WidgetsOutlined,
} from "@mui/icons-material";
import React from "react";
import ROUTES from "utils/CommonRouter";

const menuItems = [
  { to: ROUTES.MY_ADVERT_ROUTE, icon: <Speed />, name: "dashboard" },
  {
    to: ROUTES.PROFILE,
    icon: <AccountCircleOutlined />,
    name: "userAccount",
    submenu: [
      { to: ROUTES.PROFILE, icon: <Person4Outlined />, name: "profile" },
      { to: ROUTES.CONTACTS, icon: <ContactsOutlined />, name: "contacts" },
      {
        to: ROUTES.BANK_DETAILS,
        icon: <AccountBalanceOutlined />,
        name: "bankDetails",
      },
      { to: ROUTES.PAYMENTS, icon: <PaymentsOutlined />, name: "payments" },
      { to: ROUTES.SECURITY, icon: <SecurityOutlined />, name: "security" },
      {
        to: ROUTES.BILLING_PAYMENTS,
        icon: <ReceiptOutlined />,
        name: "billingPayments",
      },
    ],
  },
  {
    to: ROUTES.MY_ADVERTISER,
    icon: <CampaignOutlined />,
    name: "myAdvertiser",
    submenu: [
      {
        to: ROUTES.ADVERTISING_CLIENTS,
        icon: <PeopleOutlineOutlined />,
        name: "advertisingClients",
      },
      {
        to: ROUTES.ADVERTISING_PROJECTS,
        icon: <DashboardOutlined />,
        name: "advertisingProjects",
      },
      {
        to: ROUTES.ADVERTISER_TASKS,
        icon: <TaskOutlined />,
        name: "tasks",
      },
      {
        to: ROUTES.ADVERTISER_OFFERS,
        icon: <PercentOutlined />,
        name: "offers",
      },
      {
        to: ROUTES.ADVERTISER_PUBLISHING_OFFERS,
        icon: <PublishOutlined />,
        name: "publishingOffers",
      },
      {
        to: ROUTES.ADVERTISER_ADVERTISEMENT_CONTENT,
        icon: <NewspaperOutlined />,
        name: "advertisementContent",
      },
      {
        to: ROUTES.ADVERTISER_CAMPAIGNS,
        icon: <PublishOutlined />,
        name: "campaigns",
      },
      {
        to: ROUTES.ADVERTISER_PUBLICATIONS,
        icon: <PublishOutlined />,
        name: "advertiserPublications",
      },
    ],
  },
  {
    to: ROUTES.MY_MEDIA_CHANNELS,
    icon: <SubscriptionsOutlined />,
    name: "myMediaChannels",
    submenu: [
      {
        to: ROUTES.MEDIA_CHANNELS,
        icon: <SubscriptionsOutlined />,
        name: "mediaChannels",
      },
      {
        to: ROUTES.ANALYTICS,
        icon: <TimelineOutlined />,
        name: "analytics",
      },
      {
        to: ROUTES.TASKS,
        icon: <TaskOutlined />,
        name: "tasks",
      },
      {
        to: ROUTES.OFFERS,
        icon: <PercentOutlined />,
        name: "offers",
      },
    ],
  },
  {
    to: ROUTES.MENU_TWO,
    icon: <WidgetsOutlined />,
    name: "menu2",
    submenu: [
      { to: ROUTES.SUBMENU_SIX, icon: <StarBorder />, name: "starred" },
    ],
  },
  { to: ROUTES.MENU_THREE, icon: <WidgetsOutlined />, name: "menu3" },
  { to: ROUTES.MENU_FOUR, icon: <WidgetsOutlined />, name: "menu4" },

  {
    to: ROUTES.MENU_SIX,
    icon: <BrushOutlined />,
    name: "Basic Components",
    submenu: [
      {
        to: ROUTES.BASIC_COMPONENTS_ACCORDION,
        icon: <DnsOutlined />,
        name: "Accordion",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_CHIP,
        icon: <SmartButtonOutlined />,
        name: "Button",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_LIST,
        icon: <ListAltOutlined />,
        name: "list",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_TABS,
        icon: <TabOutlined />,
        name: "tabs",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_CARDS,
        icon: <DashboardOutlined />,
        name: "cards",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_ALERT,
        icon: <ReportGmailerrorredOutlined />,
        name: "alert",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_LOADER,
        icon: <AutorenewOutlined />,
        name: "loader",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_PROGRESS,
        icon: <RotateLeftOutlined />,
        name: "progress",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_MAINTENANCE,
        icon: <ManageAccountsOutlined />,
        name: "siteMap",
        submenu: [
          {
            to: ROUTES.BASIC_COMPONENTS_MAINTENANCE,
            icon: <EngineeringOutlined />,
            name: "maintenance",
          },
          {
            to: ROUTES.BASIC_COMPONENTS_UNDER_CONSTRUCTION,
            icon: <DnsOutlined />,
            name: "underConstruction",
          },
        ],
      },
      {
        to: ROUTES.BASIC_COMPONENTS_FORMFIELDS,
        icon: <FactCheckOutlined />,
        name: "formFields",
      },
      {
        to: ROUTES.BASIC_COMPONENTS_DRAWER,
        icon: <ViewCarouselOutlined />,
        name: "drawer",
      },
      { to: ROUTES.TABLE, icon: <TocOutlined />, name: "table" },
    ],
  },
];

export default menuItems;
