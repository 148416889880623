import React from "react";

import { UserLayout } from "views/layout";

const UserLayoutController = () => {
  return (
    <div>
      <UserLayout />
    </div>
  );
};

export default UserLayoutController;
