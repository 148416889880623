import React from "react";
import { Box, Slider, Typography } from "@mui/material";
import AMLabel from "./AM_Label";

const MAX = 100;
const MIN = 0;

interface AMSliderProps {
  value: number;
  onChange: (event: Event, newValue: number | number[]) => void;
  label: string;
  required?: boolean;
}
const AMSlider = ({
  value,
  label,
  required,
  onChange,
}: Readonly<AMSliderProps>) => {
  const [val, setVal] = React.useState<number>(MIN);
  console.log(val);
  return (
    <div>
      <AMLabel label={label} required={required} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "30px",
          alignItems: "center",
        }}
      >
        <Typography
          onClick={() => setVal(MIN)}
          sx={{ cursor: "pointer", border: 0 }}
        >
          {MIN}%
        </Typography>
        <Slider
          step={10}
          value={value}
          onChange={onChange}
          valueLabelDisplay="on"
          min={MIN}
          max={MAX}
        />
        <Typography
          onClick={() => setVal(MAX)}
          sx={{ cursor: "pointer", border: 0 }}
        >
          {MAX}%
        </Typography>
      </Box>
    </div>
  );
};

export default AMSlider;
