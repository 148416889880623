import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

interface AMCheckBoxProps {
  label: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AMCheckBox = ({
  label,
  checked = false,
  onChange,
}: Readonly<AMCheckBoxProps>) => {
  return (
    <FormControlLabel
      className="common-checkbox"
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={label}
    />
  );
};

export default AMCheckBox;
