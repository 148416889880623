import React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import AMLabel from "./AM_Label";

interface AMInputProps {
  label: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  selectOption: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isDisabled?: boolean;
}

const TextareaAutosize = styled(BaseTextareaAutosize)({
  // Css add here
});

const AMTextarea = ({
  label,
  placeholder,
  value,
  selectOption,
  required,
  isDisabled,
  ...rest
}: Readonly<AMInputProps>) => {
  return (
    <div>
      <AMLabel label={label} required={required} />

      <TextareaAutosize
        maxRows={4}
        minRows={4}
        placeholder={placeholder}
        value={value}
        onChange={selectOption}
        className={isDisabled ? "Mui-disabled" : ""}
        disabled={isDisabled}
      />
    </div>
  );
};

export default AMTextarea;
