import React from "react";
import { Switch } from "@mui/material";
import AMLabel from "./AM_Label";

interface AMSwitchProps {
  label: React.ReactNode;
  value?: string;
  changeValue: boolean;
  required?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const AMSwitch = ({
  label,
  value,
  required,
  changeValue,
  onChange,
}: Readonly<AMSwitchProps>) => {
  return (
    <div>
      <AMLabel label={label} required={required} />

      <Switch className="switchicon"
        checked={changeValue}
        onChange={onChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
};

export default AMSwitch;
