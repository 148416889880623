import React from "react";
import { AppBar, Toolbar, Container } from "@mui/material";
import { AMApplicationLogo } from "components/common";
const Header = () => {
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        bgcolor: "common.white",
      }}
    >
      {/* CONTAINER SECTION STARTS HERE */}
      <Container
        maxWidth="xl"
        sx={{
          zIndex: "1",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "grid",
            gridTemplateColumns: "20% auto 20%",
            justifyContent: "space-between",
          }}
        >
          {/* LOGO SECTION STARTS HERE */}
          <AMApplicationLogo />
          {/* LOGO SECTION ENDS HERE */}
        </Toolbar>
      </Container>
      {/* CONTAINER SECTION ENDS HERE */}
    </AppBar>
  );
};

export default Header;
