import React from "react";
import { FormControlLabel, Radio } from "@mui/material";

interface AMRadioSwitchProps {
  label: React.ReactNode;
  value: string;
}
const AMRadioSwitch = ({ label, value }: Readonly<AMRadioSwitchProps>) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio className="switch" />}
      label={label}
    />
  );
};

export default AMRadioSwitch;
